<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2" style="width: 900px;">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{item.id ? '编辑' : '添加'}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="meal-title">*标题:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.title">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="meal-shop">*简介:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.desc">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="meal-shop">*门店:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<select class="form-select" v-model="item.shop">
								<option v-for="(value, idx) in dataList" :key='idx' :value="value.id">{{value.name}}</option>
							</select>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="meal-price">*价格:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<div v-for="(price, index) in item.priceList" :key="index" style="margin-bottom: 15px;">
								<div class="flex-row flex-align-center">
									<input type="number" class="form-control" style="width: 100px;" v-model="item.priceList[index].days">
									<div style="margin: 0 15px 0 5px;">天</div>
									<input type="number" class="form-control" style="width: 100px;" v-model="item.priceList[index].price">
									<div style="margin: 0 0px 0 5px;">元</div>
									<img src="/assets/ic_trash.png" class="remove-pack ml-3" @click="item.priceList.splice(index,1)" />
								</div>
								<div class="flex-row flex-wrap flex-align-center mt-2">
									<div v-for="(v, idx) in price.ckPack" v-if="price.ckPack" class="ck-pack">
										<div class="flex-row flex-align-center">
											{{v.title}} - {{v.remark || "无备注"}}
											<img src="/assets/ic_trash.png" class="remove-pack ml-3" @click="deletePack(price, v)" />
										</div>
									</div>
									<div class="add-pack" @click="addPack(price)">添加产康包</div>
								</div>
							</div>
							<div class="add-meal-price flex-row-center" @click="addPrice">添加价格</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="app-home-order">排序:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="number" class="form-control" v-model="item.order">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="meal-show">是否显示:</label>
						</div>
					</td>
					<td>
						<div class="mb-1 form-switch">
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="item.show">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="meal-image">*宣传图:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<ImageUploader :src='item.image' @upload='imageUpload' />
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="meal-detail">*详情:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<RichEditor v-model="item.detail"/>
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>
  
  <AddShopMealPackDialog :show="showAddPackDialog" :shop="item.shop" @cancel="showAddPackDialog=false" @confirm="confirmAddPack" />
</template>
 
<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import ImageUploader from '@/components/ImageUploader.vue'
import AddShopMealPackDialog from '@/modals/AddShopMealPackDialog.vue'
import RichEditor from '@/components/RichEditor.vue'
import request from "@/global";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
	components: { ImageUploader, Datepicker, RichEditor, AddShopMealPackDialog },
	props: {  
		show: {
			type: Boolean,
			default: false, 
		},
		item: {
			type: Object,
			default: {},
		},
	},
	emits: ['cancel', 'confirm'],
  setup(props: any, ctx: any)
  {
	const refData = reactive({
		showAddPackDialog: false,
	});
	
	const dataList = ref<any[]>([]);
	let editingPrice = {} as any;
	
	onMounted(async () => {
		loadShops();
	});
	
	// 加载列表
	const loadShops = async () => {
		try {
			const data = (await request({url: `shop/list/1/100`, get: true})).data;
			if (checkResponseDataAndAlert(data) && data.data) {
				dataList.value = data.data;
			}
		} catch (e) {
			checkResponseDataAndAlert({});
		}
	};
	
	const addPrice = () => {
		if (!props.item.priceList)
			props.item.priceList = [];
		props.item.priceList.push({days: 28, price: 18000});
	}
 
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		// console.log(JSON.stringify(toPostData(props.item, [])))
		ctx.emit('confirm', toPostData(props.item, ['show']));
	}
	
	// 添加产康包
	const addPack = (price: any) => {
		if (!props.item.shop || props.item.shop.length <= 0) {
			alert("请先选择门店");
			return
		}
		
		editingPrice = price;
		refData.showAddPackDialog = true;
	}
	
	// 删除产康包
	const deletePack = (price: any, v: any) => {
		price.ckPack = price.ckPack.filter((t:any)=>{ return t.pid !== v.pid})
	}
	
	// 添加产康包
	const confirmAddPack = (arr: any[]) => {
		if (!editingPrice.ckPack) editingPrice.ckPack = [];
		for (const tt of arr) {
			editingPrice.ckPack.push({pid: tt.id, title: tt.title, remark: tt.remark});
		}
		refData.showAddPackDialog = false;
	}
	
	const imageUpload = (url: string) => {
		props.item.image = url
	}

	const dataRef = toRefs(refData);
    return {
      cancel,
	  confirm,
	  checkNumber,
	  imageUpload,
	  addPrice,
	  addPack,
	  confirmAddPack,
	  deletePack,
	  
	  dataList,
	  ...dataRef
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
	.add-pack {
		cursor: pointer;
		font-size: 12px;
		height: 24px;
		line-height: 24px;
		color: #0F52BA;
		padding: 0 8px;
		border-radius: 10px;
		border: 1px solid #0F52BA;
		margin-left: 5px;
	}
	.add-meal-price {
		cursor: pointer;
		font-size: 15px;
		color: white;
		padding: 3px 10px;
		border-radius: 22px;
		background-color: #0F52BA;
		width: 90px;
	}
	.add-meal-price-img {
		width: 60%;
		height: 60%;
	}
	.ck-pack {
		font-size: 16px;
		margin: 5px;
	}
	.remove-pack {
		cursor: pointer;
		width: 20px;
		height: 20px;
	}
</style>