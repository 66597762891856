<template>
 	<div class="flex-colum h-100">
		<div class="flex-row flex-align-center bd-highlight mb-3">
		    <div class="align-self-center" style="height: 30px;">
		        <!-- <button type="button" class="btn btn-primary" @click="openAddCustomerDialog">添加</button> -->
				<button type="button" class="btn btn-primary ml-5" @click="exportData">导出</button>
		    </div>
			<div class="flex-1"></div>
			<select v-model="active" class="customer-select">
				<option value="1000">全部</option>
				<option value="0">已签约</option>
				<option value="1">已入住</option>
				<option value="2">产康客户</option>
				<option value="3">月子客户</option>
			</select>
		    <div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 200px;">
		        <input type="text" id="customer-keywords" class="form-control" v-model="keywords" placeholder="搜索">
		        <button class="btn btn-outline-secondary" type="button" @click="loadCustomerList(1)">搜索</button>
		    </div>
		</div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th>序号</th><th>姓名</th><th>客户编号</th><th>手机号</th><th>微信号</th><th>咨询日期</th><th>顾问</th><th>授权码</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in customers" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.name}}</td>
						<td>{{value.id}}</td>
						<td>{{value.mobile}}</td>
						<td>{{value.wechat}}</td>
						<td>{{value.addDate}}</td>
						<td>{{value.guwenName}}</td>
						<td>{{value.authCode}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditCustomerDialog(value.id)"/>
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteCustomer(value.id, value.name)"/>
								<img class="button-icon img-margin" src="/assets/ic_view.svg" @click="showDetail(value)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadCustomerList" />
		</div>
	</div>
	
	<AddCustomerDialog :show='showAddDialog' :customer='editing' @cancel='showAddDialog = false' @confirm='editCustomer'/>
	<LoadingDialog :show='showLoadingDialog' />
	
	<template v-if="showDetailDialog">
		<ShowDetailDialog @cancel="showDetailDialog=false" :json="detail" />
	</template>
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddCustomerDialog from '@/modals/AddCustomerDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import ShowDetailDialog from '@/modals/ShowDetailDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt, downloadTextAsFile } from '@/common/util';

export default({
	components: { Pagination, AddCustomerDialog, LoadingDialog, ShowDetailDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			showDetailDialog: false,
			keywords: "",
			editing: {},
			detail: "",
			active: "1000"
		});
		
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const customers = ref<any[]>([]);
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadCustomerList(1);
		});
		
		// ========================文章标签========================
		// 添加标签
		const openAddCustomerDialog = () => {
			refData.editing = {
				nation:"汉族", education: "大学本科", babySex: "男",
				'qianyue': false, 'waipaipeihu': false, 'waipaitongru': false,
			};
			refData.showAddDialog = true;
		};
		
		// 编辑信息
		const openEditCustomerDialog = async (card_no: string) => {
		    const errMsg = "查询客户信息失败";
			try {
				const data = (await request({url: `customer/${card_no}`, get: true})).data;
		        if (checkResponseDataAndAlert(data, errMsg) && data.data) {
					refData.editing = data.data;
					refData.showAddDialog = true;
		        }
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 编辑
		const editCustomer = async (id: string, postObj: any) =>  {
			console.log(postObj)
		    const errMsg = `操作失败`;
			// if (!postObj.mobile || postObj.mobile.length !== 11) {
			// 	alert("请填写正确的手机号");
			// 	return;
			// }
		
		    refData.showLoadingDialog = true;
			try {
				const data = (await request({url: `customer/update/${id}`, data: postObj})).data;
				console.log(data);
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadCustomerList(nowPage.value);
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 删除
		const deleteCustomer = async (card_no: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除客户失败";
				try {
					const data = (await request({url: `customer/delete/${card_no}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除客户成功");
						loadCustomerList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};
			
		// 加载列表
		const  loadCustomerList = async (page: number) => {
		    if (page < 1) return;
		
		    nowPage.value = page;
			try {
				const data = (await request({url: `customer/list/${page}/${50}?shop=all&search=${refData.keywords}&active=${refData.active}`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    customers.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};
		
		// 加载列表
		const  exportData = async () => {
			try {
				const data = (await request({url: `customer/export`, get: true})).data;
				console.log(data)
				if (checkResponseDataAndAlert(data) && data.data) {
					downloadTextAsFile(data.data, "客户.csv")
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};
		
		const showDetail = async (item: any) => {
			const errMsg = "查询客户信息失败";
			try {
				const data = (await request({url: `customer/${item.id}`, get: true})).data;
			    if (checkResponseDataAndAlert(data, errMsg) && data.data) {
					refData.showDetailDialog = true;
					refData.detail = JSON.stringify(data.data, null, "\t");
			    }
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		const dataRef = toRefs(refData);
		return {
			customers,
			nowPage,
			totalPage,
			
			dateFtt,
			editCustomer,
			loadCustomerList,
			openAddCustomerDialog,
			openEditCustomerDialog,
			deleteCustomer,
			exportData,
			showDetail,
			
			...dataRef
		};
	},
});
</script>

<style>
.customer-select {
	border: #29434e 1px solid;
	color: #29434e;
	height: 36px;
	border-radius: 5px;
	padding: 0 10px;
	margin-right: 10px;
}
</style>