<template>
    <div class="modal-backdrop">
       <div class="modal2">
          <div class="modal-header flex-row flex-align-center">
            <div class="text-center flex-1 modal-header-title">{{title}}</div>
            <button @click="cancel" class="modal-header-close"><img src="../assets/ic_close.svg" /></button>
          </div>
          <div class="modal-body flex-row flex-wrap">
              <slot></slot>
          </div>
          <div class="modal-footer2 flex-row-center">
              <button type="button" class="btn btn-primary" @click="confirm">{{button}}</button>
          </div>
      </div>
      <div id="datePickerContainerId"></div>
    </div>
</template>
   
<script lang="ts">
  import { defineComponent } from "vue";
  
  export default defineComponent({
      components: { },
      props: {  
          title: { type: String, default: "添加"},
          button: { type: String, default: "确定"},
      },
  
    setup(props: any, ctx: any)
    {
   
      const cancel = () => {
        ctx.emit('cancel');
      }
            
      const confirm = () => {
        ctx.emit('confirm');
      }
  
      return {
        cancel,
        confirm,
      }
    },
  });
</script>