import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './index.css';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const { SnackbarService, Vue3Snackbar } = require("vue3-snackbar");
import "vue3-snackbar/dist/style.css";

const app = createApp(App);
app.use(SnackbarService).use(router);
app.component('Datepicker', Datepicker);
app.component("vue3-snackbar", Vue3Snackbar);
app.mount('#app');