<template>
 	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
		    <div class="align-self-center mb-3" style="height: 30px;">
		        <button type="button" class="btn btn-primary" @click="openAddCommentDialog">添加</button>
		    </div>
		    <div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
		        <input type="text" id="employee-keywords" class="form-control" v-model="keywords">
		        <button class="btn btn-outline-secondary" type="button" @click="searchComment">搜索</button>
		    </div>
		</div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th scope="col">序号</th><th scope="col">用户</th><th scope="col">内容</th><th scope="col">发布时间</th><th scope="col">操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.name}}</td>
						<td>{{value.content}}</td>
						<td>{{dateFtt('yyyy-MM-dd hh:mm', new Date(value.operateTime))}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditCommentDialog(value.id)"/>
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteComment(value.id, value.name)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadCommentList" />
		</div>
	</div>
	
	<AddCommentDialog :show='showAddDialog' :item='editing' @cancel='showAddDialog = false' @confirm='editComment'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddCommentDialog from '@/modals/AddCommentDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt } from '@/common/util';

export default({
	components: { Pagination, AddCommentDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			keywords: "搜索",
			editing: {}
		});
		
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadCommentList(1);
		});
		
		// ========================文章标签========================
		// 添加标签
		const openAddCommentDialog = () => {
			refData.editing = {};
			refData.showAddDialog = true;
		};
		
		// 编辑信息
		const openEditCommentDialog = async (id: string) => {
		    const errMsg = "查询信息失败";
			try {
				const data = (await request({url: `comment/${id}`, get: true})).data;
		        if (checkResponseDataAndAlert(data, errMsg) && data.data) {
					refData.editing = data.data;
					refData.showAddDialog = true;
		        }
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 编辑
		const editComment = async (postObj: any) =>  {
			console.log(postObj)
		    const errMsg = `操作失败`;		
			if (!postObj.name || postObj.name.length < 2
				&& !postObj.mobile || postObj.mobile.length !== 11) {
				alert("请完善带*的必填项");
				return;
			}
		
		    refData.showLoadingDialog = true;
			try {
				const data = (await request({url: `comment/save`, data: postObj})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadCommentList(nowPage.value);
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 删除
		const deleteComment = async (card_no: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await request({url: `comment/delete/${card_no}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功");
						loadCommentList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};
		
		// 搜索
		const searchComment = async () => {
		    if (refData.keywords.length === 0) {
		        loadCommentList(1);
		        return;
		    }
		
		    nowPage.value = 1;
			try {
				const postObj = {"page" : 1, "size": 50, "content": refData.keywords, timestamp: Date.now() };
				const data = (await request({url: `comment/search`, data: postObj})).data
		        if (checkResponseDataAndAlert(data) && data.data) {
					dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}
			
		// 加载列表
		const  loadCommentList = async (page: number) => {
		    if (page < 1) return;
		
		    nowPage.value = page;
			try {
				const data = (await request({url: `comment/list/all/${page}/${50}`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};
		
		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
			
			dateFtt,
			editComment,
			loadCommentList,
			openAddCommentDialog,
			openEditCommentDialog,
			deleteComment,
			
			...dataRef
		};
	},
});
</script>

<style>
</style>