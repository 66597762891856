<template>
	<div class="d-flex flex-row file-upload">
		<img :src="src || '/assets/ic_upload.png'"/>
		<input type="file" @change="uploadFilePicked" />
	</div>
	
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">

import { reactive, toRefs, onMounted, defineComponent } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert } from '@/common/util';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import { DEFAULTS } from "@/global";
import axios from 'axios';

export default defineComponent({
	components: { LoadingDialog },
	emits: ['upload'],
	props: {  
		src: {
			type: String,
			default: '',
		},
	},
	setup(props: any, ctx: any)
	{
		const refData = reactive({
			showLoadingDialog: false,
		});
		
		const uploadFilePicked = async (e: any) => {
			refData.showLoadingDialog = true;
			let formData = new FormData();
			formData.append('file', e.target.files[0]);
			try {
				const data = (await axios.post(`${DEFAULTS.URL}file/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
					ctx.emit('upload', data.data);
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
			refData.showLoadingDialog = false;
		}

		const dataRef = toRefs(refData);
		return {
			uploadFilePicked,
			...dataRef
		}
	},
});
</script>

<style>
.file-upload {
	width: 50px;
	height: 50px;
	position: relative;
}
.file-upload>img {
	background-color: transparent;
	margin-left: 5px;
	width: 100%;
	height: 100%;
}
.file-upload>input {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	outline: 0;
	opacity: 0;
}
</style>
