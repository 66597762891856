<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2" style="width: 900px;">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">添加</div>
          <button @click="cancel" class="modal-header-close">
			<img src="../assets/ic_close.svg" />
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>*标题:</label></div></td>
					<td><div class="mb-1"><input type="text" class="form-control" v-model="itemData.data.title"></div></td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label style="line-height: 36px;">*描述:</label></div></td>
					<td><div class="mb-1"><input type="text" class="form-control" v-model="itemData.data.desc"></div></td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label style="line-height: 36px;">类型:</label></div></td>
					<td>
						<div class="mb-1">
							<div class="mb-1">
								<select class="form-select" v-model="itemData.data.type">
									<option value="video" selected>视频</option>
									<option value="image">图文</option>
									<option value="yoga">瑜伽</option>
								</select>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>标签:</label></div></td>
					<td>
						<div class="mt-2 d-flex flex-row flex-wrap">
							<div v-for="(value, index) in dataList" :key="index" class="mb-2 mr-2" @click="setLabel(value)">
								<input type="checkbox" class="btn-check" autocomplete="off" :checked="itemData.data.labels && itemData.data.labels.indexOf(value.id)>-1">
								<label class="btn btn-outline-primary">{{value.title}}</label>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>状态:</label></div></td>
					<td>
						<div class="mb-1 form-switch">
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="itemData.data.show"/>
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right">*排序:</div></td>
					<td><div class="mb-1"><input type="text" class="form-control" v-model="itemData.data.order"></div></td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>宣传图:</label></div></td>
					<td>
						<div class="mb-1">
							<ImageUploader :src='itemData.data.image' @upload='imageUpload' />
						</div>
					</td>
				</tr>
				<tr id="article-video-tr" v-if="itemData.data.type!=='image'">
					<td><div class="mb-1 td-label-right"><label>*视频:</label></div></td>
					<td>
						<div class="d-flex flex-row mb-1">
							<input type="text" class="form-control" v-model="itemData.data.video">
							<FileUploader @upload='fileUpload' />
						</div>
					</td>
				</tr>
				<tr id="article-content-tr" v-else>
					<td><div class="mb-1 td-label-right"><label>*内容:</label></div></td>
					<td><div class="mb-1"><RichEditor v-model="itemData.data.content"/></div></td>
				</tr>
				<tr v-if="itemData.data.type==='yoga'">
					<td><div class="mb-1 td-label-right"><label>课程动作:</label></div></td>
					<td><div class="mt-2 d-flex flex-row flex-wrap">
						<div v-for="(lesson, index) in itemData.data.lessons" :key="index" class="mb-2 mr-2">
							<img :src="lesson.image" class="lesson-image" @click="editLesson(lesson, index)"/>
						</div>
						<img src='/assets/image_preview_add.png' class="lesson-image" @click="editLesson(null)" />
					</div></td>
				</tr>
			</table>
        </div>
		<div class="flex-row-center modal-footer2">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>

	<div class="modal-backdrop" v-if="showLesson">
      <div class="modal2" style="width: 800px;">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">课程动作</div>
          <button @click="showLesson = false" class="modal-header-close"><img src="../assets/ic_close.svg" /></button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
			<tr><td style="width: 100px;"></td><td></td></tr>
			<tr>
				<td><div class="mb-1 td-label-right"><label>标题:</label></div></td>
				<td><div class="mb-1"><input type="text" class="form-control" v-model="editingLesson.title"></div></td>
			</tr>
			<tr>
				<td><div class="mb-1 td-label-right"><label style="line-height: 36px;">描述:</label></div></td>
				<td><div class="mb-1"><input type="text" class="form-control" v-model="editingLesson.desc"></div></td>
			</tr>
			<tr>
				<td><div class="mb-1 td-label-right"><label style="line-height: 36px;">时长:</label></div></td>
				<td><div class="mb-1"><input type="text" class="form-control" v-model="editingLesson.duration"></div></td>
			</tr>
			<tr>
				<td><div class="mb-1 td-label-right"><label>封面:</label></div></td>
				<td>
					<div class="mb-1">
						<ImageUploader :src='editingLesson.image' @upload='lessonImageUpload' />
					</div>
				</td>
			</tr>
			<tr id="article-video-tr">
				<td><div class="mb-1 td-label-right"><label>视频:</label></div></td>
				<td>
					<div class="d-flex flex-row mb-1">
						<input type="text" class="form-control" v-model="editingLesson.video">
						<FileUploader @upload='lessonFileUpload' />
					</div>
				</td>
			</tr>
			</table>
		</div>
		<div class="flex-row-center modal-footer2">
			<button type="button" class="btn btn-primary" @click="confirmLesson">确定</button>
		</div>
	  </div>
	</div>
</template>
 
<script lang="ts">
import { reactive, toRefs, defineComponent, ref, onMounted, watch } from "vue";
import RichEditor from '@/components/RichEditor.vue'
import ImageUploader from '@/components/ImageUploader.vue'
import FileUploader from '@/components/FileUploader.vue'
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import { DEFAULTS } from "@/global";
import axios from 'axios';

export default defineComponent({
  components: { RichEditor, ImageUploader, FileUploader },
  emits: ["confirm", 'cancel'],
  props: {  
    show: {
        type: Boolean,
        default: false, 
    },
  },

  setup(props: any, ctx: any)
  {
	const refData = reactive({
		editingLesson: {} as any,
		editingIdx: -1,
		showLesson: false,
	});
	const dataList = ref<any[]>([]);
	const itemData = reactive({data: {labels: null, image: null, lessons: null}});
	
	onMounted(async () => {
		loadLabels();
	});
	
	// 加载列表
	const loadLabels = async () => {
		try {
			const data = (await axios.get(`${DEFAULTS.URL}article/class/list/1/100?show=1`)).data;
			if (checkResponseDataAndAlert(data) && data.data) {
				dataList.value = data.data;
			}
		} catch (e) {
			checkResponseDataAndAlert({});
		}
	};
	
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		console.log(JSON.stringify(toPostData(itemData.data, ['show'])))
		ctx.emit('confirm', toPostData(itemData.data, ['show']));
	}
	
	const imageUpload = (url: string) => {
		itemData.data.image = url
	}
	
	const fileUpload = (url: string) => {
		(itemData.data as any).video = url
	}

	const setItemData = (value: any) => {
		itemData.data = value;
		console.log(value);
	}
	
	const setLabel = (value: any) => {
		if (!itemData.data.labels) itemData.data.labels = [];
		const index = itemData.data.labels.indexOf(value.id);
		if (index > -1)
			itemData.data.labels.splice(index, 1);
		else
			itemData.data.labels.push(value.id);
	}

	const editLesson = (value: any, idx: number) => {
		if (!value) {
			refData.editingLesson = {};
			refData.editingIdx = -1;
		} else {
			refData.editingLesson = value;
			refData.editingIdx = idx;
		}
		refData.showLesson = true;
	}

	const lessonImageUpload = (url: string) => {
		refData.editingLesson.image = url
	}
	
	const lessonFileUpload = (url: string) => {
		refData.editingLesson.video = url
		console.log(refData.editingLesson);
	}

	const confirmLesson = () => {
		refData.showLesson = false;
		if (refData.editingIdx >= 0) {
			itemData.data.lessons.splice(refData.editingIdx, 1, refData.editingLesson)
		} else {
			if (!itemData.data.lessons) itemData.data.lessons = [];
			itemData.data.lessons.push(refData.editingLesson);
		}
		console.log(itemData.data);
	}

	const dataRef = toRefs(refData);
    return {
      cancel,
	  confirm,
	  setLabel,
	  setItemData,
	  imageUpload,
	  fileUpload,
	  editLesson,
	  lessonImageUpload,
	  lessonFileUpload,
	  confirmLesson,
	  
	  dataList,
	  ...dataRef,
	  itemData
    }
  },
});
</script>

<style scoped>
.form-check-input {
	margin-left: 0;
}
.lesson-image {
	width: 100px;
	height: 100px;
	cursor: pointer;
	background-color: #00193A;
}
</style>