<template>
	<div class="d-flex flex-row upload">
		<img :src="src || '/assets/image_preview_add.png'"/>
		<input type="file" class="upload" id="qr-picker" @change="uploadFilePicked" />
	</div>
</template>

<script lang="ts">

import { reactive, toRefs, onMounted, defineComponent } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert } from '@/common/util';
import { DEFAULTS } from "@/global";
import axios from 'axios';

export default defineComponent({
	props: {  
		src: {
			type: String,
			default: '',
		},
	},
	setup(props: any, ctx: any)
	{
		const uploadFilePicked = async (e: any) => {
			let formData = new FormData();
			formData.append('file', e.target.files[0]);
			try {
				const data = (await axios.post(`${DEFAULTS.URL}file/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
					ctx.emit('upload', data.data);
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}

		return {
			uploadFilePicked
		}
	},
});
</script>

<style>
.upload {
	cursor: pointer;
	width: 150px;
	height: 150px;
	position: relative;
}
.upload img {
	background-color: #00193A;
	width: 100%;
	height: 100%;
}
.upload input {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	outline: 0;
	opacity: 0;
}
</style>
