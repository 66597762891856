<template>
 	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
		    <div class="align-self-center mb-3" style="height: 30px;">
		        <button type="button" class="btn btn-primary" @click="openAddExcelDialog">添加</button>
		    </div>
		    <div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
		        <select class="form-select" v-model="shop">
		        	<option v-for="(value, idx) in shops" :key='idx' :value="value.id">{{value.name}}</option>
		        </select>
		    </div>
		</div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th scope="col">序号</th><th scope="col">客户ID</th><th scope="col">姓名</th><th scope="col">签单时间</th><th scope="col">操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.customer}}</td>
						<td>{{value.profile?.name || "未知"}}</td>
						<td>{{value.operateDate}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditExcelDialog(value)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadExcelList" />
		</div>
	</div>

	<AddExcelDialog :show='showAddDialog' :item='editing' @cancel='showAddDialog = false' @confirm='editExcel'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddExcelDialog from '@/modals/AddExcelDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request, {DEFAULTS} from "@/global";
import {buildHttpHeader, checkResponseDataAndAlert, dateFtt} from '@/common/util';
import axios from "axios";

export default({
	components: { Pagination, AddExcelDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			keywords: "",
			type: "KHYDD",
			shop: "",
			editing: {} as any,
			shops: [] as any[],
		});

		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);

		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadShops();
		});

		watch(()=>refData.shop, ()=>{
			loadExcelList(1);
		})

		// 加载列表
		const loadShops = async () => {
			try {
				const data = (await request({url: `shop/list/1/100`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
					refData.shops = data.data;
					refData.shop = data.data[0].id;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};

		// ========================文章标签========================
		// 添加标签
		const openAddExcelDialog = () => {
			refData.editing = {};
			refData.showAddDialog = true;
		};

		// 编辑信息
		const openEditExcelDialog = async (value: any) => {
		    const errMsg = "查询信息失败";
			try {
				const data = (await request({url: `excel/${value.customer}/${refData.type}`, get: true})).data;
		        if (checkResponseDataAndAlert(data, errMsg) && data.data) {
					refData.editing = data.data;
          refData.editing.detailObj = JSON.parse(refData.editing.detail);
          await loadMealList();
					// const detailObj = JSON.parse(refData.editing.detail);
					// refData.editing.detail = JSON.stringify(detailObj, null, "\t");
					refData.showAddDialog = true;
		        }
			} catch (e) {
				//console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}

    const loadMealList = async () => {
      try {

        let data = (await axios.get(`${DEFAULTS.URL}shop/meal/list/1/100?shop=${refData.editing.detailObj.shop_id}`, {headers: buildHttpHeader()})).data;
        if (checkResponseDataAndAlert(data) && data.data) {
          let dataListTmp = [];
          for(let i = 0; i < data.data.length; i++){
            let tmpItem = data.data[i];
            if (tmpItem.priceList && tmpItem.priceList.length > 0) {  // && tmpItem.show
              for(let j = 0; j < tmpItem.priceList.length; j++) {
                let priceTmp = tmpItem.priceList[j];
                dataListTmp.push({
                  id: tmpItem.id,
                  price: priceTmp.price,
                  days: priceTmp.days,
                  title: tmpItem.title + '-' + priceTmp.days + '天-' + priceTmp.price + '元',
                  ckPack: priceTmp.ckPack,
                  desc: tmpItem.desc
                });
              }
            }
          }
          refData.editing.mealList = dataListTmp
        }
      } catch (e) {
        checkResponseDataAndAlert({});
      }
    };

		// 编辑
		const editExcel = async (id: string, postObj: any) =>  {
		    const errMsg = `操作失败`;
			if (!postObj.detail || postObj.detail.length < 2) {
				alert("请完善带*的必填项");
				return;
			}

		    refData.showLoadingDialog = true;
			try {
				const data = (await request({url: `excel/update/${id}`, data: postObj})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadExcelList(nowPage.value);
		        }
			} catch (e) {
				//console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}

		// 加载列表
		const  loadExcelList = async (page: number) => {
		    if (page < 1) return;

		    nowPage.value = page;
			try {
				const data = (await request({url: `excel/list/${page}/${50}?type=${refData.type}&shop=${refData.shop}`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};

		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,

			dateFtt,
			editExcel,
			loadExcelList,
			openAddExcelDialog,
			openEditExcelDialog,

			...dataRef
		};
	},
});
</script>

<style>
</style>
