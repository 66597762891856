<template>
  <div class="flex-colum h-100">
    <div class="d-flex justify-content-between bd-highlight mb-3">
      <div class="align-self-center mb-3" style="height: 30px;">
        <button type="button" class="btn btn-primary" @click="openAddQuestionDialog">添加</button>
      </div>
      <div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 40%;">
        &nbsp;&nbsp;<select class="form-select" v-model="examType" @change="searchQuestionInner">
        <option selected value="">选择试卷类型</option>
        <option value="system">系统</option>
        <option value="meal">项目</option>
        </select>
        &nbsp;&nbsp;<select class="form-select" v-model="questionType" @change="searchQuestionInner">
          <option selected value="">选择题类型</option>
          <option value="trueFalse">判断题</option>
          <option value="radio">单选题</option>
          <option value="checkbox">多选题</option>
        </select>
        &nbsp;&nbsp;<input type="text" id="article-keywords" class="form-control"  v-model="keywords" placeholder="标题/选项">
        <button class="btn btn-outline-secondary" type="button" @click="searchQuestionInner">搜索</button>
      </div>
    </div>
    <div>
      <table class="table table-bordered table-striped table-sm member-list">
        <thead>
        <tr>
          <th style="width: 50px;">序号</th><th style="width: 100px;">题目类型</th><th  style="width: 100px;">考题类型</th>
          <th style="width: 300px;">标题</th><th style="width:300px;">答题选项</th><th style="width:100px;">答案</th><th>状态</th><th>排序</th><th>添加时间</th><th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value, idx) in dataList" :key='idx'>
          <td>{{50 * (nowPage - 1) + idx + 1}}</td>
          <td>{{ value.examType === 'system'?'系统':'项目'}}</td>
          <td>{{getQuestionType(value.questionType)}}</td>
          <td>{{value.title}}</td>
          <td><div class="flex-colum"><div v-for="(v, index) in value.optionList">{{index+1}}、&ensp;{{v.option}}</div></div></td>
          <td><div class="flex-colum"><div v-for="(v, index) in value.answerList">{{getAnswer(v, value)}}</div></div></td>
          <td>
            <div class="mb-1 form-check form-switch" style="padding-top: 5px;">
              <input class="form-check-input" :checked="value.show" @change="updateQuestion(value)" type="checkbox" role="switch"/>
            </div>
          </td>
          <td>{{value.sort}}</td>
          <td>{{value.createTime}}</td>
          <td>
            <div class="flex-row flex-align-center">
              <img class="button-icon" src="/assets/ic_edit.png" @click="openEditQuestionDialog(value)" />
              <img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteQuestion(value.id, value.title)" />
              <!-- <img class="button-icon img-margin" src="/assets/ic_comment.png" @click="openPage('comment/${value.id}')" /> -->
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadQuestionList" />
    </div>
  </div>

  <AddExamQuestionDialog ref="addDialogRef" :show='showAddDialog' @confirm='editExamQuestion' @confirmAdd='editExamQuestionAdd' @confirmNext='editExamQuestionNext' @cancel='showAddDialog=false'/>
  <LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddExamQuestionDialog from '@/modals/AddExamQuestionDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt } from '@/common/util';

export default({
  components: { Pagination, AddExamQuestionDialog },
  setup() {
    const refData = reactive({
      showAddDialog: false,
      showLoadingDialog: false,
      keywords: "",
      questionType:"",
      examType:""
    });

    const addDialogRef = ref<any>(null) // 通过 ref 绑定子组件
    const totalPage = ref<number>(0);
    const nowPage = ref<number>(1);
    const dataList = ref<any[]>([]);

    //needed in case we switch in from another window
    onMounted(async () => {
      await loadQuestionList(1);
    });


    const openAddQuestionDialog = () => {
      addDialogRef.value!.setItemData({'show': true,"examType":"meal", "questionType":"trueFalse","title":"","optionList":[], "answerList": []})
      refData.showAddDialog = true;
    }

    const getQuestionType = (questionType:any) => {
      if(questionType === 'trueFalse') {
        return "判断题";
      }else if(questionType === 'radio'){
        return '单选题';
      }else if(questionType === 'checkbox'){
        return '多选题';
      }
    }

    const getAnswer = (answer:any, rowValue:any) =>{
        for(let i = 0; i < rowValue.optionList.length; i++){
            if(rowValue.optionList[i].number == answer){
                return i+1;
            }
        }
    }

    // 跳转编辑页
    const openEditQuestionDialog = async (value: any) => {

      const errMsg = "查询信息失败";
      try {
        //const data = (await request({url: `examinationQuestionController/get/${id}`, get: true})).data;
        //if (checkResponseDataAndAlert(data, errMsg) && data.data) {
          addDialogRef.value!.setItemData(JSON.parse(JSON.stringify(value)));
          refData.showAddDialog = true;
        //}
      } catch (e) {
        console.log(e);
        checkResponseDataAndAlert({}, errMsg);
      }
    }

    const updateQuestion  = async (item:any) => {
      refData.showLoadingDialog = true;
      try {
        const postObj = item;
        postObj.show = !postObj.show;
        console.log(postObj)
        const data = (await request({url: `examinationQuestionController/update`, data: postObj})).data;
        refData.showLoadingDialog = false;
        if (checkResponseDataAndAlert(data, "操作失败")) {
        } else {
          loadQuestionList(nowPage.value);
        }
      } catch (e) {
        console.log(e);
        refData.showLoadingDialog = false;
        checkResponseDataAndAlert({}, "操作失败");
      }
    }

    const editExamQuestionAdd = async (postObj: any) =>{
      let gid = postObj.id;
      const errMsg = `${(gid && gid.length > 0) ? '编辑' : '添加'}`;

      refData.showLoadingDialog = true;
      try {
        let data = {};
        if(gid != undefined) {
          data = (await request({url: `examinationQuestionController/update`, data: postObj})).data;
        }else {
          data = (await request({url: `examinationQuestionController/add`, data: postObj})).data;
        }
        refData.showLoadingDialog = false;
        if (checkResponseDataAndAlert(data, errMsg)) {
          loadQuestionList(nowPage.value);
          openAddQuestionDialog();
        }
      } catch (e) {
        console.log(e);
        refData.showLoadingDialog = false;
        checkResponseDataAndAlert({}, errMsg + " 添加试题失败");
      }
    }

    const editExamQuestionNext = async (postObj: any) =>{
      alert("editExamQuestionNext")
    }

    // 编辑
    const  editExamQuestion = async (postObj: any) => {
      let gid = postObj.id;
      const errMsg = `${(gid && gid.length > 0) ? '编辑' : '添加'}`;

      refData.showLoadingDialog = true;
      try {
        let data = {};
        if(gid != undefined) {
          data = (await request({url: `examinationQuestionController/update`, data: postObj})).data;
        }else {
          data = (await request({url: `examinationQuestionController/add`, data: postObj})).data;
        }
        refData.showLoadingDialog = false;
        if (checkResponseDataAndAlert(data, errMsg)) {
          refData.showAddDialog = false;
          loadQuestionList(nowPage.value);
        }
      } catch (e) {
        console.log(e);
        refData.showLoadingDialog = false;
        checkResponseDataAndAlert({}, errMsg + " 添加试题失败");
      }
    }

    // 删除
    const deleteQuestion = async (id: string, title:String) => {
      if (confirm(`确认删除 ${title} 吗？`)) {
        const errMsg = "删除失败";
        try {
          const data = (await request({url: `examinationQuestionController/del/${id}`, get: true})).data;
          if (checkResponseDataAndAlert(data, errMsg)) {
            alert("删除成功")
            loadQuestionList(nowPage.value);
          }
        } catch (e) {
          checkResponseDataAndAlert({}, errMsg);
        }
      }
    }

    // 搜索
    const searchQuestionInner = async () => {

      nowPage.value = 1;
      try {
        const postObj = {"page" : 1, "size": 50, "searchValue": refData.keywords, "questionType":refData.questionType, "examType":refData.examType};
        const data = (await request({url: `examinationQuestionController/list`, data: postObj})).data
        if (checkResponseDataAndAlert(data) && data.data) {
          dataList.value = data.data;
          totalPage.value = data.total;
        }
      } catch (e) {
        checkResponseDataAndAlert({});
      }
    }

    // 加载列表
    const  loadQuestionList = async (page: number) => {
      if (page < 1) return;

      dataList.value = [];
      nowPage.value = page;
      try {
        //let postObj = new Object();
        let postObj = {
          page:page,
          size:50
        };
        const data = (await request({url: `examinationQuestionController/list`, data: postObj})).data;
        if (checkResponseDataAndAlert(data) && data.data) {
          dataList.value = data.data;
          totalPage.value = data.total;
        }
      } catch (e) {
        checkResponseDataAndAlert({});
      }
    }

    const dataRef = toRefs(refData);
    return {
      dataList,
      nowPage,
      totalPage,

      dateFtt,
      editExamQuestion,
      editExamQuestionAdd,
      editExamQuestionNext,
      loadQuestionList,
      openAddQuestionDialog,
      openEditQuestionDialog,
      deleteQuestion,
      getQuestionType,
      getAnswer,
      addDialogRef,
      updateQuestion,
      searchQuestionInner,
      ...dataRef
    };
  },
});
</script>

<style>
</style>
