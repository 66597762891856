export function isTextValid(text?: string) {
	return text && text.trim().length > 0;
}

export function shortenName(text: string) {
	let index = text.indexOf("(");
	index = index < 0 ? text.indexOf("（") : index;
	if (index > 0) return text.substring(0, index);
	return text;
}

// 显示HTTP响应提示
export function checkResponseDataAndAlert(data:any, message?: string) {
    if (data.message && data.message.length)
        alert(data.message);
    else if (data.code !== 200)
        alert(message ? message : "加载失败，请重试");
    return data.code === 200;
}

/**************************************时间格式化处理************************************/
export function dateFtt(fmt: string, date: Date) {
    var o = {
        "M+" : date.getMonth()+1, //月份
        "d+" : date.getDate(), //日
        "h+" : date.getHours(), //小时
        "m+" : date.getMinutes(), //分
        "s+" : date.getSeconds(), //秒
        "q+" : Math.floor((date.getMonth()+3)/3), //季度
        "S" : date.getMilliseconds()    //毫秒
    };
    if(/(y+)/.test(fmt))
        fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length));
    for(var k in o) {
		const kk = k as keyof typeof o;
        if(new RegExp("("+ k +")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length===1) ? ("" + o[kk]) : (("00"+ o[kk]).substr((""+ o[kk]).length)));
	}
    return fmt;
}

export function checkNumber(element: any, defValue: number, maxValue: number) {
    var value = parseInt(element.value, 10);
    if (value && value === 0) value = 1;
    if (value && value < 0) value = -value;
    if (value && value > maxValue) value = maxValue;
    element.value = value ? value : defValue
}

export function toPostData(data: any, intKeys: string[]) {
	const postData = JSON.parse(JSON.stringify(data));
	for (let key of intKeys) {
		postData[key] = postData[key] ? 1 : 0;
	}
	return postData;
}

export function toBindData(data: any, boolKeys: string[]) {
	const bindData = JSON.parse(JSON.stringify(data));
	for (let key of boolKeys) {
		bindData[key] = (bindData[key] || 0) > 0;
	}
	return bindData;
}

export function loadTokens() {
	return sessionStorage.getItem("token") as any;
}

export function buildPlainTextHeader() {
	return {'Content-type':'text/plain;charset=utf-8'};
}

export function buildPlainTextWithAuthHeader() {
	const token = sessionStorage.getItem("token") ?? "{}";
	const data = JSON.parse(token);
	return {'Authorization': "Bearer " + (data.accessToken ?? "-"), 'Content-type':'text/plain;charset=utf-8'};
}

export function buildHttpHeader() {
	const token = sessionStorage.getItem("token") ?? "{}";
	const data = JSON.parse(token);
    return {'Authorization': "Bearer " + (data.accessToken ?? "-")};
}

export function downloadTextAsFile(text: string, fileName: string) {
	var dataStr = "data:text/json;charset=utf-8," + text;
	var downloadAnchorNode = document.createElement('a');
	downloadAnchorNode.setAttribute("href",     dataStr);
	downloadAnchorNode.setAttribute("download", fileName);
	document.body.appendChild(downloadAnchorNode); // required for firefox
	downloadAnchorNode.click();
	downloadAnchorNode.remove();
}

export function shortenTitle(title: string, len: number) {
	if (title.length <= len) return title;
	return title.substring(0, len);
}