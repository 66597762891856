<template>
 	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
		    <div class="align-self-center mb-3" style="height: 30px;">
		        <button type="button" class="btn btn-primary" @click="openEditTagDialog()">添加</button>
		    </div>
		</div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th width="80px">序号</th><th>部门名称</th><th>排序</th><th width="120px">操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.title}}</td>
						<td>{{value.order}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditTagDialog(value)"/>
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteTag(value)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		
		<div class="modal-backdrop" v-if="showAddDialog">
		   <div class="modal2">
		      <div class="modal-header flex-row flex-align-center">
		        <div class="text-center flex-1 modal-header-title">添加</div>
		      </div>
		      <div class="modal-body flex-row flex-wrap">
					<table class="table table-borderless">
						<tr><td style="width: 100px;"></td><td></td></tr>
						<tr>
							<td><div class="mb-1 td-label-right"><label for="class-title">*名称:</label></div></td>
							<td><div class="mb-1"><input type="text" class="form-control" v-model="editing.title"></div></td>
						</tr>
						<tr>
							<td><div class="mb-1 td-label-right"><label for="class-order">排序:</label></div></td>
							<td><div class="mb-1"><input type="number" class="form-control" v-model="editing.order"></div></td>
						</tr>
					</table>
		      </div>
				<div class="flex-row-center modal-footer2">
					<button type="button" class="btn btn-secondary" @click="showAddDialog=false">取消</button>
					<div style="width: 40px;"></div>
					<button type="button" class="btn btn-primary" @click="editTag(editing)">确定</button>
				</div>
		  </div>
			<div id="datePickerContainerId"></div>
		</div>
	</div>

	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import { checkResponseDataAndAlert, dateFtt, buildHttpHeader, buildPlainTextWithAuthHeader } from '@/common/util';
import request from "@/global";

export default({
	components: { Pagination, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			shopList: [],
			editing: {}
		});
		
		const type = 'dept';
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadTagList(1);
		  loadShops()
		});

		// 编辑
		const openEditTagDialog = async (tag: any) => {
			refData.editing = tag ? tag : {};
			refData.showAddDialog = true;
		}
		
		// 加载列表
		const loadShops = async () => {
			try {
				const data = (await request({url: `shop/list/1/100`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
					refData.shopList = data.data;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};
		
		// 编辑
		const editTag = async (tag: any) =>  {
		    const errMsg = `操作失败`;		
			if (!tag.title || tag.title.length < 1) {
				alert("请完善带*的字段");
				return;
			}
		
		    refData.showLoadingDialog = true;
			try {
				tag.type = type;
				const data = (await request({url: `preset/save`, data: tag})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadTagList(nowPage.value);
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 删除
		const deleteTag = async (tag: any) => {
			if (confirm(`确认删除 ${tag.title} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await request({url: `preset/delete/${tag.id}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						loadTagList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};
			
		// 加载列表
		const  loadTagList = async (page: number) => {
		    if (page < 1) return;
		
		    nowPage.value = page;
			try {
				const data = (await request({url: `preset/list/1/200?type=${type}`, get: true})).data;
				console.log(data);
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.data.length;
				}
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({});
			}
		};
		
		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
			
			editTag,
			loadTagList,
			openEditTagDialog,
			deleteTag,
			
			...dataRef
		};
	},
});
</script>

<style>
</style>