<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{item.type}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="app-home-title">标题:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.title">
						</div>
					</td>
				</tr>
				<tr v-if="item.type!=='栏目'">
					<td>
						<div class="mb-1 td-label-right">
							<label for="app-home-title2">二级标题:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.title2">
						</div>
					</td>
				</tr>
				<tr v-if="item.type!=='栏目'">
					<td>
						<div class="mb-1 td-label-right">
							<label for="app-home-desc">描述:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.desc">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="app-home-button">按钮:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.button">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="app-home-order">排序:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="number" class="form-control" v-model="item.order">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="app-home-link">链接:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.link">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="app-home-show">是否显示:</label>
						</div>
					</td>
					<td>
						<div class="mb-1 form-switch">
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="item.show">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="app-home-image">图片:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<ImageUploader :src='item.image' @upload='imageUpload' />
						</div>
					</td>
				</tr>
				<tr id="app-home-children" v-if="item.type === '内容' && item.id">
					<td>
						<div class="mb-1 td-label-right">
							<label for="app-home-image">子分类:</label>
						</div>
					</td>
					<td>
						<div class="flex-row flex-wrap mb-1">
							<img v-for="(value, index) in item.children" :src='value.image' :key='index' @click="openEditChildDialog(value)" class="image-preview-small" />
							<img src="/assets/image_preview_add.png" class="image-preview-small" @click="openAddAppHomeChildDialog" />
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>
  
  <AppHomeChildDialog :show='showAddDialog' :item='editing' @cancel='showAddDialog=false' @confirm='submitAppHomeChild' />
  <LoadingDialog :show='showLoadingDialog' />
</template>
 
<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt, buildHttpHeader } from '@/common/util';
import ImageUploader from '@/components/ImageUploader.vue'
import AppHomeChildDialog from '@/modals/AppHomeChildDialog.vue'
import LoadingDialog from '@/modals/LoadingDialog.vue';
import { DEFAULTS } from "@/global";
import axios from 'axios';
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
	components: { ImageUploader, AppHomeChildDialog, LoadingDialog },
	props: {  
		show: {
			type: Boolean,
			default: false, 
		},
		item: {
			type: Object,
			default: {},
		},
	},

  setup(props: any, ctx: any)
  {
	const refData = reactive({
		showAddDialog: false,
		showLoadingDialog: false,
		editing: {},
	});
	  
	onMounted(async () => {
	});
 
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		ctx.emit('confirm', toPostData(props.item, ['show']));
	}
	
	const imageUpload = (url: string) => {
		props.item.image = url
	}
	
	const openAddAppHomeChildDialog = () => {
		refData.editing = {}
		refData.showAddDialog = true;
	}
	
	const openEditChildDialog = (child: any) => {
		console.log("openEditChildDialog", child)
		refData.editing = child;
		refData.showAddDialog = true;
	}
	
	const submitAppHomeChild = async (postObj: any) => {
		const aid = props.item.id;
		if ((!aid || aid.length === 0)
			||(!postObj.title || postObj.title.length === 0)
			|| (!postObj.link || postObj.link.length === 0)
			|| !postObj.image || postObj.image.length === 0) {
			alert("带*的内容不能为空");
			return
		}

		const errMsg = `操作失败`;
		refData.showLoadingDialog = true;
		try {
			const data = (await axios.post(`${DEFAULTS.URL}app/home/child/save/${aid}`, postObj, {headers: buildHttpHeader()})).data;
			refData.showLoadingDialog = false;
		    if (checkResponseDataAndAlert(data, errMsg)) {
				refData.showAddDialog = false;
		        ctx.emit('reopen', aid);
		    }
		} catch (e) {
			console.log(e);
			refData.showLoadingDialog = false;
			checkResponseDataAndAlert({}, errMsg);
		}
	}

	const dataRef = toRefs(refData);

    return {
      cancel,
	  confirm,
	  imageUpload,
	  openAddAppHomeChildDialog,
	  submitAppHomeChild,
	  openEditChildDialog,
	  
	  ...dataRef
    }
  },
});
</script>

<style scoped>
.form-check-input {
	margin-left: 0;
}

.upload-music {
	width: 40px;
	height: 40px;
	position: relative;
}
.upload-music img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}
.upload-music input {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	outline: 0;
	opacity: 0;
}
</style>