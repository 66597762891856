<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{item.id ? '编辑' : '添加'}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td><div class="mb-1 td-label-right">*门店:</div></td>
					<td>
						<div class="mb-1">
							<select class="form-select" v-model="item.shop">
								<option v-for="(value, idx) in dataList" :key='idx' :value="value.id">{{value.name}}</option>
							</select>
						</div>
					</td>
				</tr>
				<tr v-if="!isBlue">
					<td><div class="mb-1 td-label-right">*入住天数:</div></td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.days">
						</div>
					</td>
				</tr>
				<tr v-if="isBlue">
					<td><div class="mb-1 td-label-right">*BMI:</div></td>
					<td>
						<div class="mb-1 flex-row flex-align-center">
							<input type="number" class="form-control" v-model="item.bmiFrom"/>
							<div class="ml-2 mr-2">至</div>
							<input type="number" class="form-control" v-model="item.bmiTo"/>
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="2"><div style="margin-left: 100px;">菜品用换行分隔</div></td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right">*早餐:</div></td>
					<td>
						<div class="flex-row flex-align-center mb-1">
							<div class="flex-col flex-1 mr-5" style="min-height:100px">
								<input type="text" class="form-control mb-2" placeholder="标题" v-model="item.zaocan.title" v-if="isBlue">
								<textarea :rows="isBlue ? 2 : 3" class="form-control flex-1" placeholder="内容" v-model="item.zaocan.content"></textarea>
								<div class="flex-row flex-align-center mt-2" v-if="isBlue">
									<input type="text" class="form-control" placeholder="推荐搭配1" v-model="item.zaocan.dapei1" >
									<input type="text" class="form-control ml-2" placeholder="推荐搭配2" v-model="item.zaocan.dapei2">
								</div>
							</div>
							<ImageUploader :src='item.zaocan.image' @upload='imageUpload1' class="uploader" />
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right">*午间餐:</div></td>
					<td>
						<div class="flex-row flex-align-center mb-1">
							<div class="flex-col flex-1 mr-5" style="min-height:100px">
								<input type="text" class="form-control mb-2" placeholder="标题" v-model="item.wujiancan.title" v-if="isBlue">
								<textarea :rows="isBlue ? 2 : 3" class="form-control flex-1" placeholder="内容" v-model="item.wujiancan.content"></textarea>
								<div class="flex-row flex-align-center mt-2" v-if="isBlue">
									<input type="text" class="form-control" placeholder="推荐搭配1" v-model="item.wujiancan.dapei1" >
									<input type="text" class="form-control ml-2" placeholder="推荐搭配2" v-model="item.wujiancan.dapei2">
								</div>
							</div>
							<ImageUploader :src='item.wujiancan.image' @upload='imageUpload2' class="uploader" />
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right">*午餐:</div></td>
					<td>
						<div class="flex-row flex-align-center mb-1">
							<div class="flex-col flex-1 mr-5" style="min-height:100px">
								<input type="text" class="form-control mb-2" placeholder="标题" v-model="item.wucan.title" v-if="isBlue">
								<textarea :rows="isBlue ? 2 : 3" class="form-control flex-1" placeholder="内容" v-model="item.wucan.content"></textarea>
								<div class="flex-row flex-align-center mt-2" v-if="isBlue">
									<input type="text" class="form-control" placeholder="推荐搭配1" v-model="item.wucan.dapei1" >
									<input type="text" class="form-control ml-2" placeholder="推荐搭配2" v-model="item.wucan.dapei2">
								</div>
							</div>
							<ImageUploader :src='item.wucan.image' @upload='imageUpload3' class="uploader" />
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right">*间餐:</div></td>
					<td>
						<div class="flex-row flex-align-center mb-1">
							<div class="flex-col flex-1 mr-5" style="min-height:100px">
								<input type="text" class="form-control mb-2" placeholder="标题" v-model="item.jiancan.title" v-if="isBlue">
								<textarea :rows="isBlue ? 2 : 3" class="form-control flex-1" placeholder="内容" v-model="item.jiancan.content"></textarea>
								<div class="flex-row flex-align-center mt-2" v-if="isBlue">
									<input type="text" class="form-control" placeholder="推荐搭配1" v-model="item.jiancan.dapei1" >
									<input type="text" class="form-control ml-2" placeholder="推荐搭配2" v-model="item.jiancan.dapei2">
								</div>
							</div>
							<ImageUploader :src='item.jiancan.image' @upload='imageUpload4' class="uploader" />
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right">*晚餐:</div></td>
					<td>
						<div class="flex-row flex-align-center mb-1">
							<div class="flex-col flex-1 mr-5" style="min-height:100px">
								<input type="text" class="form-control mb-2" placeholder="标题" v-model="item.wancan.title" v-if="isBlue">
								<textarea :rows="isBlue ? 2 : 3" class="form-control flex-1" placeholder="内容" v-model="item.wancan.content"></textarea>
								<div class="flex-row flex-align-center mt-2" v-if="isBlue">
									<input type="text" class="form-control" placeholder="推荐搭配1" v-model="item.wancan.dapei1" >
									<input type="text" class="form-control ml-2" placeholder="推荐搭配2" v-model="item.wancan.dapei2">
								</div>
							</div>
							<ImageUploader :src='item.wancan.image' @upload='imageUpload5' class="uploader" />
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right">*宵夜:</div></td>
					<td>
						<div class="flex-row flex-align-center mb-1">
							<div class="flex-col flex-1 mr-5" style="min-height:100px">
								<input type="text" class="form-control mb-2" placeholder="标题" v-model="item.xiaoye.title" v-if="isBlue">
								<textarea :rows="isBlue ? 2 : 3" class="form-control" placeholder="内容" v-model="item.xiaoye.content"></textarea>
								<div class="flex-row flex-align-center mt-2" v-if="isBlue">
									<input type="text" class="form-control" placeholder="推荐搭配1" v-model="item.xiaoye.dapei1" >
									<input type="text" class="form-control ml-2" placeholder="推荐搭配2" v-model="item.xiaoye.dapei2">
								</div>
							</div>
							<ImageUploader :src='item.xiaoye.image' @upload='imageUpload6' class="uploader" />
						</div>
					</td>
				</tr>
				<tr v-if="isBlue">
					<td><div class="mb-1 td-label-right">*补充说明:</div></td>
					<td>
						<div class="flex-col flex-align-center mb-1">
							<div class="mb-2">用,号分隔每一个要点</div>
							<textarea :rows="isBlue ? 2 : 3" class="form-control flex-1" v-model="item.detail"></textarea>
						</div>
					</td>
				</tr>
				<tr v-if="isBlue">
					<td><div class="mb-1 td-label-right">*材料选择:</div></td>
					<td>
						<div class="flex-col flex-align-center mb-1">
							<div v-for="(v, idx) in item.choice">
								<div class="mb-1">{{ v.title }}</div>
								<textarea :rows="3" class="form-control mb-2" v-model="v.content"></textarea>
							</div>
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
	<div id="datePickerContainerId"></div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import ImageUploader from '@/components/ImageUploader.vue'
import { DEFAULTS } from "@/global";
import axios from 'axios';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
	components: { ImageUploader, Datepicker },
	props: {  
		show: {
			type: Boolean,
			default: false, 
		},
		item: {
			type: Object,
			default: {},
		},
	},

  setup(props: any, ctx: any)
  {
	const dataList = ref<any[]>([]);
	const isBlue = ref<boolean>(process.env.VUE_APP_ENV === "blue")
	
	onMounted(async () => {
		loadShops();
	});
	
	// 加载列表
	const loadShops = async () => {
		try {
			const data = (await axios.get(`${DEFAULTS.URL}shop/list/1/100`)).data;
			if (checkResponseDataAndAlert(data) && data.data) {
				dataList.value = data.data;
			}
		} catch (e) {
			checkResponseDataAndAlert({});
		}
	};
 
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		// console.log(JSON.stringify(toPostData(props.item, [])))
		ctx.emit('confirm', toPostData(props.item, []));
	}
	
	const imageUpload1 = (url: string) => {
		props.item.zaocan.image = url
	}
	const imageUpload2 = (url: string) => {
		props.item.wujiancan.image = url
	}
	const imageUpload3 = (url: string) => {
		props.item.wucan.image = url
	}
	const imageUpload4 = (url: string) => {
		props.item.jiancan.image = url
	}
	const imageUpload5 = (url: string) => {
		props.item.wancan.image = url
	}
	const imageUpload6 = (url: string) => {
		props.item.xiaoye.image = url
	}

    return {
      cancel,
	  confirm,
	  checkNumber,
	  imageUpload1,
	  imageUpload2,
	  imageUpload3,
	  imageUpload4,
	  imageUpload5,
	  imageUpload6,
	  dataList,
	  isBlue,
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
	.uploader {
		width: 100px; height: 100px;
	}
</style>