<template>
 	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
		    <div class="align-self-center mb-3" style="height: 30px;">
				<button type="button" class="btn btn-primary ml-5" @click="exportData">导出</button>
		    </div>
		</div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th scope="col">序号</th><th scope="col">姓名</th><th scope="col">客户编号</th><th scope="col">跟进人</th><th scope="col" style="width: 40%;">跟进内容</th><th scope="col" style="width: 180px;">跟进时间</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in customers" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.profile?.name || "未提供"}}</td>
						<td>{{value.profile?.id || "-"}}</td>
						<td>{{value.operatorName || "-"}}</td>
						<td>{{value.detail || "-"}}</td>
						<td v-if="value.operateDate">{{value.operateDate + " " + (value.clockTime || '')}}</td>
						<td v-else>{{dateFtt('yyyy-MM-dd hh:mm', new Date(value.operateTime))}}</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadCustomerList" />
		</div>
	</div>
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddCustomerDialog from '@/modals/AddCustomerDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import { DEFAULTS } from "@/global";
import { checkResponseDataAndAlert, dateFtt, buildHttpHeader, downloadTextAsFile } from '@/common/util';
import axios from 'axios';

export default({
	components: { Pagination, AddCustomerDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			keywords: "搜索客户",
			editing: {}
		});
		
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const customers = ref<any[]>([]);
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadCustomerList(1);
		});

		// 加载列表
		const  loadCustomerList = async (page: number) => {
		    if (page < 1) return;
		
		    nowPage.value = page;
			try {
				const data = (await axios.get(`${DEFAULTS.URL}customer/follow/list/all/${page}/${50}?all=1`, {headers: buildHttpHeader()})).data;
				console.log(data);
				if (checkResponseDataAndAlert(data) && data.data) {
				    customers.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};
		
		// 加载列表
		const  exportData = async () => {
			try {
				const data = (await axios.get(`${DEFAULTS.URL}customer/follow/export`, {headers: buildHttpHeader()})).data;
				console.log(data)
				if (checkResponseDataAndAlert(data) && data.data) {
					downloadTextAsFile(data.data, "跟进.csv")
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};
		
		const dataRef = toRefs(refData);
		return {
			customers,
			nowPage,
			totalPage,
			
			dateFtt,
			loadCustomerList,
			exportData,
			
			...dataRef
		};
	},
});
</script>

<style>
</style>