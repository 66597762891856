<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2" style="width: 900px;">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{title}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr id="article-content-tr">
					<td>
						<div class="mb-1">
							<RichEditor v-model="itemData.data.content"/>
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="flex-row-center modal-footer2">
			<button type="button" class="btn btn-primary" @click="confirm">提交</button>
		</div>
    </div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, defineComponent, ref, onMounted, watch } from "vue";
import RichEditor from '@/components/RichEditor.vue'
import ImageUploader from '@/components/ImageUploader.vue'
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import { DEFAULTS } from "@/global";
import axios from 'axios';

export default defineComponent({
  components: { RichEditor, ImageUploader },
  props: {
	title: {
		type: String,
		default: '', 
	},
    show: {
        type: Boolean,
        default: false, 
    },
  },

  setup(props: any, ctx: any)
  {
	const itemData = reactive({data: {content: null, type: ''}});
	
	onMounted(async () => {
	});
		
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		ctx.emit('confirm', itemData.data.type, itemData.data.content);
	}
	
	const setItemData = (value: any) => {
		itemData.data = value;
	}
	
    return {
      cancel,
	  confirm,
	  setItemData,

	  itemData
    }
  },
});
</script>

<style scoped>
.form-check-input {
	margin-left: 0;
}
</style>