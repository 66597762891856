<template>
	<nav aria-label="Page navigation">
		<ul class="pagination justify-content-center">
			<li class="page-item" :class="{'disabled': page === 1}">
				<a class="page-link" @click="loadPage(page - 1)">上一页</a>
			</li>
			<li v-for="(pageNum, idx) in prev" :key="index" class="page-item" :class="{'active': page === pageNum}">
				<a class="page-link" :click="loadPage(pageNum)">{{pageNum}}</a>
			</li>
			<li v-for="(pageNum, idx) in next" class="page-item" :class="{'active': page === pageNum}">
				<a class="page-link" :click="loadPage(pageNum)">{{pageNum}}</a>
			</li>
			<li class="page-item" :class="{'disabled': page >= max}">
				<a class="page-link" @click="loadPage(page + 1)">下一页</a>
			</li>
		</ul>
	</nav>
</template>

<script>
import { ref, computed, defineComponent, watch } from 'vue';

export default defineComponent ({
	components: { },
	props: {
		page: Number,
		max: Number,
	},
	emits: ['loadPage'],
	setup(props, ctx) {
		const prev = ref([]);
		const next = ref([]);
		
		watch(props, async () => {
			const max = Math.ceil(props.max);
			const start = props.page - 4;
			let pageNumberCount = 0;
			for (let pageNum = start - 1; pageNumberCount < 9; pageNum--) {
			    if (pageNum < 1) break;
			    if (pageNum > max) break;
			    pageNumberCount += 1;
			}
			// 如果是最后四页，不全为九页
			for (var pageNum = start - 1; pageNumberCount < 9; pageNum--) {
			    if (pageNum < 1) break;
			    if (pageNum > max) break;
			    pageNumberCount += 1;
			}
		});
		
		const loadPage = (p) => {
			ctx.emit('loadPage', p);
		}
		
		return {
			prev,
			next,
			loadPage
		}
	}
});
</script>

<style>
</style>
