<template>
  <div class="flex-colum h-100">
    <div class="d-flex justify-content-between bd-highlight mb-3">
      <div class="align-self-center mb-3" style="height: 30px;">
        <button type="button" class="btn btn-primary" @click="openAddTutorialDialog">添加</button>
      </div>
      <div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
        <input type="text" id="article-keywords" class="form-control" v-model="keywords">
        <button class="btn btn-outline-secondary" type="button" @click="searchTutorialInner">搜索</button>
      </div>
    </div>
    <div>
      <table class="table table-bordered table-striped table-sm member-list">
        <thead>
        <tr>
          <th>序号</th><th>标题</th><th>分类</th><th>预览</th><th>视频</th><th>浏览次数</th><th>状态</th><th>排序</th><th>添加时间</th><th>操作</th>
        </tr>
        </thead>
        <tbody id="member-list">
        <tr v-for="(value, idx) in dataList" :key='idx' style="height:20px;">
          <td>{{50 * (nowPage - 1) + idx + 1}}</td>
          <td>{{value.title}}</td>
          <td>{{value.classify}}</td>
          <td><video width="150" height="150" controls :src="value.video"></video></td>
          <td>{{value.video}}</td>
          <td>{{value.viewTimes}}</td>
          <td>
            <div class="mb-1 form-check form-switch" style="padding-top: 5px;">
              <input class="form-check-input" :checked="value.show" @change="updateTutorial(value)" type="checkbox" role="switch"/>
            </div>
          </td>
          <td>{{value.sort}}</td>
          <td>{{value.createTime}}</td>
          <td>
            <div class="flex-row flex-align-center">
              <img class="button-icon" src="/assets/ic_edit.png" @click="openEditTutorialDialog(value.id)" />
              <img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteTutorial(value.id, value.title)" />
              <!-- <img class="button-icon img-margin" src="/assets/ic_comment.png" @click="openPage('comment/${value.id}')" /> -->
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadTutorialList" />
    </div>
  </div>

  <AddMealTutorialDialog ref="addDialogRef" :show='showAddDialog' @confirm='editTutorial' @cancel='showAddDialog=false'/>
  <LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddMealTutorialDialog from '@/modals/AddMealTutorialDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt } from '@/common/util';

export default({
  components: { Pagination, AddMealTutorialDialog },
  setup() {
    const refData = reactive({
      showAddDialog: false,
      showLoadingDialog: false,
      keywords: "搜索",
    });

    const addDialogRef = ref<any>(null) // 通过 ref 绑定子组件
    const totalPage = ref<number>(0);
    const nowPage = ref<number>(1);
    const dataList = ref<any[]>([]);

    //needed in case we switch in from another window
    onMounted(async () => {
      await loadTutorialList(1);
    });

    // 添加文章
    const openAddTutorialDialog = () => {
      addDialogRef.value!.setItemData({'show': true,"content":""})
      refData.showAddDialog = true;
    }


    // 跳转编辑页
    const openEditTutorialDialog = async (id: string) => {

      const errMsg = "查询信息失败";
      try {
        const data = (await request({url: `tutorial/get/${id}`, get: true})).data;
        if (checkResponseDataAndAlert(data, errMsg) && data.data) {
          addDialogRef.value!.setItemData(data.data)
          refData.showAddDialog = true;
        }
      } catch (e) {
        console.log(e);
        checkResponseDataAndAlert({}, errMsg);
      }
    }

    const updateTutorial  = async (item:any) => {
      refData.showLoadingDialog = true;
      try {
        const postObj = item;
        postObj.show = !postObj.show;
        console.log(postObj)
        const data = (await request({url: `tutorial/update`, data: postObj})).data;
        refData.showLoadingDialog = false;
        if (checkResponseDataAndAlert(data, "操作失败")) {
        } else {
          loadTutorialList(nowPage.value);
        }
      } catch (e) {
        console.log(e);
        refData.showLoadingDialog = false;
        checkResponseDataAndAlert({}, "操作失败");
      }
    }

    // 编辑
    const  editTutorial = async (postObj: any) => {
      let gid = postObj.id;
      const errMsg = `${(gid && gid.length > 0) ? '编辑' : '添加'}`;
      postObj.tutorialType = "meal";
      if (!postObj.title || postObj.video < 1) {
        alert("请完善带*的必填项");
        return;
      }

      refData.showLoadingDialog = true;
      try {
        let data = {}
        if(gid) {
          data = (await request({url: `tutorial/update`, data: postObj})).data;
        }else{
          data = (await request({url: `tutorial/add`, data: postObj})).data;
        }
        refData.showLoadingDialog = false;
        if (checkResponseDataAndAlert(data, errMsg)) {
          refData.showAddDialog = false;
          loadTutorialList(nowPage.value);
        }
      } catch (e) {
        console.log(e);
        refData.showLoadingDialog = false;
        checkResponseDataAndAlert({}, errMsg + "添加问答失败");
      }
    }

    // 删除
    const deleteTutorial = async (id: string, title:String) => {
      if (confirm(`确认删除 ${title} 吗？`)) {
        const errMsg = "删除失败";
        try {
          const data = (await request({url: `tutorial/del/${id}`, get: true})).data;
          if (checkResponseDataAndAlert(data, errMsg)) {
            alert("删除成功")
            loadTutorialList(nowPage.value);
          }
        } catch (e) {
          checkResponseDataAndAlert({}, errMsg);
        }
      }
    }

    // 搜索
    const searchTutorialInner = async () => {
      console.log(refData.keywords);
      if (refData.keywords.length === 0) {
        loadTutorialList(1);
        return;
      }

      nowPage.value = 1;
      try {
        const postObj = {"page" : 1,
          "size": 50,
          "title": refData.keywords,
          "tutorialType": "meal"};
        const data = (await request({url: `tutorial/list`, data: postObj})).data
        if (checkResponseDataAndAlert(data) && data.data) {
          dataList.value = data.data;
          totalPage.value = data.total;
        }
      } catch (e) {
        checkResponseDataAndAlert({});
      }
    }

    // 加载列表
    const  loadTutorialList = async (page: number) => {
      if (page < 1) return;

      dataList.value = [];
      nowPage.value = page;
      try {
        //let postObj = new Object();
        let postObj = {
          tutorialType:"meal",
          page:page,
          size:50
        };
        const data = (await request({url: `tutorial/list`, data: postObj})).data;
        if (checkResponseDataAndAlert(data) && data.data) {
          dataList.value = data.data;
          totalPage.value = data.total;
        }
      } catch (e) {
        checkResponseDataAndAlert({});
      }
    }

    const dataRef = toRefs(refData);
    return {
      dataList,
      nowPage,
      totalPage,

      dateFtt,
      editTutorial,
      loadTutorialList,
      openAddTutorialDialog,
      openEditTutorialDialog,
      deleteTutorial,
      addDialogRef,
      updateTutorial,
      searchTutorialInner,
      ...dataRef
    };
  },
});
</script>

<style>
</style>
