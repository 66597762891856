<template>
	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
			<div class="align-self-center mb-3" style="height: 30px;">
				<button type="button" class="btn btn-primary" @click="openAddPurchaseDialog">添加</button>
			</div>
 			<div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
				<input type="text" id="article-keywords" class="form-control" placeholder="搜索" v-model="keywords">
				<button class="btn btn-outline-secondary" type="button" @click="loadPurchaseList(1)">搜索</button>
			</div>
		</div>
 		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th>序号</th><th>客户ID</th><th>客户姓名</th><th>类型</th><th>应收</th><th>实收</th><th>扣卡</th><th>录入人</th><th>收款日期</th><th>录入时间</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.customer}}</td>
						<td>{{shortenName(value.customerName)}}</td>
						<td>{{value.type}}({{value.feeType}})</td>
						<td>{{value.fee}}</td>
						<td>{{value.cash}}</td>
						<td>{{value.cardFee || 0}}</td>
						<td>{{value.lururen}}</td>
						<td>{{value.operateDate}}</td>
						<td>{{dateFtt('yyyy-MM-dd hh:mm', new Date(value.operateTime))}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deletePurchase(value.id, value.customerName)" />
								<img class="button-icon img-margin" src="/assets/ic_edit.png" @click="showDetial(value)" />
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadPurchaseList" />
		</div>
	</div>
	
	<LoadingDialog :show='showLoadingDialog' />

	<template v-if="showDetailDialog">
		<ShowDetailDialog @cancel="showDetailDialog=false" :json="editing" />
	</template>
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import ShowDetailDialog from '@/modals/ShowDetailDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt, shortenName } from '@/common/util';

export default({
	components: { Pagination, ShowDetailDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			showDetailDialog: false,
			keywords: "",
			editing: ""
		});
		
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadPurchaseList(1);
		});
		
		// 删除
		const deletePurchase = async (id: string, name: string) => {
			if (confirm(`确认删除 ${name}(${id}) 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await request({url: `purchase/delete/${id}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功")
						loadPurchaseList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		}
		
		// 加载列表
		const loadPurchaseList = async (page: number) => {
		    if (page < 1) return;
		
		    nowPage.value = page;
			try {
				const data = (await request({url: `purchase/list/${page}/${50}?search=${refData.keywords}`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}
		
		const showDetial = async (item: any) => {
		    refData.showDetailDialog = true;
			refData.editing = JSON.stringify(item, null, "\t");
		}
		
		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
			
			dateFtt,
			loadPurchaseList,
			deletePurchase,
			shortenName,
			showDetial,
			
			...dataRef
		};
	},
});
</script>

<style>
</style>
