<template>
 	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
		    <div class="align-self-center mb-3" style="height: 30px;">
		        <button type="button" class="btn btn-primary" @click="openAddKoubeiDialog">添加</button>
		    </div>
		    <div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
		        <input type="text" class="form-control" v-model="keywords">
		        <button class="btn btn-outline-secondary" type="button" @click="searchKoubei">搜索</button>
		    </div>
		</div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th scope="col">序号</th><th scope="col">客户</th><th scope="col">套餐</th><th scope="col">星级</th><th scope="col" style="width: 400px">最满意</th><th scope="col">操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.customer}}</td>
						<td>{{value.meal || "-"}}</td>
						<td>{{value.star}}星</td>
						<td>{{value.whichBest}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditKoubeiDialog(value.id)"/>
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteKoubei(value.id, value.customer)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadKoubeiList" />
		</div>
	</div>
	
	<AddKoubeiDialog :show='showAddDialog' :item='editing' @cancel='showAddDialog = false' @confirm='editKoubei'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddKoubeiDialog from '@/modals/AddKoubeiDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import { DEFAULTS } from "@/global";
import { checkResponseDataAndAlert, dateFtt, buildHttpHeader } from '@/common/util';
import axios from 'axios';

export default({
	components: { Pagination, AddKoubeiDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			keywords: "搜索",
			editing: {}
		});
		
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadKoubeiList(1);
		});
		
		const openAddKoubeiDialog = () => {
			refData.editing = {star: 1};
			refData.showAddDialog = true;
		};
		
		// 编辑信息
		const openEditKoubeiDialog = async (id: string) => {
		    const errMsg = "查询信息失败";
			try {
				const data = (await axios.get(`${DEFAULTS.URL}koubei/${id}`, {headers: buildHttpHeader()})).data;
		        if (checkResponseDataAndAlert(data, errMsg) && data.data) {
					refData.editing = data.data;
					refData.showAddDialog = true;
		        }
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 编辑
		const editKoubei = async (postObj: any) =>  {
			console.log(postObj)
		    const errMsg = `操作失败`;		
			if (!postObj.customer || postObj.customer.length < 2
				|| !postObj.reason || postObj.reason.length < 2
				|| !postObj.whichBest || postObj.whichBest.length < 2
				|| !postObj.meal || postObj.meal.length < 2
				|| !postObj.advice || postObj.advice.length < 2) {
				alert("请完善带*的必填项");
				return;
			}
		
		    refData.showLoadingDialog = true;
			try {
				const data = (await axios.post(`${DEFAULTS.URL}koubei/save`, postObj, {headers: buildHttpHeader()})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadKoubeiList(nowPage.value);
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 删除
		const deleteKoubei = async (card_no: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await axios.get(`${DEFAULTS.URL}koubei/delete/${card_no}`, {headers: buildHttpHeader()})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功");
						loadKoubeiList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};
		
		// 搜索
		const searchKoubei = async () => {
		    if (refData.keywords.length === 0) {
		        loadKoubeiList(1);
		        return;
		    }
		
		    nowPage.value = 1;
			try {
				const postObj = {"page" : 1, "size": 50, "content": refData.keywords, timestamp: Date.now() };
				const data = (await axios.post(`${DEFAULTS.URL}koubei/search`, postObj, {headers: buildHttpHeader()})).data
		        if (checkResponseDataAndAlert(data) && data.data) {
					dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}
			
		// 加载列表
		const  loadKoubeiList = async (page: number) => {
		    if (page < 1) return;
		
		    nowPage.value = page;
			try {
				const data = (await axios.get(`${DEFAULTS.URL}koubei/list/${page}/${50}`, {headers: buildHttpHeader()})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};
		
		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
			
			dateFtt,
			editKoubei,
			loadKoubeiList,
			openAddKoubeiDialog,
			openEditKoubeiDialog,
			deleteKoubei,
			
			...dataRef
		};
	},
});
</script>

<style>
</style>