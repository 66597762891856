<template>
	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
			<div class="align-self-center mb-3" style="height: 30px;">
				<button type="button" class="btn btn-primary" @click="openAddChankangDialog">添加</button>
			</div>
 			<div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
				<input type="text" id="article-keywords" class="form-control" v-model="keywords" placeholder="标题">
				<button class="btn btn-outline-secondary" type="button" @click="searchChankang">搜索</button>
        &ensp;&ensp;<button class="btn btn-info" type="button" @click="exportAll">导出</button>
			</div>
		</div>
 		<div>
			<table class="table table-bordered table-striped table-sm member-list table-responsive">
				<thead>
					<tr>
						<th  style="width: 15px;">序号</th><th style="width: 100px;">ID</th><th style="width: 15px;">NO</th><th style="width: 160px;">标题</th><th>分类</th><th v-if="isBlue" >分组</th><th v-if="isBlue" >产品数量</th><th style="width: 80px;">价格</th><!--<th v-if="isBlue">手工</th>--><th>体验价</th><th>疗程价</th>
            <th style="width: 90px;" v-for ="(value, idx) in shopDataList" :key='idx'>&ensp;&ensp;手工费<br>{{value.name.substr(3)}}</th>
            <th>显示</th><th>更新时间</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.id}}</td>
						<td>{{value.no}}</td>
						<td>{{value.title}}</td>
            <td>{{value.chankangType || "-"}}</td>
						<td v-if="isBlue" >{{value.group || "-"}}</td>
            <td v-if="isBlue" >{{value.meiRongProductNumber}}{{value.meiRongProductUnit}}</td>
						<td>{{value.price}}{{value.unit}}</td>
<!--            <td v-if="isBlue">{{value.shougong}}元</td>-->
						<td>{{value.trialPrice}}{{value.unit}}</td>
						<td>{{value.packPrice}}{{value.packUnit}}</td>

            <td v-for ="(shop, idx) in shopDataList">{{getShougong(value, shop)}}</td>
            <td>
              <div class="mb-1 form-switch">
                <input class="form-check-input" :checked="value.show !== 0" @change="updateItem(value, 'show')" type="checkbox" role="switch"/>
              </div>
            </td>
						<td>{{dateFtt('yyyy-MM-dd hh:mm', new Date(value.latestOperateTime))}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditChankangDialog(value.id)" />
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteChankang(value.id, value.title)" />
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadChankangList" />
		</div>
	</div>

   <AddChankangMealDialog :show='showAddDialog' :item='editing' @confirm='editChankang' @cancel='showAddDialog=false'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddChankangMealDialog from '@/modals/AddChankangMealDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt,downloadTextAsFile } from '@/common/util';

export default({
	components: { Pagination, AddChankangMealDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
      keywords: "",
			editing: {shopShougongList:[],
        unit:'',
        price: 0,
        shougong: 0}
		});

		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
    const shopDataList =  ref<any[]>([]);
    const isBlue = ref<boolean>(process.env.VUE_APP_ENV === "blue")

		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadChankangList(1);
      await loadShops();
		});

    const getShougong = (value:any, shop:any) =>{
      for(let i = 0  ;  i < value.shopShougongList.length; i++){
        if(value.shopShougongList[i].shop == shop.id){
            return value.shopShougongList[i].shougong;
        }
      }
      return 0;
    };
    const loadShops = async () => {
      try {
        const data = (await request({url: `shop/list/1/100`, get: true})).data;
        if (checkResponseDataAndAlert(data) && data.data) {
          shopDataList.value = data.data;
        }
      } catch (e) {
        //console.log(e)
        checkResponseDataAndAlert({});
      }
    };

    const initShopShougong = async () =>{
      //console.log("enter ...")
      //console.log(shopDataList.value)
      for(let i = 0; i < shopDataList.value.length; i++){
        let find = false;
        if( refData.editing.shopShougongList && refData.editing.shopShougongList.length >0){
          for(let j =0; j < refData.editing.shopShougongList.length; j++){
            //console.log(shopDataList.value[i].id)
            //console.log(refData.editing.shopShougongList[j].shop)
            if(shopDataList.value[i].id === refData.editing.shopShougongList[j].shop){
              refData.editing.shopShougongList[j].name = shopDataList.value[i].name;
              find = true;
              break;
            }
          }
        }
        if(!find){
          var tmp = {
            "shop":shopDataList.value[i].id,
            "name":shopDataList.value[i].name,
            "shougong":0.0
          }
          refData.editing.shopShougongList.push(tmp);
        }

      }
    }

    const updateItem  = async (item: any, key: string) => {
      refData.showLoadingDialog = true;
      try {
        const postObj: any = {};
        let value=item.show === 1 ? 0 : 1;
        item.show=value;　
        const data = (await request({url: `chankang/meal/save`, data: item})).data;
        if (checkResponseDataAndAlert(data, "操作失败")) {
          item.show=value;
        } else {
          loadChankangList(1);
        }
        refData.showLoadingDialog = false;
      } catch (e) {
        refData.showLoadingDialog = false;
        checkResponseDataAndAlert({}, "操作失败");
      }
    };

		// 添加文章
		const openAddChankangDialog = () => {
		    refData.editing = {shopShougongList: [],unit:"元", price: 999, shougong: 0};
        initShopShougong();
		    refData.showAddDialog = true;
		}

    const exportAll = async () => {
      try {
        let csv = "序号,ID,NO,标题,分类,分组,价格,体验价,疗程价"
        for(let i=0; i < shopDataList.value.length; ++i){
          csv += ",手工费" + shopDataList.value[i].name.substr(3);
        }
        csv += ",更新时间\n";
        const data = (await request({url: `chankang/meal/list/1/2000?search=${refData.keywords}`, get: true})).data;
        if (checkResponseDataAndAlert(data) && data.data) {
          let i = 1
          for (const p of data.data) {
            csv += `${i},${p.id},${p.no},${p.title},`;
            if(p.chankangType === undefined){
              csv += "-";
            }else{
              csv += p.chankangType;
            }
            csv +=",";
            if(p.group === undefined){
              csv += "-";
            }else{
              csv += p.group;
            }

            csv += `,${p.price}${p.unit},${p.trialPrice},${p.packPrice}`
            if(p.packUnit!=undefined){
              csv += `/${p.packUnit}`
            }
            for(let i=0; i < shopDataList.value.length; ++i){
              csv += "," + getShougong(p, shopDataList.value[i]);
            }
            csv += "," + dateFtt('yyyy-MM-dd hh:mm', new Date(p.latestOperateTime));
            csv += "\n";

            i++
          }
          downloadTextAsFile(csv, "产康套餐.csv")
        }
      } catch (e) {
        checkResponseDataAndAlert({});
      }
    }

		// 跳转编辑页
		const openEditChankangDialog = async (id: string) => {
		    const errMsg = "查询信息失败";
		    try {
		    	const data = (await request({url: `chankang/meal/${id}`, get: true})).data;
				   //console.log(data)
		        if (checkResponseDataAndAlert(data, errMsg) && data.data) {
		    		refData.editing = data.data;
            initShopShougong()
		    		refData.showAddDialog = true;
		        }
		    } catch (e) {
		    	console.log(e);
		    	checkResponseDataAndAlert({}, errMsg);
		    }
		}

		// 编辑
		const  editChankang = async (postObj: any) => {
		    let gid = postObj.id;
		    const errMsg = `${(gid && gid.length > 0) ? '编辑' : '添加'}`;

			if (!postObj.title || postObj.title.length === 0
				|| !postObj.no || postObj.no.length === 0
				|| !postObj.unit || postObj.unit.length === 0
				|| !postObj.price || postObj.price.length === 0) {
				alert("带*号的内容不能为空");
				return
			}

			refData.showLoadingDialog = true;
			try {
				// const tail = (gid && gid.length > 0) ? `update/${gid}` : 'save';
				const data = (await request({url: `chankang/meal/save`, data: postObj})).data;
				//console.log(data)
				refData.showLoadingDialog = false;
			    if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
			        loadChankangList(nowPage.value);
			    }
			} catch (e) {
				//console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg + "失败");
			}
		}

		// 删除
		const deleteChankang = async (card_no: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await request({url: `chankang/meal/delete/${card_no}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功")
						loadChankangList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		}

		// 搜索
		const searchChankang = async () => {
			    loadChankangList(1);
		}

		// 加载列表
		const  loadChankangList = async (page: number) => {
		    if (page < 1) return;

		    nowPage.value = page;
			try {
				const data = (await request({url: `chankang/meal/list/${page}/${50}?showAll=all&search=${refData.keywords}`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}

		const dataRef = toRefs(refData);
		return {
			dataList,
      shopDataList,
			nowPage,
			totalPage,
      isBlue,
      getShougong,
			dateFtt,
			editChankang,
			loadChankangList,
			openAddChankangDialog,
			openEditChankangDialog,
			deleteChankang,
			searchChankang,
      updateItem,
      exportAll,
			...dataRef
		};
	},
});
</script>

<style>
</style>
