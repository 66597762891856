<template>
	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3" style="margin-top: 20px;">
		</div>
		
			<table class="table table-bordered table-striped table-sm member-list">
				<thead><tr><th style="width: 160px;">类型</th><th>排序</th></tr></thead>
				<tbody id="member-list">
					<tr>
						<td>预约入口</td>
						<td><ImageUploader :src='item.yuyue' @upload='imageUploadYuyue' /></td>
					</tr>
					<tr>
						<td>UVIP入口</td>
						<td><ImageUploader :src='item.uvip' @upload='imageUploadVip' /></td>
					</tr>
					<tr>
						<td>护理入口</td>
						<td><ImageUploader :src='item.huli' @upload='imageUploadHuli' /></td>
					</tr>
				</tbody>
			</table>
	</div>
	
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import ImageUploader from '@/components/ImageUploader.vue'
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request, {DEFAULTS} from "@/global";
import { checkResponseDataAndAlert, dateFtt } from '@/common/util';

export default({
	components: { LoadingDialog, ImageUploader},
	setup() {
		const refData = reactive({
			showEditorDialog: false,
			showLoadingDialog: false,
			showRuleDialog: false,
			showVersionDialog: false,
			showCheckRoomDialog: false,
			showAboutDialog: false,
			showVipsDialog: false,
			item: {} as any,
			isBlue: process.env.VUE_APP_ENV === "blue"
		});

		onMounted(async () => {
			loadGloabalData()
		});

		// 编辑信息
		const loadGloabalData = async () => {
			const errMsg = `没有找相关内容，请先添加或刷新重试`;
			refData.showLoadingDialog = true;
			try {
				const data = (await request({url: `${DEFAULTS.HOST}app/global.html`, get: true, guest: true})).data;
				refData.showLoadingDialog = false;
		        if (data) refData.item = data
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 编辑
		const submitRule = async (postObj: any) =>  {			
		    const errMsg = `操作失败`;

		    refData.showLoadingDialog = true;
			try {
				const data = (await request({url: `app/static/save/global`, data: JSON.stringify(postObj), plainText: true})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showRuleDialog = false;
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}

		const imageUploadVip = async (url: string) => {
			refData.item.uvip = url
			await submitRule(refData.item);
		}

		const imageUploadHuli = async (url: string) => {
			refData.item.huli = url
			await submitRule(refData.item);
		}

		const imageUploadYuyue = async (url: string) => {
			refData.item.yuyue = url
			await submitRule(refData.item);
		}
		
		const dataRef = toRefs(refData);
		return {
			dateFtt,

			submitRule,
			imageUploadVip,
			imageUploadHuli,
			imageUploadYuyue,
			
			...dataRef
		};
	},
});
</script>

<style>
</style>
