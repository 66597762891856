<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{item.id ? '编辑' : '添加'}}</div>
          <button @click="cancel" class="modal-header-close"><img src="../assets/ic_close.svg" /></button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td class="td-avg6"></td><td class="td-avg6"></td><td class="td-avg6"></td><td class="td-avg6"></td><td class="td-avg6"></td><td class="td-avg6"></td></tr>
				<tr>
					<td colspan="3">
						<div class="mb-1">
							<label for="employee-name">*姓名:</label>
							<input type="text" class="form-control" v-model="item.name">
						</div>
					</td>
					<td colspan="3">
						<div class="mb-1">
							<label for="employee-mobile">*手机:</label>
							<input type="tel" class="form-control" v-model="item.mobile">
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="3">
						<div class="mb-1">
							<label for="employee-shop">所属门店:</label>
							<select class="form-select" v-model="item.shop">
								<option v-for="(value, idx) in dataList" :key='idx' :value="value.id">{{value.name}}</option>
							</select>
						</div>
					</td>
					<td  colspan="3">
							<div class="mb-1" selector="angular-datepicker-input">
								<label for="employee-hireDate">入职日期:</label>
								<Datepicker teleport='#datePickerContainerId' v-model="item.hireDate" :enableTimePicker="false" format="yyyy-MM-dd"></Datepicker>
							</div>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<div class="mb-1">
							<label for="employee-idcard">身份证:</label>
							<input type="text" class="form-control" v-model="item.idcard">
						</div>
					</td>
					<td colspan="2">
						<div class="mb-1">
							<label for="employee-jobTitle">职位:</label>
							<select class="form-select" v-model="item.jobTitle">
								<option v-for="(value, idx) in jobList" :key='idx' :value="value.title">{{value.title}}</option>
							</select>
						</div>
					</td>
					<td colspan="2">
						<div class="mb-1">
							<label for="employee-jobTitle">部门:</label>
							<select class="form-select" v-model="item.dept">
								<option v-for="(value, idx) in deptList" :key='idx' :value="value.title">{{value.title}}</option>
							</select>
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="6">
						<div class="flex-col mb-1">
							<div>员工介绍:</div>
							<textarea :rows="3" class="form-control" v-model="item.content"></textarea>
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="6">
						<div class="mb-1">
							<label for="employee-wxQR">企业微信:</label>
							<ImageUploader :src='item.wxQR' @upload='wxqrUpload' />
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="6">
						<div class="mb-1">
							<label for="employee-wxQR">头像:</label>
							<ImageUploader :src='item.headimg' @upload='headUpload' />
						</div>
					</td>
				</tr>
				<tr style="height: 400px">
				</tr>
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
	<div id="datePickerContainerId"></div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import ImageUploader from '@/components/ImageUploader.vue'
import { DEFAULTS } from "@/global";
import axios from 'axios';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import request from "@/global";

export default defineComponent({
	components: { ImageUploader, Datepicker },
	props: {  
		show: {
			type: Boolean,
			default: false, 
		},
		item: {
			type: Object,
			default: {},
		},
	},

  setup(props: any, ctx: any)
  {
	const dataList = ref<any[]>([]);
	const jobList = ref<any[]>([]);
	const deptList = ref<any[]>([]);
	
	onMounted(async () => {
		loadShops();
		loadJobs();
		loadDepts();
	});
 
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	// 加载列表
	const loadShops = async () => {
		try {
			const data = (await axios.get(`${DEFAULTS.URL}shop/list/1/100`)).data;
			if (checkResponseDataAndAlert(data) && data.data) {
				dataList.value = data.data;
			}
		} catch (e) {
			checkResponseDataAndAlert({});
		}
	};
	
	// 加载列表
	const loadJobs = async () => {
		try {
			const data = (await axios.get(`${DEFAULTS.URL}preset/list/1/100?type=job`)).data;
			if (checkResponseDataAndAlert(data) && data.data) {
				jobList.value = data.data;
			}
		} catch (e) {
			checkResponseDataAndAlert({});
		}
	};

	// 加载部门
	const  loadDepts = async () => {
		try {
			const data = (await request({url: `preset/list/1/200?type=dept`, get: true})).data;
			console.log(data);
			if (checkResponseDataAndAlert(data) && data.data) {
				deptList.value = data.data;
			}
		} catch (e) {
			console.log(e);
			checkResponseDataAndAlert({});
		}
	};
	
	const confirm = () => {
		const postObj: any = {};
		const filterKeys = ['id', 'name', 'mobile', 'idcard', 'jobTitle', 'shop', 'hireDate', 'dept', 'wxQR', 'headimg', 'content'];
		for (const key of filterKeys) postObj[key] = props.item[key];
		
		if (postObj.hireDate && postObj.hireDate instanceof Date) 
			postObj.hireDate = dateFtt('yyyy-MM-dd', postObj.hireDate)
		ctx.emit('confirm', toPostData(postObj, []));
	}
	
	const wxqrUpload = (url: string) => {
		props.item.wxQR = url
	}

	const headUpload = (url: string) => {
		props.item.headimg = url
	}

    return {
      cancel,
	  confirm,
	  checkNumber,
	  wxqrUpload,
	  headUpload,
	  jobList,
	  dataList,
	  deptList,
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
</style>