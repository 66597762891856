<template>
 	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
		    <div class="align-self-center mb-3" style="height: 30px;">
		        <button type="button" class="btn btn-primary" @click="openAddMealDialog">添加</button>
		    </div>
		    <div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
		        <input type="text" class="form-control" v-model="keywords">
		        <button class="btn btn-outline-secondary" type="button" @click="searchMeal">搜索</button>
		    </div>
		</div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th scope="col">序号</th><th scope="col">标题</th><th scope="col">门店</th><th scope="col">排序</th><th>显示</th><th scope="col">创建时间</th><th scope="col">操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.title}}</td>
						<td>{{(value.shopInfo) ? value.shopInfo.name : "未知"}}</td>
						<td>{{value.order}}</td>
						<td>
							<div class="mb-1 form-switch">
								<input class="form-check-input" :checked="value.show === 1" @change="updateMeal(value, 'show')" type="checkbox" role="switch"/>
							</div>
						</td>
						<td>{{dateFtt('yyyy-MM-dd hh:mm', new Date(value.operateTime))}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditMealDialog(value.id)"/>
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteMeal(value.id, value.name)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadMealList" />
		</div>
	</div>

	<AddShopMealDialog :show='showAddDialog' :item='editing' @cancel='showAddDialog = false' @confirm='editMeal'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddShopMealDialog from '@/modals/AddShopMealDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import { DEFAULTS } from "@/global";
import { checkResponseDataAndAlert, dateFtt, buildHttpHeader } from '@/common/util';
import axios from 'axios';
import request from "@/global";

export default({
	components: { Pagination, AddShopMealDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			keywords: "搜索",
			editing: {}
		});

		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);

		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadMealList(1);
		});

		// ========================文章标签========================
		// 添加标签
		const openAddMealDialog = () => {
			refData.editing = {};
			refData.showAddDialog = true;
		};

		// 编辑信息
		const openEditMealDialog = async (id: string) => {
		    const errMsg = "查询信息失败";
			try {
				const data = (await axios.get(`${DEFAULTS.URL}shop/meal/${id}`, {headers: buildHttpHeader()})).data;
		        if (checkResponseDataAndAlert(data, errMsg) && data.data) {
					data.data.show = (data.data.show === 1)
					refData.editing = data.data;
					refData.showAddDialog = true;
		        }
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}

		// 编辑
		const editMeal = async (postObj: any) =>  {
			console.log(postObj)
		    const errMsg = `操作失败`;
			if (!postObj.title || postObj.title.length < 2
				|| !postObj.shop || postObj.shop.length < 2
				|| !postObj.detail || postObj.detail.length < 2
				|| !postObj.image || postObj.image.length < 2) {
				alert("请完善带*的必填项");
				return;
			}

		    refData.showLoadingDialog = true;
			try {
				let api = "shop/meal/save"
				if (postObj.id && postObj.id.length)
					api = `shop/meal/update/${postObj.id}`
				const data = (await axios.post(`${DEFAULTS.URL}${api}`, postObj, {headers: buildHttpHeader()})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadMealList(nowPage.value);
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}

		// 删除
		const deleteMeal = async (card_no: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await axios.get(`${DEFAULTS.URL}shop/meal/delete/${card_no}`, {headers: buildHttpHeader()})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功");
						loadMealList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};

		// 搜索
		const searchMeal = async () => {
		    if (refData.keywords.length === 0) {
		        loadMealList(1);
		        return;
		    }

		    nowPage.value = 1;
			try {
				const postObj = {"page" : 1, "size": 50, "content": refData.keywords, timestamp: Date.now() };
        const data = (await axios.get(`${DEFAULTS.URL}shop/meal/list/1/${50}?lookup=true&all=1&search=${refData.keywords}`, {headers: buildHttpHeader()})).data;
        if (checkResponseDataAndAlert(data) && data.data) {
          dataList.value = data.data;
          totalPage.value = data.total;
        }
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}

		// 加载列表
		const  loadMealList = async (page: number) => {
		    if (page < 1) return;

		    nowPage.value = page;
			try {
				const data = (await axios.get(`${DEFAULTS.URL}shop/meal/list/${page}/${50}?lookup=true&all=1`, {headers: buildHttpHeader()})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};

		const updateMeal  = async (item: any, key: string) => {
			refData.showLoadingDialog = true;
			try {
				const postObj: any = {};
				postObj[`${key}`] = item[`${key}`] === 1 ? 0 : 1;
				const data = (await request({url: `shop/meal/update/${item.id}`, data: postObj})).data;
				refData.showLoadingDialog = false;
			    if (checkResponseDataAndAlert(data, "操作失败")) {
					item[`${key}`] = postObj[`${key}`];
			    } else {
					loadMealList(nowPage.value);
				}
			} catch (e) {
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, "操作失败");
			}
		}

		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,

			dateFtt,
			editMeal,
			loadMealList,
			openAddMealDialog,
			openEditMealDialog,
			deleteMeal,
			updateMeal,
      searchMeal,
			...dataRef
		};
	},
});
</script>

<style>
</style>
