<template>
 	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
		    <div class="align-self-center mb-3" style="height: 30px;">
		        <button type="button" class="btn btn-primary" @click="openAddPackDialog">添加</button>
		    </div>
		</div>
    <div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 40%;">
        <select class="form-select w-40" v-model="shopId">
          <option  key='100' value="">全部</option>
          <option v-for="(value, idx) in shopDataList" :key='idx' :value="value.id">{{value.name}}</option>
        </select>
      <input style="left:10px;right: 10px" type="text" id="article-keywords" class="form-control w-30" v-model="keywords" placeholder="标题">
      <button class="btn btn-secondary" type="button" @click="searchChankangPack">搜索</button>
      &ensp;&ensp;<button class="btn btn-info" type="button" @click="exportAll">导出</button>
    </div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th style="width: 50px;"> 序号</th>
            <th style="width: 200px;">门店</th>
            <th style="width: 350px;">标题</th>
            <th style="width: 350px;">项目</th>
            <th style="width: 100px;">价格</th>
            <th style="width: 80px;">次数</th>
            <th style="width: 200px;">备注</th>
            <th>状态</th>
            <th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
            <td v-if="value.shopList && value.shopList.lenth > 0 "  ><div v-for="(v, iii) in value.shopList">{{getShopName(v)}}</div></td>
            <td v-else>{{getShopName(value.shop)}}</td>
						<td>{{value.title}}</td>
						<td><div class="flex-colum"><div v-for="(v, iii) in value.meals">{{v.title}}</div></div></td>
            <td>{{value.price}}</td>
            <td>{{value.count}}次</td>
            <td>{{value.remark}}</td>
						<td>
							<div class="mb-1 form-switch">
								<input class="form-check-input" :checked="value.show !== 0" @change="updateItem(value, 'show')" type="checkbox" role="switch"/>
							</div>
						</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditPackDialog(value.id)"/>
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deletePack(value.id, value.title)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadPackList" />
		</div>
	</div>

	<AddPackDialog ref="addDialogRef" :show='showAddDialog' :item='editing' @cancel='showAddDialog = false' @confirm='editPack'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddPackDialog from '@/modals/AddPackDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import {checkResponseDataAndAlert, dateFtt, downloadTextAsFile} from '@/common/util';

export default({
	components: { Pagination, AddPackDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
      keywords: "",
      shopId:"",
			editing: {shopList:[]}
		});

    const addDialogRef = ref<any>(null) // 通过 ref 绑定子组件
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
    const shopDataList =  ref<any[]>([]);

		//needed in case we switch in from another window
		onMounted(async () => {
      await loadShops();
		  await loadPackList(1);
		});

		// ========================文章标签========================
		// 添加标签
		const openAddPackDialog = () => {
			refData.editing = {shopList:[]};
			refData.showAddDialog = true;
		};

		// 编辑信息
		const openEditPackDialog = async (id: string) => {
		    const errMsg = "查询信息失败";
			try {
				const data = (await request({url: `shop/chankang/pack/get/${id}`, get: true})).data;
		        if (checkResponseDataAndAlert(data, errMsg) && data.data) {
          //addDialogRef.value!.setItemData(data.data)
					refData.editing = data.data;
					refData.showAddDialog = true;
		        }
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}

    const getShopName= (shopId:any) =>{
      console.log("getShopName")
      console.log(shopId)
      for(let i = 0;  i < shopDataList.value.length; i++){
        console.log(shopDataList.value[i].id)
        if(shopDataList.value[i].id == shopId){
          return shopDataList.value[i].name;
        }
      }
    };

    const loadShops = async () => {
      try {
        const data = (await request({url: `shop/list/1/100`, get: true})).data;
        if (checkResponseDataAndAlert(data) && data.data) {
          shopDataList.value = data.data;
        }
      } catch (e) {
        //console.log(e)
        checkResponseDataAndAlert({});
      }
    };

		// 编辑
		const editPack = async (postObj: any) =>  {
      console.log(postObj)
		    const errMsg = `操作失败`;
			if ((!postObj.shop && (!postObj.shopList || postObj.shopList.length <1))
				|| !postObj.title || postObj.title.length < 1
				|| !postObj.count || parseInt(postObj.count) < 1
				|| !postObj.meals || postObj.meals.length < 1) {
				alert("请完善带*的必填项");
				return;
			}

		  refData.showLoadingDialog = true;
			try {
				const data = (await request({url: `shop/chankang/pack/save`, data:postObj})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadPackList(nowPage.value);
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}

		// 删除
		const deletePack = async (card_no: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await request({url: `shop/chankang/pack/delete/${card_no}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功");
						loadPackList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};

		// 加载列表
		const  loadPackList = async (page: number) => {
		    if (page < 1) return;

		    nowPage.value = page;
			try {
				const data = (await request({url: `shop/chankang/pack/list/${page}/${50}?all=1&shop=${refData.shopId}&search=${refData.keywords}`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};
    const searchChankangPack = async () => {
      loadPackList(1);
    }

    /*<td>{{getShopName(value.shop)}}</td>
						<td>{{value.title}}</td>
						<td><div class="flex-colum"><div v-for="(v, iii) in value.meals">{{v.title}}</div></div></td>
            <td>{{value.count}}次</td>*/
    const exportAll = async () => {
      try {
        let csv = "序号,门店,标题,项目,次数\n";
        const data = (await request({url: `shop/chankang/pack/list/1/2000?all=1&shop=${refData.shopId}&search=${refData.keywords}`, get: true})).data;
        if (checkResponseDataAndAlert(data) && data.data) {
          let i = 1
          for (const p of data.data) {
            csv += `${i}`;
            csv += ","+ getShopName(p.shop);
            csv += ","+ p.title;
            csv += ",";
            csv += "\"";
            for(let j = 0 ; j < p.meals.length; j++){
              csv += p.meals[j].title + "\r\n";
            }
            csv += "\"";
            csv +="," + p.count  + "\n";
            i++
          }
          downloadTextAsFile(csv, "产康包.csv")
        }
      } catch (e) {
        checkResponseDataAndAlert({});
      }
    }

		const updateItem  = async (item: any, key: string) => {
			refData.showLoadingDialog = true;
			try {
				const postObj: any = {};
				postObj[`${key}`] = item[`${key}`] === 0 ? 1 : 0;
				const data = (await request({url: `shop/chankang/pack/update/${item.id}`, data: postObj})).data;
				refData.showLoadingDialog = false;
			    if (checkResponseDataAndAlert(data, "操作失败")) {
					item[`${key}`] = postObj[`${key}`];
			    } else {
					loadPackList(nowPage.value);
				}
			} catch (e) {
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, "操作失败");
			}
		}

		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
      shopDataList,
			dateFtt,
			editPack,
			updateItem,
			loadPackList,
			openAddPackDialog,
			openEditPackDialog,
			deletePack,
      exportAll,
      searchChankangPack,
      loadShops,
      getShopName,
			...dataRef
		};
	},
});
</script>

<style>
</style>
