<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{item.id ? '编辑' : '添加'}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="activity-title">客户ID:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							{{item.customer}}
						</div>
					</td>
				</tr>
        <tr>
          <td>
            <div class="mb-1 td-label-right">
              <label for="activity-title">预产期:</label>
            </div>
          </td>
          <td>
            <div class="mb-1">
              {{item.detailObj.yuchanqi}}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-1 td-label-right">
              <label for="activity-title">预住日期:</label>
            </div>
          </td>
          <td>
            <div class="mb-1">
              {{item.detailObj.yuzhuriqi}}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-1 td-label-right">
              <label for="activity-title">入住门店:</label>
            </div>
          </td>
          <td>
            <div class="mb-1">
              {{item.detailObj.shop}}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-1 td-label-right">
              <label for="activity-title">客户类型:</label>
            </div>
          </td>
          <td>
            <div class="mb-1">
              {{item.detailObj.customerType}}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-1 td-label-right">
              <label for="activity-title">套餐:</label>
            </div>
          </td>
          <td>
<!--            <div class="mb-1">
              {{item.detailObj.meal}}
            </div>-->
            <select class="form-select" v-model="item.detailObj.meal">
              <option v-for="(value, idx) in item.mealList" :key='idx' :value="value.title">{{value.title}}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-1 td-label-right">
              <label for="activity-title">优惠金额:</label>
            </div>
          </td>
          <td>
            <div class="mb-1">
<!--              {{item.detailObj.lijian}}-->
              <input type="number" style="width: 120px;" class="form-control" v-model="item.detailObj.lijian">
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-1 td-label-right">
              <label for="activity-title">赠送天数:</label>
            </div>
          </td>
          <td>
            <div class="mb-1">
<!--              {{item.detailObj.zengsongtianshu ? item.detailObj.zengsongtianshu:0}}-->
              <input type="number" style="width: 120px;" class="form-control ml-2" v-model="item.detailObj.zengsongtianshu">
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-1 td-label-right">
              <label for="activity-title">赠送产康金:</label>
            </div>
          </td>
          <td>
            <div class="mb-1">
              {{item.detailObj.ckzsj ? item.detailObj.ckzsj:0}}
            </div>
          </td>
        </tr>
<!--				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="activity-detail">*详情:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<textarea rows="2" class="form-control" style="height: 400px;" v-model="item.detail"></textarea>
						</div>
					</td>
				</tr>-->
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
	<div id="datePickerContainerId"></div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref } from "vue";
import {toPostData, checkResponseDataAndAlert, dateFtt, buildHttpHeader} from '@/common/util';
import axios from "axios";
import {DEFAULTS} from "@/global";

export default defineComponent({
	components: { },
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		item: {
			type: Object,
			default: {},
		},
    mealList:[],
	},

  setup(props: any, ctx: any)
  {
    //


    const dataList = ref<any[]>([]);
    onMounted(async () => {
    });

    const cancel = () => {
      ctx.emit('cancel');
    }

    const handleChange = () => {
      for(let i = 0; i < props.item.mealList.length; i++){
         let itemTmp = props.item.mealList[i];
         if(itemTmp.title == props.item.detailObj.meal){
           props.item.detailObj.mealObj = itemTmp;
         }
      }
      console.log(props.item.detailObj);
    }

	  const confirm = () => {
      try {
        const detail = JSON.parse(props.item.detail);
        if (!detail || !detail.shop) {
          alert("预订单详情格式错误");
          return
        }
      } catch {
        alert("预订单详情格式错误");
        return
      }

		// console.log(JSON.stringify({detail: props.item.detail}))
      handleChange();
      let tmp = JSON.stringify(props.item.detailObj);
      //ctx.emit('confirm', props.item.id, {detail: props.item.detail});
       ctx.emit('confirm', props.item.id, {detail: tmp});
	  }
    return {
      cancel,
	    confirm,
	    dataList
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
</style>
