<template>
  <div class="modal-backdrop"  v-if="show">
     <div class="modal2" style="width: 900px;">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title" v-if="itemData.data.id != undefined">修改</div>
          <div class="text-center flex-1 modal-header-title" v-else >增加</div>
          <button @click="cancel" class="modal-header-close">
			    <img src="../assets/ic_close.svg" />
          </button>
     </div>
     <div class="modal-body flex-row flex-wrap">
			 <table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
          <td><div class="mb-1 td-label-right"><label>*题目类型:</label></div></td>
          <td>
          <input class="mb-1" type="radio" name="examType" id="examType1" value="system" v-model="itemData.data.examType" >
          <label class="form-check-label" for="examType1">
            系统
          </label>
          <input class="mb-1" type="radio" name="examType" id="examType2" value="meal" v-model="itemData.data.examType">
          <label class="form-check-label" for="examType2">
            项目
          </label>
          </td>
				</tr>
         <tr>
           <td><div class="mb-1 td-label-right"><label>*考题类型:</label></div></td>
           <td>
             <input class="mb-1" type="radio" name="questionType" id="questionType1" value="trueFalse" v-model="itemData.data.questionType" >
             <label class="mb-1 form-check-label" for="questionType1">
               判断题
             </label>
             <input class="mb-1" type="radio" name="questionType" id="questionType2" value="radio" v-model="itemData.data.questionType">
             <label class="mb-1 form-check-label" for="questionType2">
               单选题
             </label>
             <input class="mb-1" type="radio" name="questionType" id="questionType3" value="checkbox" v-model="itemData.data.questionType">
             <label class="mb-1 form-check-label" for="questionType3">
               多选题
             </label>
           </td>
         </tr>
         <tr>
           <td><div class="mb-1 td-label-right"><label>*标题:</label></div></td>
           <td><div class="mb-1"><input type="text" class="form-control" v-model="itemData.data.title"></div></td>
         </tr>
         <tr v-if="itemData.data.questionType != 'trueFalse'">
           <td class="align-top"><div class="mb-1 td-label-right"><label>答题选项:</label></div></td>
           <td>
             <div >
               <div class="mb-1" v-for ="(value, idx) in itemData.data.optionList" >
                 <span style="font-family:黑体">{{idx+1}}、&ensp;</span>
                 <input class="" style="width: 75%" type="text" v-model="itemData.data.optionList[idx].option"/>
                 <button type="button" class=" mb-1 btn btn-link h-75"  @click="deleteOption(value.number)">删除</button>
                 <button type="button" class="btn btn-outline-success h-75"  @click="addOption" v-if="idx  == (itemData.data.optionList.length - 1)">增加</button>
               </div>
               <button type="button" class="btn btn-outline-success h-75"  @click="addOption"
                     v-if="itemData.data.optionList == undefined || itemData.data.optionList.length == 0">增加</button>
             </div>
           </td>
         </tr>
         <tr>
           <td class="align-top"><div class="mb-1 td-label-right"><label>答案选项:</label></div></td>
           <td>
             <span class="mb-1" v-for ="(value, idx) in itemData.data.optionList" v-if = "itemData.data.questionType != 'trueFalse'" >
               <span v-if="itemData.data.questionType =='radio'">
                <input class="mb-1" type="radio" name="answer" :id="'answer' + value.number" :value="value.number" v-model="answer">
                 <label class="mb-1 form-check-label" :for="'answer' + value.number">
                   <span style="font-family:黑体">&ensp;{{idx+1}}&ensp;</span>
                 </label>
               </span>
               <span v-else-if="itemData.data.questionType =='checkbox'">
                 <input class="mb-1" type="checkbox" :name="'answer' + value.number"  :id="'answer' + value.number" :value="value.number" v-model="itemData.data.answerList" >
                 <label class="mb-1 form-check-label" :for="'answer' + value.number">
                     <span style="font-family:黑体">&ensp;{{idx+1}}&ensp;</span>
                 </label>
               </span>
             </span>
             <span class="mb-1" v-for ="(value, idx) in trueFalseOptionList" v-else>
                <input class="mb-1" type="radio" name="answer" :id="'answer' + value.number" :value="value.number" v-model="answer">
                 <label class="mb-1 form-check-label" :for="'answer' + value.number">
                   <span style="font-family:黑体">&ensp;{{value.option}}&ensp;</span>
                 </label>
             </span>
           </td>
         </tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>状态:</label></div></td>
					<td>
						<div class="mb-1 form-switch">
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="itemData.data.show"/>
						</div>
					</td>
				</tr>
         <tr>
           <td><div class="mb-1 td-label-right">*排序:</div></td>
           <td><div class="mb-1"><input type="number" class="form-control" v-model="itemData.data.sort"></div></td>
         </tr>
			</table>
        </div>
		<div class="flex-row-center modal-footer2">
			<button type="button" class="btn btn-primary" style="width:120px" @click="confirm">确定</button>
      <div v-if="itemData.data.id != undefined">
<!--        &nbsp;&nbsp;<button type="button" class="btn btn-secondary" style="width:120px" @click="confirmNext">保存&下一条</button>-->
      </div>
      <div v-else>
        &nbsp;&nbsp;<button type="button" class="btn btn-secondary" style="left:120px" @click="confirmAdd">保存&继续增加</button>
      </div>
      &nbsp;&nbsp; <button type="button" class="btn  btn-secondary " style="width:120px" @click="cancel">关闭</button>
		</div>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, ref, onMounted, watch } from "vue";
import RichEditor from '@/components/RichEditor.vue'
import ImageUploader from '@/components/ImageUploader.vue'
import FileUploader from '@/components/FileUploader.vue'
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';


export default defineComponent({
  components: { RichEditor, ImageUploader, FileUploader },
  emits: ["confirm", 'cancel', "confirmAdd", "confirmNext"],
  props: {
    show: {
        type: Boolean,
        default: false,
    },
  },

  setup(props: any, ctx: any)
  {
	  const refData = reactive({
      answer:"",
      trueFalseOptionList:[{number:1, option:'对'},{number:2,option:'错'}]
	});

  const itemData = reactive({data: {id:null,questionType:"trueFalse", examType:null, title: null, optionList:[], answerList: [], sort: 0, show:true}});

	onMounted(async () => {
	});

  const setItemData = (value: any) => {
    itemData.data = value;
    if(itemData.data.questionType != "checkbox"){
      if(itemData.data.answerList != undefined && itemData.data.answerList.length > 0){
        refData.answer = itemData.data.answerList[0];
      }
    }
  }
 const cancel = () => {
    ctx.emit('cancel');
  }

  const confirmCommon = () =>{
    if(itemData.data.questionType == 'trueFalse'){
      itemData.data.optionList = refData.trueFalseOptionList;
    }
    if(itemData.data.questionType != "checkbox"){
      itemData.data.answerList = [];
      itemData.data.answerList.push(refData.answer);
    }
  }

	const confirm = () => {
    confirmCommon();
    console.log(itemData.data)
		ctx.emit('confirm', toPostData(itemData.data, []));
	}

    const confirmNext = () => {
      confirmCommon();
      console.log(itemData.data)
      ctx.emit('confirmNext', toPostData(itemData.data, []));
    }

    const confirmAdd = () =>{
      confirmCommon();
      console.log(itemData.data)
      ctx.emit('confirmAdd', toPostData(itemData.data, []));
    }


  const deleteOption = (number:any) =>{
    for(let i = 0; i < itemData.data.optionList.length; i++){
        if(number == itemData.data.optionList[i].number){
          itemData.data.optionList.splice(i, 1);
          break;
        }
    }
  }
  const addOption = () =>{
    if(itemData.data.optionList === undefined ){
      itemData.data.optionList = [];
    }
    let option = {
        number:1,
        option:""
    }

    for(let i = 0; i < itemData.data.optionList.length;  i++){
        if(option.number <= itemData.data.optionList[i].number){
          option.number = itemData.data.optionList[i].number++;
        }
    }

    itemData.data.optionList.push(option);
  }
	const dataRef = toRefs(refData);
    return {
    cancel,
	  confirm,
    confirmNext,
    confirmAdd,
    confirmCommon,
    setItemData,
    addOption,
    deleteOption,
      itemData,
      ...dataRef,
    }
  },
});
</script>

<style scoped>
.form-check-input {
	margin-left: 0;
}
.lesson-image {
	width: 100px;
	height: 100px;
	cursor: pointer;
	background-color: #00193A;
}
</style>
