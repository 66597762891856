<template>
  <div class="modal-backdrop"  v-if="show">
     <div class="modal2" style="width: 900px;">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title" v-if="itemData.data.id != undefined">修改</div>
          <div class="text-center flex-1 modal-header-title" v-else >增加</div>
          <button @click="cancel" class="modal-header-close">
			<img src="../assets/ic_close.svg" />
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>*标题:</label></div></td>
					<td><div class="mb-1"><input type="text" class="form-control" v-model="itemData.data.title"></div></td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label style="line-height: 36px;">*答案:</label></div></td>
          <td>
            <div class="mb-1">
              <RichEditor v-model="itemData.data.content" :min-height="200"/>
            </div>
          </td>
<!--          <editor v-model="form.content" :min-height="200"/>-->
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>状态:</label></div></td>
					<td>
						<div class="mb-1 form-switch">
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="itemData.data.show"/>
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right">*排序:</div></td>
					<td><div class="mb-1"><input type="number" class="form-control" v-model="itemData.data.sort"></div></td>
				</tr>
			</table>
        </div>
		<div class="flex-row-center modal-footer2">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, ref, onMounted, watch ,toRaw} from "vue";
import RichEditor from '@/components/RichEditor.vue'
import ImageUploader from '@/components/ImageUploader.vue'
import FileUploader from '@/components/FileUploader.vue'
import QuillEditor from '@/components/edit/index.vue';
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import { DEFAULTS } from "@/global";
import axios from 'axios';
//import { QuillEditor } from '@vueup/vue-quill'
//#import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default defineComponent({
  components: { RichEditor, ImageUploader, FileUploader ,QuillEditor},
  emits: ["confirm", 'cancel'],
  props: {
    show: {
        type: Boolean,
        default: false,
    },
  },

  setup(props: any, ctx: any)
  {
	    const refData = reactive({
	});

	const dataList = ref<any[]>([]);
	const itemData = reactive({data: {id:null,title: null, content: null, sort: 0, show:true}});

	onMounted(async () => {
	});

  const setItemData = (value: any) => {
      itemData.data = value;
      console.log(value);
  }

  const cancel = () => {
    ctx.emit('cancel');
  }

	const confirm = () => {
		console.log(JSON.stringify(toPostData(itemData.data, ['show'])))
		ctx.emit('confirm', toPostData(itemData.data, ['show']));
	}

	const dataRef = toRefs(refData);
    return {
    cancel,
	  confirm,
    setItemData,
	  dataList,
	  ...dataRef,
	  itemData,
    refData,
    }
  },
});
</script>

<style scoped>
.form-check-input {
	margin-left: 0;
}
.lesson-image {
	width: 100px;
	height: 100px;
	cursor: pointer;
	background-color: #00193A;
}
</style>
