<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{item.id ? '编辑' : '添加'}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">*门店:</div>
					</td>
					<td>
						<div class="mb-1" v-if="!isBlue">
							<select class="form-select" v-model="item.shop">
								<option v-for="(value, idx) in dataList" :key='idx' :value="value.id">{{value.name}}</option>
							</select>
						</div>
            <div v-else>
              <span class="mb-1" v-for ="(value, idx) in dataList"  >
               <input class="mb-1" type="checkbox" :id="value.id" :value="value.id"  v-model="item.shopList" >
               <label class="mb-1 form-check-label" :for="value.id">
                   <span style="font-family:黑体">{{value.name}}</span>
               </label>
          </span>
            </div>
					</td>

				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">*标题:</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.title">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">*排序:</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.order">
						</div>
					</td>
				</tr>
        <tr>
          <td>
            <div class="mb-1 td-label-right">*价格:</div>
          </td>
          <td>
            <div class="mb-1">
              <input type="text" class="form-control" v-model="item.price">
            </div>
          </td>
        </tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">*次数:</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.count">
						</div>
					</td>
				</tr>
        <tr>
          <td>
            <div class="mb-1 td-label-right">备注:</div>
          </td>
          <td>
            <div class="mb-1">
              <input type="text" class="form-control" v-model="item.remark">
            </div>
          </td>
        </tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">*产康项目:</div>
					</td>
					<td>
						<div class="flex-colum mb-1">
							<div v-for="(v, idx) in item.meals" v-if="item.meals" class="ck-item">
								<div class="flex-row flex-align-center">
									{{v.title}} - {{v.duration}} - {{v.count}}次
									<img src="/assets/ic_trash.png" class="remove-item ml-3" @click="deleteItem(v)" />
								</div>
							</div>
							<button type="button" class="btn btn-primary" style="width: 160px;" @click="addItem">添加产康项目</button>
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
	<div id="datePickerContainerId"></div>
  </div>

  <AddPackItemDialog :show='showAddItemDialog' :shop="item.shop" @confirm="confirmAddItems" @cancel="showAddItemDialog = false" />
</template>

<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import AddPackItemDialog from '@/modals/AddPackItemDialog.vue'
import { DEFAULTS } from "@/global";
import axios from 'axios';

export default defineComponent({
	components: { AddPackItemDialog },
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		item: {
			type: Object,
			default: {},
		},
	},

  setup(props: any, ctx: any)
  {
    const refData = reactive({
      showAddItemDialog: false,

    });

	  const dataList = ref<any[]>([]);
    const isBlue = process.env.VUE_APP_ENV === "blue"
    const itemData = reactive({data: {shopList:[]}});

    onMounted(async () => {
      loadShops();
    });

    const setItemData = (value: any) => {
      itemData.data.shopList = value.shopList;
      console.log(value);
    }
	// 加载列表
	const loadShops = async () => {
		try {
			const data = (await axios.get(`${DEFAULTS.URL}shop/list/1/100`)).data;
			if (checkResponseDataAndAlert(data) && data.data) {
				dataList.value = data.data;
			}
		} catch (e) {
			checkResponseDataAndAlert({});
		}
	};

    const cancel = () => {
      ctx.emit('cancel');
    }

	const confirm = () => {
		// console.log(JSON.stringify(toPostData(props.item, [])))
		ctx.emit('confirm', toPostData(props.item, []));
	}

	const addItem = () => {
		refData.showAddItemDialog = true;
	}

	// 添加产康项目
	const deleteItem = (v: any) => {
		props.item.meals = props.item.meals.filter((t:any)=>{ return t.id !== v.id})
	}

	const confirmAddItems = (arr: any[]) => {
		if (!props.item.meals) props.item.meals = [];
		for (const tt of arr) {
			const ttt = {no: tt.no, id: tt.id, title: tt.title, duration: tt.duration, price: tt.price, count: 1}
			props.item.meals.push(ttt);
		}
		refData.showAddItemDialog = false;
	}

	const dataRef = toRefs(refData);
    return {
      cancel,
	  confirm,
	  addItem,
	  confirmAddItems,
	  deleteItem,
    setItemData,

	  dataList,
    itemData,
    isBlue,
	  ...dataRef
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
	.ck-item {
		height: 34px;
		font-size: 16px;
	}
	.remove-item {
		cursor: pointer;
		width: 20px;
		height: 20px;
	}
</style>
