<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{item.id ? '编辑' : '添加'}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="activity-title">*标题:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.title">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="activity-shop">*门店:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<select class="form-select" v-model="item.shop">
								<option v-for="(value, idx) in dataList" :key='idx' :value="value.id">{{value.name}}</option>
							</select>
						</div>
					</td>
				</tr>

				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="activity-startTime">*活动时间:</label>
						</div>
					</td>
					<td>
						<div class="d-flex">
						<div class="mb-1" style="width: 300px;">
							<div class="mb-1" selector="angular-datepicker-input">
								<label for="employee-hireDate">开始日期:</label>
								<Datepicker teleport='#datePickerContainerId' v-model="item.startTime" :enableTimePicker="false" format="yyyy-MM-dd"></Datepicker>
							</div>
						</div>
						<span style="padding-left: 10px; padding-right: 10px; line-height: 80px;">至</span>
						<div class="mb-1" style="width: 300px;">
							<div class="mb-1" selector="angular-datepicker-input">
								<label for="employee-hireDate">结束日期:</label>
								<Datepicker teleport='#datePickerContainerId' v-model="item.endTime" :enableTimePicker="false" format="yyyy-MM-dd"></Datepicker>
							</div>
						</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="activity-detail">*详情:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<textarea rows="2" class="form-control" v-model="item.detail"></textarea>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="activity-image">*宣传图:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<ImageUploader :src='item.image' @upload='imageUpload' />
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
	<div id="datePickerContainerId"></div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import ImageUploader from '@/components/ImageUploader.vue'
import { DEFAULTS } from "@/global";
import axios from 'axios';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
	components: { ImageUploader, Datepicker },
	props: {  
		show: {
			type: Boolean,
			default: false, 
		},
		item: {
			type: Object,
			default: {},
		},
	},

  setup(props: any, ctx: any)
  {
	const dataList = ref<any[]>([]);
	
	onMounted(async () => {
		loadShops();
	});
	
	// 加载列表
	const loadShops = async () => {
		try {
			const data = (await axios.get(`${DEFAULTS.URL}shop/list/1/100`)).data;
			if (checkResponseDataAndAlert(data) && data.data) {
				dataList.value = data.data;
			}
		} catch (e) {
			checkResponseDataAndAlert({});
		}
	};
 
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		if (props.item.startTime && props.item.startTime instanceof Date)
			props.item.startTime = dateFtt('yyyy-MM-dd', props.item.startTime)
		if (props.item.endTime && props.item.endTime instanceof Date)
			props.item.endTime = dateFtt('yyyy-MM-dd', props.item.endTime)
		// console.log(JSON.stringify(toPostData(props.item, [])))
		ctx.emit('confirm', toPostData(props.item, []));
	}
	
	const imageUpload = (url: string) => {
		props.item.image = url
	}

    return {
      cancel,
	  confirm,
	  checkNumber,
	  imageUpload,
	  dataList
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
</style>