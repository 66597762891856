<template>
	<div class="sidebar2 sidebar2-auto" :class="sidebarDynamic">
		<div class="flex-colum">
			<router-link to="/"
			 :class="{'nav-link2': true, 'active2': path === '/'}"
			 style="margin-top: 30px;">首页</router-link>
		</div>

		<div v-for="(value, key) in menus" :key="key" class="flex-colum">
			<div class="sidebar-heading">{{key}}</div>
			<router-link
			 v-for="(item, index) in value"
			 :to="item.tag"
			 :key="index"
			 :class="{'nav-link2': true, 'active2': path === item.tag}">
				{{item.title}}
			</router-link>
		</div>
	</div>
	<div class="nav-more nav-more-auto">
		<div @click="switchSidebar"><img src="/assets/ic_more.svg"/></div>
	</div>
</template>

<script lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { allNavMenus } from '@/router';
import { loadTokens } from '@/common/util';

export default({
	components: { },
	setup() {
		const route = useRoute();
		const path = computed(() => route.path);
		const menus = ref<any>();
		const sidebarDynamic = ref<string>("display-auto")

		//needed in case we switch in from another window
		onMounted(async () => {
		  const token = loadTokens() as any;
		  if (token) {
			  menus.value = {};
			  const data = JSON.parse(token);
			  if (data.job) {
				// 筛选出职位对应的页面
				for (const entry of Object.entries(allNavMenus)) {
					const routes = [];
					for (const route of entry[1] as any[]) {
						console.log(route);
            console.log("test naveBay")
            //if (route.who && route.who.includes(data.job) && !route.hide)
            if (route.who && route.who.includes(data.job) && !route.hide)
							routes.push(route);
					}
					if (routes.length) menus.value[entry[0]] = routes;
				}
			  }
		  } else {
			  menus.value = {};
		  }
		});

		watch(()=>route.path, () => {
			sidebarDynamic.value = "display-auto";
		});

		const switchSidebar = () => {
			if (sidebarDynamic.value === "display-auto")
				sidebarDynamic.value = "display-flex";
			else
				sidebarDynamic.value = "display-auto";
		}

		return {
			menus,
			path,
			sidebarDynamic,
			switchSidebar,
		};
	},
});
</script>

<style>
.sidebar2 {
	width: 220px;
	height: 100vh;
	overflow-y: scroll;
	background-color: #00193A;
	color: #FFFFFF;
	z-index: 2;
}

.sidebar2-auto {
	position: relative;
}

.nav-more {
	position: fixed;
	top: 0;
	left: -23px;
	height: 90vh;
}

.nav-more>div {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	background-color: #3b4252;
	width: 46px;
	height: 46px;
	cursor: pointer;
}

.nav-more>div>img {
	width:20px;
	height: 20px;
	margin-left: 12px;
}

.nav-more-auto {
	display: none;
}

.display-flex {
}

.display-auto {
}

@media (max-width: 600px) {
	.sidebar2-auto {
		position: absolute;
		left: 0;
		top: 0;
	}

	.display-auto {
		display: none;
	}

	.nav-more-auto {
		display: flex;
		align-items: center;
	}
}

.sidebar2::-webkit-scrollbar
{
    width:5px;
    height:16px;
    background-color:#CCCCCC;
}
.sidebar2::-webkit-scrollbar-thumb
{
    background-color:#0077b6;
}

.nav-link2 {
	cursor: pointer;
	padding: 0 15px;
	width: 100%;
	height: 40px;
	line-height: 40px;
	font-size: 15px;
}

.nav-link2:hover {
	color: #FFFFFF;
	background-color: rgba(255,255,255,.3)!important;
}

.active2 {
	color: #FFFFFF;
	background-color: #0F52BA!important;
}

</style>
