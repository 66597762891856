<template>
  <div class="modal-backdrop"  v-if="show">
     <div class="modal2" style="width: 900px;">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title" v-if="itemData.data.id != undefined">修改</div>
          <div class="text-center flex-1 modal-header-title" v-else >增加</div>
          <button @click="cancel" class="modal-header-close">
			<img src="../assets/ic_close.svg" />
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>*标题:</label></div></td>
					<td><div class="mb-1"><input type="text" class="form-control" v-model="itemData.data.title"></div></td>
				</tr>
        <tr>
          <td>
            <div class="mb-1 td-label-right">
              <label for="chankang-label">*分类:</label>
            </div>
          </td>
          <td>
            <div class="mb-1">
              <select class="form-select" v-model="itemData.data.classify">
                <option v-for="(value, idx) in classifyDataList" :key='idx' :value="value.title">{{value.title}}</option>
              </select>
            </div>
          </td>
        </tr>
				<tr>
          <td><div class="mb-1 td-label-right"><label style="line-height: 36px;">*视频:</label></div></td>
          <!--					<td><div class="mb-1"><input type="text" class="form-control" v-model="itemData.data.video"></div></td>-->
          <td>
            <div class="d-flex flex-row mb-1">
              <FileUploader @upload='fileUpload' />
              <div v-if = "itemData.data.video">
                <video ref="videoPlayer" :style="`width: 155px; height: 188px; `" controls>
                  <source :src="itemData.data.video"  autoPlay preload="auto" type="video/mp4">
                </video>
              </div>
            </div>
            <input type="text" class="form-control" disabled v-model="itemData.data.video">
          </td>
<!--          <editor v-model="form.content" :min-height="200"/>-->
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>状态:</label></div></td>
					<td>
						<div class="mb-1 form-switch">
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="itemData.data.show"/>
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right">*排序:</div></td>
					<td><div class="mb-1"><input type="number" class="form-control" v-model="itemData.data.sort"></div></td>
				</tr>
			</table>
        </div>
		<div class="flex-row-center modal-footer2">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, ref, onMounted, watch } from "vue";
import RichEditor from '@/components/RichEditor.vue'
import ImageUploader from '@/components/ImageUploader.vue'
import FileUploader from '@/components/FileUploader.vue'
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import { DEFAULTS } from "@/global";
import axios from 'axios';

export default defineComponent({
  components: { RichEditor, ImageUploader, FileUploader },
  emits: ["confirm", 'cancel'],
  props: {
    show: {
        type: Boolean,
        default: false,
    },
  },

  setup(props: any, ctx: any)
  {
	const refData = reactive({
	});


	const dataList = ref<any[]>([]);
  const classifyDataList = [
      {"id":1,"title":"背部"},
      {"id":2,"title":"骨缝"},
      {"id":3,"title":"肩颈"},
      {"id":4,"title":"暖宫"},
      {"id":5,"title":"祛湿"},
      {"id":6,"title":"手部"},]
	const itemData = reactive({data: {id:null,title: null, content: null, sort: 0, show:true,  video:null}});

	onMounted(async () => {
	});

    const fileUpload = (url: string) => {
      //(itemData.data as any).video = url
      itemData.data.video = url;
      //videoRef.value.videoPlayer.load()
    }

    const setItemData = (value: any) => {
      itemData.data = value;
      console.log(value);
  }


    const cancel = () => {
    ctx.emit('cancel');
  }

	const confirm = () => {
		console.log(JSON.stringify(toPostData(itemData.data, ['show'])))
		ctx.emit('confirm', toPostData(itemData.data, ['show']));
	}

	const dataRef = toRefs(refData);
    return {
    cancel,
	  confirm,
    setItemData,
    fileUpload,
	  dataList,
    classifyDataList,
	  ...dataRef,
	  itemData
    }
  },
});
</script>

<style scoped>
.form-check-input {
	margin-left: 0;
}
.lesson-image {
	width: 100px;
	height: 100px;
	cursor: pointer;
	background-color: #00193A;
}
</style>
