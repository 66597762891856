<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{title}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="music-title">*标题:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.title">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="music-title">*版本名:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.version">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="music-title">*版本号:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.code">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="music-music">*安装包:</label>
						</div>
					</td>
					<td>
						<div class="d-flex flex-row mb-1">
							<input type="text" class="form-control" v-model="item.url">
							<FileUploader @upload='fileUpload' />
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import FileUploader from '@/components/FileUploader.vue'
import { DEFAULTS } from "@/global";
import axios from 'axios';
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
	components: { FileUploader,},
	props: {  
		show: {
			type: Boolean,
			default: false, 
		},
		title: {
			type: String,
			default: {},
		},
		item: {
			type: Object,
			default: {},
		},
	},

  setup(props: any, ctx: any)
  {
	onMounted(async () => {
	});
 
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		ctx.emit('confirm', toPostData(props.item, []));
	}

	const fileUpload = (url: string) => {
		props.item.url = url
	}

    return {
      cancel,
	  confirm,
	  fileUpload,
    }
  },
});
</script>

<style scoped>
.form-check-input {
	margin-left: 0;
}

.upload-music {
	width: 40px;
	height: 40px;
	position: relative;
}
.upload-music img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}
.upload-music input {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	outline: 0;
	opacity: 0;
}
</style>