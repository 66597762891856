import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row upload" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.src || '/assets/image_preview_add.png'
    }, null, 8, _hoisted_2),
    _createElementVNode("input", {
      type: "file",
      class: "upload",
      id: "qr-picker",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadFilePicked && _ctx.uploadFilePicked(...args)))
    }, null, 32)
  ]))
}