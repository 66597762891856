<template>
	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
			<div class="align-self-center mb-3" style="height: 30px;">
				<button type="button" class="btn btn-primary" @click="openAddChankangDialog">添加</button>
			</div>
 			<div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
				<input type="text" id="article-keywords" class="form-control" v-model="keywords">
				<button class="btn btn-outline-secondary" type="button" @click="searchChankang">搜索</button>
			</div>
		</div>
 		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th scope="col">序号</th><th scope="col">标题</th><th scope="col">排序</th><th scope="col">添加时间</th><th scope="col">操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.title || (value.labelInfo?.title || "空")}}</td>
						<td>{{value.order}}</td>
						<td>{{dateFtt('yyyy-MM-dd hh:mm', new Date(value.operateTime))}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditChankangDialog(value.id)" />
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteChankang(value.id, value.title)" />
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadChankangList" />
		</div>
	</div>

	<AddChankangDialog :show='showAddDialog' :item='editing' @confirm='editChankang' @cancel='showAddDialog=false'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddChankangDialog from '@/modals/AddChankangDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt } from '@/common/util';

export default({
	components: { Pagination, AddChankangDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			keywords: "搜索",
			editing: {}
		});

		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
    const isBlue = process.env.VUE_APP_ENV === "blue"

		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadChankangList(1);
		});

		// 添加文章
		const openAddChankangDialog = () => {
		    refData.editing = {};
		    refData.showAddDialog = true;
		}

		// 跳转编辑页
		const openEditChankangDialog = async (id: string) => {
		    const errMsg = "查询信息失败";
		    try {
		    	const data = (await request({url: `chankang/${id}`, get: true})).data;
				console.log(data)
		        if (checkResponseDataAndAlert(data, errMsg) && data.data) {
		    		refData.editing = data.data;
		    		refData.showAddDialog = true;
		        }
		    } catch (e) {
		    	console.log(e);
		    	checkResponseDataAndAlert({}, errMsg);
		    }
		}

		// 编辑
		const  editChankang = async (postObj: any) => {
		    let gid = postObj.id;
		    const errMsg = `${(gid && gid.length > 0) ? '编辑' : '添加'}`;

			if (!postObj.label || postObj.label.length === 0
				|| !postObj.images || postObj.images.length === 0) {
				alert("带*号的内容不能为空");
				return
			}

			refData.showLoadingDialog = true;
			try {
				const data = (await request({url: `chankang/save`, data: postObj})).data;
				refData.showLoadingDialog = false;
			    if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
			        loadChankangList(nowPage.value);
			    }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg + "失败");
			}
		}

		// 删除
		const deleteChankang = async (card_no: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await request({url: `chankang/delete/${card_no}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功")
						loadChankangList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		}

		// 搜索
		const searchChankangInner = async () => {
			if (refData.keywords.length === 0) {
			    loadChankangList(1);
			    return;
			}

			nowPage.value = 1;
			try {
				const postObj = {"page" : 1, "size": 50, "content": refData.keywords, timestamp: Date.now() };
				const data = (await request({url: `chankang/search`, data: postObj})).data
			    if (checkResponseDataAndAlert(data) && data.data) {
					dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}

		// 加载列表
		const  loadChankangList = async (page: number) => {
		    if (page < 1) return;

		    nowPage.value = page;
			try {
				const data = (await request({url: `chankang/list/${page}/${50}`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}

		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
      isBlue,
			dateFtt,
			editChankang,
			loadChankangList,
			openAddChankangDialog,
			openEditChankangDialog,
			deleteChankang,

			...dataRef
		};
	},
});
</script>

<style>
</style>
