<template>
  <div class="flex-colum h-100">
    <div class="d-flex justify-content-between bd-highlight mb-3">
      <div class="align-self-center mb-3" style="height: 30px;">
        <button type="button" class="btn btn-primary" @click="openAddQuestionDialog">添加</button>
      </div>
      <div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 40%;">
        &nbsp;&nbsp;<select class="form-select" v-model="examType" @change="searchQuestionInner">
        <option selected value="">选择试卷类型</option>
        <option value="system">系统</option>
        <option value="meal">项目</option>
      </select>
        <input type="text" id="article-keywords" class="form-control"  v-model="keywords" placeholder="标题">
        <button class="btn btn-outline-secondary" type="button" @click="searchQuestionInner">搜索</button>
      </div>
    </div>
    <div>
      <table class="table table-bordered table-striped table-sm member-list">
        <thead>
        <tr>
          <th style="width: 50px;">序号</th><th style="width: 100px;">题目类型</th> <th style="width: 300px;">标题</th>
          <th style="width:100px;">判断题数量</th><th style="width:100px;">单选题数量</th><th style="width:100px;">多选题数量</th>
          <th style="width:100px;">优秀正确率</th><th style="width:100px;">合格正确率</th><th>状态</th><th>添加时间</th><th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value, idx) in dataList" :key='idx'>
          <td>{{50 * (nowPage - 1) + idx + 1}}</td>
          <td>{{ value.examType === 'system'?'系统':'项目'}}</td>
          <td>{{value.title}}</td>
          <td>{{value.trueFalseQuantity = getQuestionTypeQuantity(value, "trueFalse")}}</td>
          <td>{{value.radioQuantity = getQuestionTypeQuantity(value, "radio")}}</td>
          <td>{{value.checkboxQuantity = getQuestionTypeQuantity(value, "checkbox")}}</td>
          <td>{{value.excellentAccuracy}}</td>
          <td>{{value.passAccuracy}}</td>
          <td>
            <div class="mb-1 form-check form-switch" style="padding-top: 5px;">
              <input class="form-check-input" :checked="value.show" @change="updateQuestion(value)" type="checkbox" role="switch"/>
            </div>
          </td>
          <td>{{value.createTime}}</td>
          <td>
            <div class="flex-row flex-align-center">
              <img class="button-icon" src="/assets/ic_edit.png" @click="openEditQuestionDialog(value)" />
              <img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteQuestion(value.id, value.title)" />
              <!-- <img class="button-icon img-margin" src="/assets/ic_comment.png" @click="openPage('comment/${value.id}')" /> -->
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadQuestionList" />
    </div>
  </div>

  <AddExamPaperDialog ref="addDialogRef" :show='showAddDialog' @confirm='editExamPaper' @cancel='showAddDialog=false'/>
  <LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddExamPaperDialog from '@/modals/AddExamPaperDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt } from '@/common/util';

export default({
  components: { Pagination, AddExamPaperDialog },
  setup() {
    const refData = reactive({
      showAddDialog: false,
      showLoadingDialog: false,
      keywords: "",
      examType:""
    });

    const addDialogRef = ref<any>(null) // 通过 ref 绑定子组件
    const totalPage = ref<number>(0);
    const nowPage = ref<number>(1);
    const dataList = ref<any[]>([]);

    //needed in case we switch in from another window
    onMounted(async () => {
      await loadExamPaperList(1);
    });


    const openAddQuestionDialog = () => {
      addDialogRef.value!.setItemData(
          {'show': true,
          "examType":"meal",
          "title":"",
          "excellentAccuracy": 90,
          "passAccuracy":60,
          "questionIdList":[],
          "examQuestionList":[],
          }
      )
      refData.showAddDialog = true;
    }

    const getQuestionTypeQuantity = (value:any, questionType:string) =>{
      let i = 0;
      for(let j = 0; j < value.examQuestionList.length; j++){
        if(value.examQuestionList[j].questionType === questionType){
          i++;
        }
      }

      return i;
    }
    const getQuestionType = (questionType:any) => {
      if(questionType === 'trueFalse') {
        return "判断题";
      }else if(questionType === 'radio'){
        return '单选题';
      }else if(questionType === 'checkbox'){
        return '多选题';
      }
    }

    const getAnswer = (answer:any, rowValue:any) =>{
      for(let i = 0; i < rowValue.optionList.length; i++){
        if(rowValue.optionList[i].number == answer){
          return i+1;
        }
      }
    }

    // 跳转编辑页
    const openEditQuestionDialog = async (value: any) => {

      const errMsg = "查询信息失败";
      try {
        //const data = (await request({url: `examinationQuestionController/get/${id}`, get: true})).data;
        //if (checkResponseDataAndAlert(data, errMsg) && data.data) {
        addDialogRef.value!.setItemData(JSON.parse(JSON.stringify(value)))
        refData.showAddDialog = true;
        //}
      } catch (e) {
        console.log(e);
        checkResponseDataAndAlert({}, errMsg);
      }
    }

    const updateQuestion  = async (item:any) => {
      refData.showLoadingDialog = true;
      try {
        const postObj = item;
        postObj.show = !postObj.show;
        console.log(postObj)
        const data = (await request({url: `examinationPaperController/update`, data: postObj})).data;
        refData.showLoadingDialog = false;
        if (checkResponseDataAndAlert(data, "操作失败")) {
        } else {
          loadExamPaperList(nowPage.value);
        }
      } catch (e) {
        console.log(e);
        refData.showLoadingDialog = false;
        checkResponseDataAndAlert({}, "操作失败");
      }
    }

    // 编辑
    const  editExamPaper = async (postObj: any) => {
      let gid = postObj.id;
      const errMsg = `${(gid && gid.length > 0) ? '编辑' : '添加'}`;

      refData.showLoadingDialog = true;
      try {
        let data = {};
        if(gid != undefined) {
          data = (await request({url: `examinationPaperController/update`, data: postObj})).data;
        }else {
          data = (await request({url: `examinationPaperController/add`, data: postObj})).data;
        }
        refData.showLoadingDialog = false;
        if (checkResponseDataAndAlert(data, errMsg)) {
          refData.showAddDialog = false;
          loadExamPaperList(nowPage.value);
        }
      } catch (e) {
        console.log(e);
        refData.showLoadingDialog = false;
        checkResponseDataAndAlert({}, errMsg + " 添加试题失败");
      }
    }

    // 删除
    const deleteQuestion = async (id: string, title:String) => {
      if (confirm(`确认删除 ${title} 吗？`)) {
        const errMsg = "删除问题失败";
        try {
          const data = (await request({url: `examinationPaperController/del/${id}`, get: true})).data;
          if (checkResponseDataAndAlert(data, errMsg)) {
            loadExamPaperList(nowPage.value);
          }
        } catch (e) {
          checkResponseDataAndAlert({}, errMsg);
        }
      }
    }

    // 搜索
    const searchQuestionInner = async () => {
      nowPage.value = 1;
      try {
        const postObj = {"page" : 1, "size": 50, "searchValue": refData.keywords, "examType":refData.examType};
        const data = (await request({url: `examinationPaperController/list`, data: postObj})).data
        if (checkResponseDataAndAlert(data) && data.data) {
          dataList.value = data.data;
          totalPage.value = data.total;
        }
      } catch (e) {
        checkResponseDataAndAlert({});
      }
    }

    // 加载列表
    const  loadExamPaperList = async (page: number) => {
      if (page < 1) return;

      dataList.value = [];
      nowPage.value = page;
      try {
        //let postObj = new Object();
        let postObj = {
          page:page,
          size:50,
          examType:refData.keywords,

        };
        const data = (await request({url: `examinationPaperController/list`, data: postObj})).data;
        if (checkResponseDataAndAlert(data) && data.data) {
          dataList.value = data.data;
          totalPage.value = data.total;
        }
      } catch (e) {
        checkResponseDataAndAlert({});
      }
    }

    const dataRef = toRefs(refData);
    return {
      dataList,
      nowPage,
      totalPage,
      dateFtt,
      editExamPaper,
      loadExamPaperList,
      openAddQuestionDialog,
      openEditQuestionDialog,
      deleteQuestion,
      getQuestionType,
      getAnswer,
      getQuestionTypeQuantity,
      addDialogRef,
      updateQuestion,
      searchQuestionInner,
      ...dataRef
    };
  },
});
</script>

<style>
</style>
