<template>
	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3" style="margin-top: 20px;">
		</div>
		
		<div class="d-flex flex-row flex-wrap">
			<div @click="openEditorDialog('brand', '品牌故事')" class="item-block">品 牌<br>故 事</div>
			<div @click="openEditorDialog('manghe', '潮玩盲盒')" class="item-block">潮 玩<br>盲 盒</div>
			<div @click="openEditorDialog('food', '月子餐')" class="item-block">月 子<br>餐 食</div>
			<div @click="openEditorDialog('huli', '专业护理')" class="item-block">专 业<br>护 理</div>
			<div @click="openEditorDialog('privacy', '隐私政策')" class="item-block" v-if="job==='店长'">隐 私<br>政 策</div>
			<div @click="openEditorDialog('agreement', '用户协议')" class="item-block" v-if="job==='店长'">用 户<br>协 议</div>
			<div @click="openRuleDialog('invite','邀请')" class="item-block">邀 请<br>配 置</div>
			<div @click="openRuleDialog('yuyue','预约')" class="item-block">预 约<br>配 置</div>
			<div @click="openRuleDialog('checkroom','查房配置')" class="item-block">查 房<br>配 置</div>
			<div @click="openRuleDialog('vips','会员配置')" class="item-block" v-if="job==='店长'">会 员<br>配 置</div>
			<div @click="openRuleDialog('android','安卓版本')" class="item-block">安 卓<br>版 本</div>
			<div @click="openRuleDialog('about','关于我们')" class="item-block" v-if="isBlue">关 于<br>我 们</div>
		</div>
	</div>
	
	<RichEditorDialog ref="editorDialogRef" :title='editTitle' :show='showEditorDialog' @confirm='submitEditorContent' @cancel='showEditorDialog=false'/>
	<AppOtherDialog :title='editTitle' :item='editing' :show='showRuleDialog' @confirm='submitRule' @cancel='showRuleDialog=false'/>
	<AppVersionDialog :title='editTitle' :item='editing' :show='showVersionDialog' @confirm='submitRule' @cancel='showVersionDialog=false'/>
	<AppCheckRoomDialog :title='editTitle' :item='editing' :show='showCheckRoomDialog' @confirm='submitRule' @cancel='showCheckRoomDialog=false'/>
	<AboutDialog :title='editTitle' :item='editing' :show='showAboutDialog' @confirm='submitRule' @cancel='showAboutDialog=false'/>
	<AppVipsDialog :title='editTitle' :item='editing' :show='showVipsDialog' @confirm='submitRule' @cancel='showVipsDialog=false'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import RichEditorDialog from '@/modals/RichEditorDialog.vue';
import AppOtherDialog from '@/modals/AppOtherDialog.vue';
import AboutDialog from '@/modals/AboutDialog.vue';
import AppVersionDialog from '@/modals/AppVersionDialog.vue';
import AppCheckRoomDialog from '@/modals/AppCheckRoomDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request, {DEFAULTS} from "@/global";
import { checkResponseDataAndAlert, dateFtt, loadTokens } from '@/common/util';
import axios from 'axios';
import AppVipsDialog from '@/modals/AppVipsDialog.vue';

export default({
	components: { RichEditorDialog, AppOtherDialog, LoadingDialog, AppVersionDialog, AppCheckRoomDialog, AboutDialog, AppVipsDialog },
	setup() {
		const refData = reactive({
			showEditorDialog: false,
			showLoadingDialog: false,
			showRuleDialog: false,
			showVersionDialog: false,
			showCheckRoomDialog: false,
			showAboutDialog: false,
			showVipsDialog: false,
			editTitle: '',
			editing: {},
			isBlue: process.env.VUE_APP_ENV === "blue",
			job: ""
		});
		
		const editorDialogRef = ref<any>(null) // 通过 ref 绑定子组件
		
		//needed in case we switch in from another window
		onMounted(async () => {
			const token = loadTokens() as any;
			refData.job = JSON.parse(token).job;
			
		});

		// 编辑信息
		const openEditorDialog = async (id: string, title: string) => {
			const errMsg = `没有找到${title}的相关内容，请先添加或刷新重试`;
			refData.showLoadingDialog = true;
			refData.editTitle = title;
			try {
				const data = (await request({url: `${DEFAULTS.HOST}app/${id}.html`, get: true, guest: true})).data;
				refData.showLoadingDialog = false;
		        if (data) {
					editorDialogRef.value!.setItemData({content: data, type: id});
					refData.showEditorDialog = true;
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				editorDialogRef.value!.setItemData({content: "", type: id});
				refData.showEditorDialog = true;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 提交静态html内容
		const submitEditorContent = async (type: string, content: string) => {
			if (!type || type.length === 0 || !content || content.length === 0) {
				alert("内容不能为空");
				return
			}

			const errMsg = `操作失败`;
			refData.showLoadingDialog = true;
			try {
				const data = (await request({url: `app/static/save/${type}`, data: content, plainText: true})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showEditorDialog = false;
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 编辑信息
		const openRuleDialog = async (id: string, title: string) => {
			const errMsg = `没有找到${title}的相关内容，请先添加或刷新重试`;
			refData.showLoadingDialog = true;
			refData.editTitle = title;
			try {
				const data = (await request({url: `${DEFAULTS.HOST}app/${id}.html`, get: true, guest: true})).data;
				refData.showLoadingDialog = false;
		        if (data) {
					data.type = id;
					refData.editing = data;
					controlDialog(id, true)
		        }
			} catch (e) {
				refData.showLoadingDialog = false;
				refData.editing = {type: id};
				editorDialogRef.value!.setItemData({content: "", type: id});
				controlDialog(id, true)
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		const controlDialog = (id: string, show: boolean) => {
			if (id === 'android')
				refData.showVersionDialog = show;
			else if (id === 'checkroom')
				refData.showCheckRoomDialog = show;
			else if (id === 'about')
				refData.showAboutDialog = show;
			else if (id === 'vips')
				refData.showVipsDialog = show;
			else if (id === 'global')
				refData.showVipsDialog = show;
			else
				refData.showRuleDialog = show;
		}
		
		// 编辑
		const submitRule = async (postObj: any) =>  {			
		    const errMsg = `操作失败`;
			console.log(postObj)
			if (postObj.type === 'android') {
				if (!postObj.title || postObj.title.length === 0
					|| !postObj.version || postObj.version.length === 0
					|| !postObj.code || postObj.code.length === 0
					|| !postObj.url || postObj.url.length === 0) {
					alert("带*的内容不能为空");
					return
				}
			} else if (postObj.type === 'about') {
			} else if (postObj.type === 'vips') {
			} else if (postObj.type === 'checkroom') {
				if (!postObj.baby || postObj.baby.length === 0
					|| !postObj.mom || postObj.mom.length === 0) {
					alert("带*的内容不能为空");
					return
				}
			} else if ((postObj.type === 'invite' && (!postObj.rule || postObj.rule.length === 0))
			    || !postObj.image || postObj.image.length === 0) {
			    alert("带*的内容不能为空");
			    return
			}
		
		    refData.showLoadingDialog = true;
			try {
				const data = (await request({url: `app/static/save/${postObj.type}`, data: JSON.stringify(postObj), plainText: true})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showRuleDialog = false;
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		const dataRef = toRefs(refData);
		return {
			dateFtt,
			openEditorDialog,
			submitEditorContent,
			openRuleDialog,
			submitRule,
			
			editorDialogRef,
			...dataRef
		};
	},
});
</script>

<style>
</style>
