<template>
 	<div class="flex-colum h-100">
		<div class="flex-row bd-highlight mb-3">
		    <div class="align-self-center" style="height: 30px; margin-bottom: 5px;">
		        <button type="button" class="btn btn-primary" @click="openAddDialog">添加模板</button>
		    </div>
			<div class="input-group align-self-center ms-auto bd-highlight mr-5" style="width: 20%;">
				<select class="form-select" v-model="shop">
					<option v-for="(value, idx) in shops" :key='idx' :value="value.id">{{value.name}}</option>
				</select>
			</div>
		</div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th>序号</th><th>模板名</th><th>ID</th><th>门店</th><th>审批人</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.title}}</td>
						<td>{{value.id}}</td>
						<td>{{getShopName(value.shop)}}</td>
						<td>{{getApproverNames(value)}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditDialog(value)"/>
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteOne(value.id, value.title)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadTagList" />
		</div>
	</div>
	
	<CommonModal @cancel="showAddDialog=false" @confirm="editEmployee" v-if="showAddDialog">
		<table class="table table-borderless">
			<tr><td style="width: 100px;"></td><td></td></tr>
			<tr>
				<td><div class="mb-1 td-label-right"><label>*模板名:</label></div></td>
				<td><div class="mb-1">
					<input type="text" class="form-control" v-model="editing.title">
				</div></td>
			</tr>
			<tr>
				<td><div class="mb-1 td-label-right"><label>*门店:</label></div></td>
				<td><div class="mb-1">
					<select class="form-select" v-model="editing.shop">
						<option v-for="(value, idx) in shops" :key='idx' :value="value.id">{{value.name}}</option>
					</select>
				</div></td>
			</tr>
			<tr>
				<td><div class="mb-1 td-label-right"><label>*抄送:</label></div></td>
				<td><div class="mb-1 flex-row">
					<div class="approver" @click="editing.chaosong = undefined" v-if="editing.chaosong">{{ editing.chaosong?.name }}({{ editing.chaosong?.dept }})</div>
					<button @click="showPickEmployee=true;pickerType=1" class="approver ml-2" v-else>+</button>
				</div></td>
			</tr>
			<tr>
				<td><div class="mb-1 td-label-right"><label>*审批人:</label></div></td>
				<td><div class="mb-1 flex-row">
					<div v-for="(value, idx) in editing.approver" class="ml-2 mr-2">
						<div class="approver" @click="editing.approver.splice(idx,1)">{{ value.name }}({{ value.dept }})</div>
					</div>
					<button @click="showPickEmployee=true;pickerType=0" class="approver ml-2">+</button>
				</div></td>
			</tr>
		</table>
	</CommonModal>
	
	<PickEmployee v-if="showPickEmployee" @cancel="showPickEmployee=false" @confirm="addApprover" :shop="editing.shop" />
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import CommonModal from '@/modals/CommonModal.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import PickEmployee from "@/modals/PickEmployee.vue";
import { DEFAULTS } from "@/global";
import { checkResponseDataAndAlert, dateFtt, buildHttpHeader } from '@/common/util';
import axios from 'axios';
import request from "@/global";

export default({
	components: { Pagination, CommonModal, PickEmployee, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			showPickEmployee: false,
			pickerType: 0,
			shop: "",
			shops: [] as any[],
			editing: {approver:[]} as any
		});
		
		const type = "approver"
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
		
		watch(()=>refData.shop, ()=>{
			loadTagList(1);
		})
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadShops();
		  await loadTagList(1);
		});
		
		// 加载列表
		const loadShops = async () => {
			try {
				const data = (await request({url: `shop/list/1/100`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
					refData.shops.push(...data.data);
					refData.shop = refData.shops[0].id;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};

		const addApprover = (ops: any[]) => {
			if (refData.pickerType == 0) {
				if (!refData.editing.approver) refData.editing.approver = [];
				for (const op of ops) refData.editing.approver.push({name: op.name, operator: op.id, dept: op.dept});
			} else {
				refData.editing.chaosong = {name: ops[0].name, operator: ops[0].id, dept: ops[0].dept};
			}
			refData.showPickEmployee = false;
		}
		
		// 添加标签
		const openAddDialog = () => {
			refData.editing = {approver: [], shop: refData.shop};
			refData.showAddDialog = true;
		};
		
		// 编辑信息
		const openEditDialog = async (item: any) => {
		    const errMsg = "查询信息失败";
			try {
				refData.editing = JSON.parse(JSON.stringify(item))
				refData.showAddDialog = true;
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 编辑
		const editEmployee = async () =>  {
			const postObj = refData.editing
			console.log(postObj)
		    const errMsg = `操作失败`;		
			if ((!postObj.title || postObj.title.length < 2)
				|| (!postObj.shop || postObj.shop.length < 2)
		 		|| !postObj.approver || postObj.approver.length == 0) {
				alert("请完善带*的必填项");
				return;
			}
		
		    refData.showLoadingDialog = true;
			try {
				postObj.type = type
				const action = (postObj.id && postObj.id.length > 0) ? `update/${postObj.id}` : "save"
				const data = (await request({url: `preset/${action}`, data: postObj})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadTagList(nowPage.value);
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 删除
		const deleteOne = async (card_no: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await request({url: `preset/delete/${card_no}`, get: true})).data
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功");
						loadTagList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};
			
		// 加载列表
		const  loadTagList = async (page: number) => {
		    if (page < 1) return;
		
		    nowPage.value = page;
			try {
				const data = (await request({url: `preset/list/1/200?type=${type}&shop=${refData.shop}`, get: true})).data
				console.log(data);
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.data.length;
				}
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({});
			}
		};
		
		const getShopName = (shop: string) => {
			const res = refData.shops.filter((t:any)=>{return t.id === shop})
			return res.length > 0 ? res[0].name : "未知"
		};
		
		const getApproverNames = (item: any) => {
			return item.approver.map((t:any)=>t.name).join(",")
		}

		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
			
			getShopName,
			dateFtt,
			editEmployee,
			loadTagList,
			openAddDialog,
			openEditDialog,
			deleteOne,
			addApprover,
			getApproverNames,
			
			...dataRef
		};
	},
});
</script>

<style scoped>
	.approver {
		min-width: 26px;
		padding: 0 5px;
		line-height: 24px;
		cursor: pointer;
		font-size: 15px;
		color: #000000;
		border: 1px solid #000000;
	}
</style>