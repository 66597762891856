<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{item.id ? '编辑' : '添加'}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
					<tr><td style="width: 100px;"></td><td></td></tr>
					<tr>
						<td>
							<div class="mb-1 td-label-right">
								<label for="koubei-customer">*客户姓名:</label>
							</div>
						</td>
						<td>
							<div class="mb-1">
								<input type="text" class="form-control" v-model="item.customer">
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div class="mb-1 td-label-right">
								<label for="koubei-meal">*套餐:</label>
							</div>
						</td>
						<td>
							<div class="mb-1">
								<input type="text" class="form-control" v-model="item.meal">
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div class="mb-1 td-label-right">
								<label for="koubei-avatar">头像:</label>
							</div>
						</td>
						<td>
							<div class="mb-1">
								<ImageUploader :src='item.avatar' @upload='avatarUpload' />
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div class="mb-1 td-label-right">
								<label for="koubei-star">*评分:</label>
							</div>
						</td>
						<td>
							<div class="mb-1">
								<select class="form-select" aria-label="Default select example" v-model="item.star">
									<option :value="1" selected="">一星</option>
									<option :value="2">二星</option>
									<option :value="3">三星</option>
									<option :value="4">四星</option>
									<option :value="5">五星</option>
								</select>
								<!-- <input type="text" class="form-control rating" data-size="md" data-show-clear="false" data-min="0" data-max="5" data-step="1" id="koubei-star" name="koubei-star"> -->
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div class="mb-1 td-label-right">
								<label for="koubei-reason">*入住理由:</label>
							</div>
						</td>
						<td>
							<div class="mb-1">
								<textarea rows="2" class="form-control" v-model="item.reason"></textarea>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div class="mb-1 td-label-right">
								<label for="koubei-whichBest">*最满意:</label>
							</div>
						</td>
						<td>
							<div class="mb-1">
								<textarea rows="2" class="form-control" v-model="item.whichBest"></textarea>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div class="mb-1 td-label-right">
								<label for="koubei-advice">*改进意见:</label>
							</div>
						</td>
						<td>
							<div class="mb-1">
								<textarea rows="2" class="form-control" v-model="item.advice"></textarea>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div class="mb-1 td-label-right">
								<label for="koubei-images">照片:</label>
							</div>
						</td>
						<td>
							<div class="flex-row mb-1" style="flex-wrap: wrap; width: 650px;">
								<ImageUploader @upload='imageUpload' style="margin-left: 5px;"/>
								<img v-for="(value, index) in item.images" :src='value' :key='index' class="image" />
							</div>
						</td>
					</tr>
				</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
	<div id="datePickerContainerId"></div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import ImageUploader from '@/components/ImageUploader.vue'
import { DEFAULTS } from "@/global";
import axios from 'axios';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
	components: { ImageUploader, Datepicker },
	props: {  
		show: {
			type: Boolean,
			default: false, 
		},
		item: {
			type: Object,
			default: {},
		},
	},

  setup(props: any, ctx: any)
  {
	const dataList = ref<any[]>([]);
	
	onMounted(async () => {
	});
 
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		// console.log(JSON.stringify(toPostData(props.item, [])))
		
		ctx.emit('confirm', toPostData(props.item, ['show']));
	}
	
	const imageUpload = (url: string) => {
		if (!props.item.images) props.item.images = [];
		props.item.images.push(url)
	}
	
	const avatarUpload = (url: string) => {
		props.item.avatar = url
	}

    return {
      cancel,
	  confirm,
	  checkNumber,
	  imageUpload,
	  avatarUpload,
	  dataList
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
	.image {
		width: 150px;
		height: 150px;
		margin: 0 0px 5px 5px ;
		background-color: #00193A;
	}
</style>