<template>
	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
			<div class="align-self-center mb-3" style="height: 30px;">
				<button type="button" class="btn btn-primary" @click="openAddChankangDialog">添加</button>
			</div>
 			<div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
				<input type="text" id="article-keywords" class="form-control" placeholder="搜索" v-model="keywords">
				<button class="btn btn-outline-secondary" type="button" @click="loadChankangList(1)">搜索</button>
			</div>
		</div>
 		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th>序号</th><th>客户ID</th><th>客户姓名</th><th>门店</th><th>类型</th><th>项目</th><th>产康赠送金</th><th>状态</th><th>录入人</th><th>手工费</th><th>操作日期</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.customer}}</td>
						<td>{{shortenName(value.customerName||"")}}</td>
						<td>{{value.shopName}}</td>
						<td>{{value.type}}({{value.subtype}})</td>
						<td>{{value.title}}</td>
						<td>{{value.ckzsj_used > 0 ? `-${value.ckzsj_used}元` : "-"}}</td>
						<td v-if="value.status == 0"><span style="color: deepskyblue;">{{"待消耗"}}</span></td>
						<td v-else-if="value.status == 1"><span style="color: green;">{{"已消耗"}}</span></td>
						<td v-else><span style="color: firebrick;">{{"已取消"}}</span></td>
						<td>{{value.lururen}}</td>
						<td>{{value.shougong}}元</td>
						<td>{{value.operateDate}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteChankang(value.id, value.customerName)" />
								<img class="button-icon img-margin" src="/assets/ic_edit.png" @click="showDetial(value)" />
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadChankangList" />
		</div>
	</div>

	<LoadingDialog :show='showLoadingDialog' />
	<template v-if="showDetailDialog">
		<ShowDetailDialog @cancel="showDetailDialog=false" :json="editing" />
	</template>
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import ShowDetailDialog from '@/modals/ShowDetailDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt, shortenName } from '@/common/util';

export default({
	components: { Pagination, ShowDetailDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			showDetailDialog: false,
			keywords: "",
			editing: ""
		});

		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);

		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadChankangList(1);
		});

		// 删除
		const deleteChankang = async (id: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await request({url: `chankang/operate/delete/${id}?force=true`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功")
						loadChankangList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		}

		// 加载列表
		const  loadChankangList = async (page: number) => {
		    if (page < 1) return;

		    nowPage.value = page;
			try {
				const data = (await request({url: `chankang/operate/list/${page}/${50}?type=all&shop=all&search=${refData.keywords}&status=1`, get: true})).data;
				console.log(data)
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}

		const showDetial = async (item: any) => {
		    refData.showDetailDialog = true;
			refData.editing = JSON.stringify(item, null, "\t");
		}

		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,

			showDetial,
			dateFtt,
			loadChankangList,
			deleteChankang,
			shortenName,

			...dataRef
		};
	},
});
</script>

<style>
</style>
