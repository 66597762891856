<template>
	<div class="modal-backdrop" v-if="show">
	  <div class="modal3" >
		<Loading style="width: 40px; height: 40px;" />
	  </div>
	</div>
</template>

<script>
import { defineComponent } from "vue";
import Loading from '@/components/Loading.vue';

export default defineComponent ({
  components: { Loading },
  props: {
	show: {
		type: Boolean,
		default: false, 
	},
  },
  setup(props, ctx) {
	return {
	}
  }
});
</script>

<style scoped>
	.modal3 {
	    overflow-x:auto; 
	    display: flex; 
	    flex-direction: column;
		align-items: center;
		justify-content: center;
	    border-radius: 16px;
	    width: 100px;
		height: 100px;
	} 
</style>
