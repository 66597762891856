<template>
 	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
		    <div class="align-self-center mb-3" style="height: 30px;">
		        <button type="button" class="btn btn-primary" @click="openAddRoomDialog">添加</button>
		    </div>
		</div>
    <div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 40%;">
      <select class="form-select w-40" v-model="shopId">
        <option  key='100' value="">全部</option>
        <option v-for="(value, idx) in shopDataList" :key='idx' :value="value.id">{{value.name}}</option>
      </select>
      <select class="form-select w-40" v-model="show">
        <option  key='100' value="">全部</option>
        <option  key='1' value="1">启用</option>
        <option  key='2' value="0">禁用</option>
      </select>
      <input style="left:10px;right: 10px" type="text" id="article-keywords" class="form-control w-30" v-model="title" placeholder="房间号">
      <button class="btn btn-secondary" type="button" @click="searchRoom">搜索</button>
    </div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th>序号</th><th>房号</th><th>房型</th><th>门店</th><th>排序</th><th>显示</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.title}}</td>
						<td>{{value.roomType}}</td>
						<td>{{value.shopName ?? value.shop}}</td>
						<td>{{value.order}}</td>
						<td>
							<div class="mb-1 form-switch">
								<input class="form-check-input" :checked="value.show === 1" @change="updateRoom(value, 'show')" type="checkbox" role="switch"/>
							</div>
						</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditRoomDialog(value)"/>
								<img class="button-icon ml-5" src="/assets/ic_trash.png" @click="deleteRoom(value.id, value.title)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadRoomList" />
		</div>
	</div>

	<AddRoomDialog :show='showAddDialog' :room='editing' @cancel='showAddDialog = false' @confirm='editRoom'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddRoomDialog from '@/modals/AddRoomDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import { DEFAULTS } from "@/global";
import { checkResponseDataAndAlert, dateFtt, buildHttpHeader } from '@/common/util';
import axios from 'axios';
import request from "@/global";

export default({
	components: { Pagination, AddRoomDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			editing: {},
      title: "",
      shopId:"",
      show:1
		});


		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
    const shopDataList =  ref<any[]>([]);
		//needed in case we switch in from another window
		onMounted(async () => {
      await loadShops();
		  await loadRoomList(1);
		});

		// ========================文章标签========================
		// 添加标签
		const openAddRoomDialog = () => {
			refData.editing = {show: 1};
			refData.showAddDialog = true;
		};

		// 编辑信息
		const openEditRoomDialog = async (editItem: any) => {
			if (!editItem) return;
			console.log(JSON.stringify(editItem))
		    refData.editing = JSON.parse(JSON.stringify(editItem));
		    refData.showAddDialog = true;
		}

		// 编辑
		const editRoom = async (postObj: any) =>  {
			console.log(postObj)
		    const errMsg = `操作失败`;
			if (!postObj.title || postObj.title.length < 2
				|| !postObj.shop || postObj.shop.length < 2
				|| !postObj.roomType || postObj.roomType.length < 1) {
				alert("请完善带*的必填项");
				return;
			}

		    refData.showLoadingDialog = true;
			try {
				let api = "shop/room/save"
				const data = (await axios.post(`${DEFAULTS.URL}${api}`, postObj, {headers: buildHttpHeader()})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadRoomList(nowPage.value);
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}

		const updateRoom  = async (item: any, key: string) => {
			refData.showLoadingDialog = true;
			try {
				const postObj: any = {};
				postObj[`${key}`] = item[`${key}`] === 1 ? 0 : 1;
				const data = (await request({url: `preset/update/${item.id}`, data: postObj})).data;
				refData.showLoadingDialog = false;
			    if (checkResponseDataAndAlert(data, "操作失败")) {
					item[`${key}`] = postObj[`${key}`];
			    } else {
					loadRoomList(nowPage.value);
				}
			} catch (e) {
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, "操作失败");
			}
		}

		// 删除
		const deleteRoom = async (no: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await axios.get(`${DEFAULTS.URL}shop/room/delete/${no}`, {headers: buildHttpHeader()})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功");
						loadRoomList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};

    const searchRoom = async () => {
      loadRoomList(1);
    }
		// 加载列表
		const  loadRoomList = async (page: number) => {
		    if (page < 1) return;

		    nowPage.value = page;
			try {
				const data = (await request({url: `preset/list/${page}/${50}?type=room&shop=${refData.shopId}&title=${refData.title}&show=${refData.show}`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};

    const loadShops = async () => {
      try {
        const data = (await request({url: `shop/list/1/100`, get: true})).data;
        if (checkResponseDataAndAlert(data) && data.data) {
          shopDataList.value = data.data;
        }
      } catch (e) {
        //console.log(e)
        checkResponseDataAndAlert({});
      }
    };

		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
      shopDataList,
			dateFtt,
			editRoom,
			loadRoomList,
			openAddRoomDialog,
			openEditRoomDialog,
			deleteRoom,
			updateRoom,
      loadShops,
      searchRoom,
			...dataRef
		};
	},
});
</script>

<style>
</style>
