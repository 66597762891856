<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{title}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-col">
			<div class="flex-row mb-1" style="color:#000; padding-left: 20px;">
				<div class="flex-1 ml-3">标题</div><div class="flex-1 ml-3">充值金</div><div class="flex-1 ml-3">增值金</div>
			</div>
			<div v-for="(value, idx) in item.data" class="flex-row mb-3 flex-align-center">
				<span style="color:#000">{{ idx + 1 }}:</span>
				<input type="text" class="form-control ml-3" v-model="value.title">
				<input type="number" class="form-control ml-3" v-model="value.price">
				<input type="number" class="form-control ml-3" v-model="value.zengzhi">
			</div>
			<button type="button" class="btn btn-primary" style="width: 200px; margin-left: 20px;" @click="addNew">新增</button>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import ImageUploader from '@/components/ImageUploader.vue'
import { DEFAULTS } from "@/global";
import axios from 'axios';
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
	components: { ImageUploader,},
	props: {  
		show: {
			type: Boolean,
			default: false, 
		},
		title: {
			type: String,
			default: {},
		},
		item: {
			type: Object,
			default: {data: []},
		},
	},

  setup(props: any, ctx: any)
  {
	onMounted(async () => {
	});
 
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		ctx.emit('confirm', props.item);
	}

	const addNew = () => {
		if (!props.item.data) props.item.data = [];
		props.item.data.push({})
	}

    return {
      cancel,
	  confirm,
	  addNew
    }
  },
});
</script>

<style scoped>
.form-check-input {
	margin-left: 0;
}

.upload-music {
	width: 40px;
	height: 40px;
	position: relative;
}
.upload-music img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}
.upload-music input {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	outline: 0;
	opacity: 0;
}
</style>