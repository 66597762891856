<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">添加</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="class-title">*房号:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="room.title">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="meal-shop">*房型:</label>
						</div>
					</td>
					<td>
						<div class="mb-1" v-if="!isBlue">
							<select class="form-select" v-model="room.roomType">
								<option value="月子">月子</option>
								<option value="产康">产康</option>
								<option value="满月派对房">满月派对房</option>
							</select>
						</div>
						<div class="mb-1" v-else>
							<select class="form-select" v-model="room.roomType">
								<option value="美容">美容</option>
								<option value="瑜珈">瑜珈</option>
								<option value="接待">接待</option>
							</select>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="class-order">排序:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="number" class="form-control" v-model="room.order">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="meal-shop">*门店:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<select class="form-select" v-model="room.shop">
								<option v-for="(value, idx) in dataList" :key='idx' :value="value.id">{{value.name}}</option>
							</select>
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="flex-row-center modal-footer2">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
	<div id="datePickerContainerId"></div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, defineComponent, ref, onMounted } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import { DEFAULTS } from "@/global";
import axios from 'axios';

export default defineComponent({
  props: {  
    show: {
        type: Boolean,
        default: false, 
    },
	room: {
	    type: Object,
	    default: { shop: '', title: '' },
	},
  },

  setup(props: any, ctx: any)
  {
	  
	const dataList = ref<any[]>([]);
	const isBlue = process.env.VUE_APP_ENV === "blue"
	
	onMounted(async () => {
		loadShops();
	});
	
	// 加载列表
	const loadShops = async () => {
		try {
			const data = (await axios.get(`${DEFAULTS.URL}shop/list/1/100`)).data;
			if (checkResponseDataAndAlert(data) && data.data) {
				dataList.value = data.data;
			}
		} catch (e) {
			checkResponseDataAndAlert({});
		}
	};
	
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		ctx.emit('confirm', props.room);
	}

    return {
      cancel,
	  confirm,
	  dataList,
	  isBlue
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
</style>