import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import Home from '@/components/Home.vue';
import Article from '@/components/Article.vue';
import ArticleLabel from '@/components/ArticleLabel.vue';
import Customer from '@/components/Customer.vue';
import CustomerTag from '@/components/CustomerTag.vue';
import Employee from '@/components/Employee.vue';
import ArticleComment from '@/components/ArticleComment.vue';
import Music from '@/components/Music.vue';
import Shop from '@/components/Shop.vue';
import ChankangLabel from '@/components/ChankangLabel.vue';
import Chankang from '@/components/Chankang.vue';
import Activity from '@/components/Activity.vue';
import ShopMeal from '@/components/ShopMeal.vue';
import Koubei from '@/components/Koubei.vue';
import Yuyue from '@/components/Yuyue.vue';
import Feedback from '@/components/Feedback.vue';
import AppOther from '@/components/AppOther.vue';
import AppHome from '@/components/AppHome.vue';
import JobTitle from '@/components/JobTitle.vue';
import Slogan from '@/components/Slogan.vue';
import Channel from '@/components/Channel.vue';
import Follows from '@/components/Follows.vue';
import Food from '@/components/Food.vue';
import ShopRoom from '@/components/ShopRoom.vue';
import ChankangPack from '@/components/ChankangPack.vue';
import ChankangMeal from '@/components/ChankangMeal.vue';
import Excel from '@/components/Excel.vue';
import WorkTime from '@/components/WorkTime.vue';
import Department from '@/components/Department.vue';
import Purchase from '@/components/Purchase.vue';
import ChankangOperate from '@/components/ChankangOperate.vue';
import Record from '@/components/Record.vue';
import Device from '@/components/Device.vue';
import Greeting from '@/components/Greeting.vue';
import Approver from '@/components/Approver.vue';
import AppGlobal from '@/components/AppGlobal.vue';
import Question from "@/components/Question.vue";
import SystemTutorial from "@/components/SystemTutorial.vue";
import MealTutorial from "@/components/MealTutorial.vue";
import ExamQuestion from "@/components/ExamQuestion.vue";
import ExamPaper from "@/components/ExamPaper.vue";

import QuillEdit from "@/components/edit/index.vue";

const routes: Array<RouteRecordRaw> = [
  { path: '/', name: 'Home', component: Home },
  { path: '/article', name: 'Article', component: Article },
  { path: '/article_label', name: 'Article Label', component: ArticleLabel },
  { path: '/customer', name: 'Customer', component: Customer },
  { path: '/customer_tag', name: 'Customer Tag', component: CustomerTag },
  { path: '/employee', name: 'Employee', component: Employee },
  { path: '/music', name: 'Music', component: Music },
  { path: '/shop', name: 'Shop', component: Shop },
  { path: '/meal', name: 'Shop Meal', component: ShopMeal },
  { path: '/comment', name: 'Article Comment', component: ArticleComment },
  { path: '/chankang_label', name: 'Chankang Label', component: ChankangLabel },
  { path: '/chankang', name: 'Chankang', component: Chankang },
  { path: '/activity', name: 'Activity', component: Activity },
  { path: '/koubei', name: 'Koubei', component: Koubei },
  { path: '/yuyue', name: 'Yuyue', component: Yuyue },
  { path: '/feedback', name: 'Feedback', component: Feedback },
  { path: '/app_other', name: 'App Other', component: AppOther },
  { path: '/app_home', name: 'App Home', component: AppHome },
  { path: '/job', name: 'Job Title', component: JobTitle },
  { path: '/slogan', name: 'Slogan', component: Slogan },
  { path: '/channel', name: 'Channel', component: Channel },
  { path: '/follows', name: 'Follows', component: Follows },
  { path: '/food', name: 'Food', component: Food },
  { path: '/room', name: 'Room', component: ShopRoom },
  { path: '/chankang_op', name: 'ChankangOperate', component: ChankangOperate },
  { path: '/chankang_pack', name: 'ChankangPack', component: ChankangPack },
  { path: '/chankang_meal', name: 'ChankangMeal', component: ChankangMeal },
  { path: '/excel', name: 'Excel', component: Excel },
  { path: '/worktime', name: 'WorkTime', component: WorkTime },
  { path: '/dept', name: 'Department', component: Department },
  { path: '/purchase', name: 'Purchase', component: Purchase },
  { path: '/record', name: 'Record', component: Record },
  { path: '/device', name: 'Device', component: Device },
  { path: '/greeting', name: 'Greeting', component: Greeting },
  { path: '/approver', name: 'Approver', component: Approver },
  { path: '/question', name: 'Question', component: Question },
  { path: '/system_tutorial', name: 'SystemTutorial', component: SystemTutorial },
  { path: '/meal_tutorial', name: 'MealTutorial', component: MealTutorial },
  { path: '/exam_question', name: 'ExamQuestion', component: ExamQuestion },
  { path: '/exam_paper', name: 'ExamPaper', component: ExamPaper },
  { path: '/quill_edit', name: 'QuillEdit', component: QuillEdit },
  { path: '/global', name: 'Global', component: AppGlobal },
];

export const allNavMenus = {
	"人员管理": [
		{title: "客户", tag: "/customer", who: "店长"},
		{title: "客户跟进", tag: "/follows", who: "店长"},
		{title: "客户标签", tag: "/customer_tag", who: "店长"},
		{title: "客户渠道", tag: "/channel", who: "店长"},
		{title: "员工", tag: "/employee", who: "店长"},
		{title: "职位", tag: "/job", who: "店长"},
		{title: "部门", tag: "/dept", who: "店长"},
		{title: "收款", tag: "/purchase", who: "店长"},
	],
	"App配置": [
		{title: "首页配置", tag: "/app_home", who: "店长,运营"},
		{title: "其他配置", tag: "/app_other", who: "店长,运营"},
		{title: "用户反馈", tag: "/feedback", who: "店长,运营"},
		{title: "运营通用", tag: "/global", who: "店长,运营"},
	],
	"门店管理": [
		{title: "门店", tag: "/shop", who: "店长"},
		{title: "活动", tag: "/activity", who: "店长,运营"},
		{title: "月子餐", tag: "/food", who: "店长,运营"},
		{title: "月子套餐", tag: "/meal", who: "店长,运营"},
		{title: "月子记录", tag: "/record", who: "店长"},
		{title: "服务口碑", tag: "/koubei", who: "店长,运营"},
		{title: "房间管理", tag: "/room", who: "店长"},
		{title: "小程序预约", tag: "/yuyue", who: "店长"},
		{title: "客户预订单", tag: "/excel", who: "店长"},
		{title: "护士班次管理", tag: "/worktime", who: "店长"},
		{title: "审批人模板", tag: "/approver", who: "店长"},
	],
	"内容管理": [
		{title: "服务标签", tag: "/article_label", who: "店长,运营"},
		{title: "专业服务", tag: "/article", who: "店长,运营"},
		{title: "服务评论", tag: "/comment", who: "店长,运营"},
		{title: "十万个为什么", tag: "/music", who: "店长,运营"},
		{title: "鼓励语", tag: "/slogan", who: "店长,运营"},
		{title: "早晚安", tag: "/greeting", who: "店长,运营", hide: process.env.VUE_APP_ENV !== "blue"},
	],
	"产康管理": [
		{title: "产康消耗", tag: "/chankang_op", who: "店长"},
		{title: "案例分类", tag: "/chankang_label", who: "店长"},
		{title: "案例列表", tag: "/chankang", who: "店长"},
		{title: "产康包", tag: "/chankang_pack", who: "店长"},
		{title: "产康套餐", tag: "/chankang_meal", who: "店长"},
		{title: "仪器设备", tag: "/device", who: "店长"},
	],
	"学习中心": [
		{title: "常见问题", tag: "/question", who: "店长,运营"},
		{title: "系统教程", tag: "/system_tutorial", who: "店长,运营"},
		{title: "项目教程", tag: "/meal_tutorial", who: "店长,运营"},
		{title: "考题管理", tag: "/exam_question", who: "店长,运营"},
		{title: "试卷管理", tag: "/exam_paper", who: "店长,运营"},
		{title: "编辑", tag:"/quill_edit", who: "店长,运营"}
	],
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
