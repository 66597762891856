<template>
 	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
		    <div class="align-self-center mb-3" style="height: 30px;">
		        <button type="button" class="btn btn-primary" @click="openAddShopDialog">添加</button>
		    </div>
		    <div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
		        <input type="text" id="shop-keywords" class="form-control" v-model="keywords" placeholder="搜索">
		        <button class="btn btn-outline-secondary" type="button" @click="loadShopList(1)">搜索</button>
		    </div>
		</div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th>序号</th><th>店名</th><th>ID</th><th>城市</th><th>收款公司</th><th>地址</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.name}}({{ value.prefix }})</td>
						<td>{{value.id}}</td>
						<td>{{value.city || "-"}}</td>
            <td>{{value.collectCompany || "-"}}</td>
						<td>{{value.address || "-"}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditShopDialog(value.id)"/>
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteShop(value.id, value.name)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadShopList" />
		</div>
	</div>

	<AddShopDialog :show='showAddDialog' :item='editing' @cancel='showAddDialog = false' @confirm='editShop'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddShopDialog from '@/modals/AddShopDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt } from '@/common/util';

export default({
	components: { Pagination, AddShopDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			keywords: "",
			editing: {}
		});

		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);

		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadShopList(1);
		});

		// ========================文章标签========================
		// 添加标签
		const openAddShopDialog = () => {
			refData.editing = {};
			refData.showAddDialog = true;
		};

		// 编辑信息
		const openEditShopDialog = async (id: string) => {
		    const errMsg = "查询信息失败";
			try {
				const data = (await request({url:`shop/${id}`, get: true})).data;
		        if (checkResponseDataAndAlert(data, errMsg) && data.data) {
					data.data.show = (data.data.show === 1)
					refData.editing = data.data;
					refData.showAddDialog = true;
		        }
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}

		// 编辑
		const editShop = async (postObj: any) =>  {
			console.log(postObj)
		    const errMsg = `操作失败`;
			if (!postObj.name || postObj.name.length < 2
				|| !postObj.city || postObj.city.length < 2
				|| !postObj.address || postObj.address.length < 2
				|| !postObj.image || postObj.image.length < 2) {
				alert("请完善带*的必填项");
				return;
			}

		    refData.showLoadingDialog = true;
			try {
				const data = (await request({url:`shop/save`, data: postObj})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadShopList(nowPage.value);
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}

		// 删除
		const deleteShop = async (card_no: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await request({url:`shop/delete/${card_no}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功");
						loadShopList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};

		// 加载列表
		const  loadShopList = async (page: number) => {
		    if (page < 1) return;

		    nowPage.value = page;
			try {
				const data = (await request({url:`shop/list/${page}/${50}`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};

		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,

			dateFtt,
			editShop,
			loadShopList,
			openAddShopDialog,
			openEditShopDialog,
			deleteShop,

			...dataRef
		};
	},
});
</script>

<style>
</style>
