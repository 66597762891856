<template>
  <div class="modal-backdrop">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{ title }}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-colum" style="overflow: hidden;height: 70vh;">
			<input type="text" class="form-control" v-model="search" placeholder="搜索" />
			<div style="overflow-y: scroll; margin-top: 10px;">
				<div v-for="(v, idx) in showList" class="flex-row flex-align-center ck-item" @click="select(v)">
					<input type="checkbox" v-model="v.selected" class="mr-2" />
					<div>{{v.title}}</div>
				</div>
			</div>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref, watch } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import request from "@/global";

export default defineComponent({
	props: {
		title: {
			type: String,
			default: "选择仪器",
		},
		shop: {
			type: String,
			default: "",
		},
		type: {
			type: String,
			default: "device",
		},
	},

  setup(props: any, ctx: any)
  {
	const refData = reactive({
		dataList: [] as any[],
		showList: [] as any[],
		search: '',
	});	
	
	watch(()=>refData.search, () => {
		console.log("search", refData.search)
		refData.showList = refData.dataList.filter((t)=>{return t.name.includes(refData.search)});
	});
	
	watch(()=>props.shop, async () => {
		loadData();
	})

	onMounted(() => {
		loadData();
	})
	
	// 加载列表
	const loadData = async () => {
		if (!props.shop || props.shop.length <= 0) return;
		
		try {
			let query = `type=${props.type}`;
			const data = (await request({url: `preset/list/1/200?${query}`, get: true})).data;
			console.log(data);
			if (checkResponseDataAndAlert(data) && data.data) {
				refData.dataList = data.data;
				refData.showList = [...refData.dataList];
			}
		} catch (e) {
			checkResponseDataAndAlert({});
		}
	};
 
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		const fd = refData.dataList.filter((t)=>{return t.selected});
		console.log(fd)
		for (const tt of fd) tt.selected = false;
		ctx.emit('confirm', fd);
	}

	const select = (v: any) => {
		v.selected = !v.selected;
	}
	
	const dataRef = toRefs(refData);
    return {
      cancel,
	  confirm,
	  select,
	  
	  ...dataRef
    }
  },
});
</script>

<style scoped>
	.ck-item {
		cursor: pointer;
		height: 36px;
		color: #000000;
		font-size: 16px;
	}
</style>