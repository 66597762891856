<template>
	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
 			<div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
				<input type="text" id="article-keywords" class="form-control" placeholder="客户ID" v-model="keywords">
				<button class="btn btn-outline-secondary" type="button" @click="loadRecordList(1)">搜索</button>
			</div>
		</div>
 		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th>序号</th><th>客户</th><th>房号</th><th>类型</th><th>数值</th><th>颜色</th><th>形状</th><th>录入人</th><th>操作日期</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.customer}}</td>
						<td>{{value.roomNo}}</td>
						<td>{{value.type}}({{value.subtype}})</td>
						<td>{{value.value}}{{value.unit||""}}</td>
						<td>{{value.color}}</td>
						<td>{{value.shape}}</td>
						<td>{{value.operatorName}}</td>
						<td>{{value.operateDate}} {{value.clockTime}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteRecord(value.id, value.type)" />
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadRecordList" />
		</div>
	</div>
	
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt, shortenName } from '@/common/util';

export default({
	components: { Pagination },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			keywords: "",
			editing: {}
		});
		
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadRecordList(1);
		});
		
		// 删除
		const deleteRecord = async (id: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await request({url: `record/delete/${id}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功")
						loadRecordList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		}
		
		// 加载列表
		const  loadRecordList = async (page: number) => {
		    if (page < 1 || refData.keywords.length <= 0) return;
		
		    nowPage.value = page;
			try {
				const data = (await request({url: `record/list/${refData.keywords}/${page}/${50}`, get: true})).data;
				console.log(data)
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
					console.log(data.data)
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}
		
		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
			
			dateFtt,
			loadRecordList,
			deleteRecord,
			shortenName,
			
			...dataRef
		};
	},
});
</script>

<style>
</style>
