<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">添加</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>标题:</label></div></td>
					<td><div class="mb-1"><input type="text" class="form-control" v-model="tag.title"></div></td>
				</tr>
				<tr v-if="tag.type === 'device'">
					<td>
						<div class="mb-1 td-label-right"><label>门店:</label></div>
					</td>
					<td>
						<div class="mb-1">
							<select class="form-select" v-model="tag.shop">
								<option v-for="(value, idx) in dataList" :key='idx' :value="value.id">{{value.name}}</option>
							</select>
						</div>
					</td>
				</tr>
				<tr v-if="tag.type === 'greeting'">
					<td><div class="mb-1 td-label-right"><label>子类型:</label></div></td>
					<td><div class="mb-1"><input type="text" class="form-control" placeholder="早安/晚安" v-model="tag.subtype"></div></td>
				</tr>
				<tr v-if="tag.type === 'device'">
					<td><div class="mb-1 td-label-right"><label>仪器编号:</label></div></td>
					<td><div class="mb-1"><input type="text" class="form-control" v-model="tag.no"></div></td>
				</tr>
				<tr v-if="hasDetail">
					<td>
						<div class="mb-1 td-label-right">
							<label for="class-title">详情:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<textarea class="form-control" v-model="tag.detail"></textarea>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="class-order">排序:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="number" class="form-control" v-model="tag.order">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label>推荐:</label>
						</div>
					</td>
					<td>
						<div class="mb-1 form-switch">
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="tag.recommend"/>
						</div>
					</td>
				</tr>
				<tr v-if="tag.type === 'channel'">
					<td>
						<div class="mb-1 td-label-right">
							<label>显示:</label>
						</div>
					</td>
					<td>
						<div class="mb-1 form-switch">
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="tag.show"/>
						</div>
					</td>
				</tr>
				<tr v-if="tag.type === 'slogan'">
					<td>
						<div class="mb-1 td-label-right">
							<label for="class-title">日期:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<Datepicker teleport='#datePickerContainerId' v-model="tag.date" :enableTimePicker="false" format="yyyy-MM-dd"></Datepicker>
						</div>
					</td>
				</tr>
				<tr v-if="hasImage">
					<td><div class="mb-1 td-label-right"><label>图片:</label></div></td>
					<td><div class="mb-1"><ImageUploader :src='tag.image' @upload='imageUpload' /></div></td>
				</tr>
				<tr v-if="hasImages">
					<td>
						<div class="mb-1 td-label-right"><label>图文详情:</label></div>
					</td>
					<td>
						<div class="mb-1 flex-row">
							<div v-for="(img, idx) in tag.images" :key='idx'>
								<img :src='img' style="width:150px;height:150px;cursor:pointer;" @click="tag.images.splice(idx,1)" />
							</div>
							<div class="mb-1"><ImageUploader @upload='imagesUpload' /></div>
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="flex-row-center modal-footer2">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
	<div id="datePickerContainerId"></div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, defineComponent, ref, onMounted } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import ImageUploader from '@/components/ImageUploader.vue'
import request from "@/global";

export default defineComponent({
  components: { Datepicker, ImageUploader },
  props: {  
    show: {
        type: Boolean,
        default: false, 
    },
	hasDetail: {
        type: Boolean,
        default: false, 
    },
	hasImage: {
        type: Boolean,
        default: false,
    },
	hasImages: {
        type: Boolean,
        default: false,
    },
	tag: {
	    type: Object,
	    default: { title: '' },
	},
  },

  setup(props: any, ctx: any)
  {
	const dataList = ref<any[]>([]);
	
	onMounted(async () => {
		loadShops();
	});
	
	// 加载列表
	const loadShops = async () => {
		try {
			const data = (await request({url: `shop/list/1/100`, get: true})).data;
			if (checkResponseDataAndAlert(data) && data.data) {
				dataList.value = data.data;
			}
		} catch (e) {
			checkResponseDataAndAlert({});
		}
	};

    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		if (props.tag.date && props.tag.date instanceof Date)
			props.tag.date = dateFtt('yyyy-MM-dd', props.tag.date)
		ctx.emit('confirm', props.tag);
	}

	const imageUpload = (url: string) => {
		props.tag.image = url
	}

	const imagesUpload = (url: string) => {
		if (!props.tag.images) props.tag.images = []
		props.tag.images.push(url)
	}

    return {
      cancel,
	  confirm,
	  imageUpload,
	  imagesUpload,
	  dataList,
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
</style>