<template>
	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3" style="margin-top: 20px;">
		    <div class="align-self-center mb-3" style="height: 30px;">
		        <button type="button" class="btn btn-primary" @click="openAddAppHomeDialog('Banner')">添加Banner</button>
				<button type="button" class="btn btn-primary" style="margin-left: 15px;" @click="openAddAppHomeDialog('栏目')">添加栏目</button>
		        <button type="button" class="btn btn-primary" style="margin-left: 15px;margin-right: 15px;" @click="openAddAppHomeDialog('分类')">添加分类</button>
		        <button type="button" class="btn btn-primary" @click="openAddAppHomeDialog('内容')">添加内容</button>
		    </div>
		</div>
		
 		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th>序号</th><th>类型</th><th>标题</th><th>排序</th><th>状态</th><th>图片</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{ value.type }}</td>
						<td>{{ value.title || "空" }}</td>
						<td>{{ value.order }}</td>
						<td>{{ value.show==0 ? '隐藏' : "显示" }}</td>
						<td><img class="image-preview-list-image" :src="value.image"></td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditAppHomeDialog(value.id)" />
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteAppHome(value.id, value.type)" />
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	
	<AppHomeDialog ref="addDialogRef" :show='showAddDialog' :item='editing' @reopen='openEditAppHomeDialog' @confirm='submitAppHome' @cancel='showAddDialog=false'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import AppHomeDialog from '@/modals/AppHomeDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt } from '@/common/util';

export default({
	components: { AppHomeDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			editing: {}
		});
		
		const addDialogRef = ref<any>(null) // 通过 ref 绑定子组件
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadAppHomeList(1);
		});
		
		// 添加文章
		const openAddAppHomeDialog = (type: string) => {
			refData.editing = {'type': type, 'show': true}
		    refData.showAddDialog = true;
		}
			
		// 跳转编辑页
		const openEditAppHomeDialog = async (id: string) => {
			refData.showAddDialog = false;
			const errMsg = "查询信息失败";
			try {
				const data = (await request({url: `app/home/${id}`, get: true})).data;
			    if (checkResponseDataAndAlert(data, errMsg) && data.data) {
					data.data.show = (data.data.show === 1);
					refData.editing = data.data;
					refData.showAddDialog = true;
			    }
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 编辑
		const submitAppHome = async (postObj: any) => {	
		    const errMsg = `操作失败`;
		
			let isBanner = postObj.type === 'Banner';
			if (!isBanner && (!postObj.title || postObj.title.length === 0)) {
				alert("标题不能为空");
				return
			}
			if (!postObj.link || postObj.link.length === 0) {
				alert("链接不能为空");
				return
			}

			refData.showLoadingDialog = true;
			try {
				const data = (await request({url: `app/home/save`, data: postObj})).data;
				refData.showLoadingDialog = false;
			    if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
			        loadAppHomeList(nowPage.value);
			    }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 删除
		const deleteAppHome = async (gid: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await request({url: `app/home/delete/${gid}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功")
						loadAppHomeList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		}
			
		// 加载列表
		const  loadAppHomeList = async (page: number) => {
		    if (page < 1) return;
		
		    nowPage.value = page;
			try {
				const data = (await request({url: `app/home/list?all=1`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
					data.data.sort(function(a: any, b: any) {
						if (a.type === b.type) return a.order - b.order;
						else if (a.type === 'Banner') return -1;
						else if (a.type === '内容') return 1;
						else if (a.type === '分类') return (b.type === 'Banner') ? 1 : -1;
					});
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}
		
		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
			
			dateFtt,
			submitAppHome,
			loadAppHomeList,
			openAddAppHomeDialog,
			openEditAppHomeDialog,
			deleteAppHome,
			addDialogRef,
			
			...dataRef
		};
	},
});
</script>

<style>
</style>
