<template>
	<div class="flex-colum-center wh-100">
		<h2>欢迎使用管理后台</h2>
		<div class="btn btn-primary mt-6" @click="logout">退出登录</div>
	</div>
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import { useRouter } from "vue-router";
const { useSnackbar } = require("vue3-snackbar");

export default defineComponent({
	components: { },
	setup(props, ctx) {
		
		const router = useRouter();
		
		const logout = async () => {
			if (confirm("确定要退出登录吗？")) {
				sessionStorage.removeItem("token");
				router.push('/');
				setTimeout(()=>location.reload(), 400);
			}
		}
		
		return {
			logout
		};
	},
});
</script>

<style scoped>
h2 {
	font-size: 24px;
	font-weight: bold;
}
</style>
