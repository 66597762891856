<template>
  <div class="modal-backdrop"  v-if="show">
     <div class="modal2" style="width: 900px;">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title" v-if="itemData.data.id != undefined">修改</div>
          <div class="text-center flex-1 modal-header-title" v-else >增加</div>
          <button @click="cancel" class="modal-header-close">
			<img src="../assets/ic_close.svg" />
          </button>
     </div>
     <div class="modal-body flex-row flex-wrap overflow-y-scroll ">
			 <table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
          <td><div class="mb-1 td-label-right"><label>*考卷类型:</label></div></td>
          <td>
          <input class="mb-1" type="radio" name="examType" id="examType1" value="system" v-model="itemData.data.examType" >
          <label class="form-check-label" for="examType1">
            系统
          </label>
          <input class="mb-1" type="radio" name="examType" id="examType2" value="meal" v-model="itemData.data.examType">
          <label class="form-check-label" for="examType2">
            项目
          </label>
          </td>
				</tr>
         <tr>
           <td><div class="mb-1 td-label-right td-label-top"><label>*标题:</label></div></td>
           <td><div class="mb-1"><input type="text" class="form-control" v-model="itemData.data.title"></div></td>
         </tr>
         <tr>
           <td><div class="mb-1 td-label-right"><label>*考试标准：</label></div></td>
           <td>
             <div class="mb-1 input-group" >
               <span class="align-self-center">优秀正确率：&nbsp;</span><input type="number" max="100"  min="80" class="form-control" v-model="itemData.data.excellentAccuracy">
               &nbsp;&nbsp;<span class="align-self-center">合格正确率：</span><input type="number" max="80"  min="60" class="form-control" v-model="itemData.data.passAccuracy">
             </div>
           </td>
         </tr>
         <tr>
           <td><div class="mb-1 td-label-right"><label>*数量设置：</label></div></td>
           <td>
             <div class="mb-1 input-group input-group-sm" >
               <span>判断题：&nbsp;</span><input type="number" class="form-control w-10" v-model="itemData.data.trueFalseQuantity">
               <span>&nbsp;&nbsp;&nbsp;单选题：&nbsp;</span><input type="number" class="form-control" v-model="itemData.data.radioQuantity">
               <span>&nbsp;&nbsp;&nbsp;多选题：&nbsp;</span><input type="number" class="form-control" v-model="itemData.data.checkboxQuantity">
               &nbsp;&nbsp;&nbsp;<button type="button" class="btn btn-outline-success h-75"  @click="autoSelect" >自动生成</button>
             </div>
           </td>
         </tr>
         <tr>
           <td><div class="mb-1 td-label-right"><label>题目选择:</label></div></td>
           <td>
             <div class="container">
               <div class="row align-items-start">
                 <div  class="col">
                   <span class="fs-5">题库</span>
                   <div class="overflow-div">
                       <span class="examQuestionType">判断题</span>
                       <div v-for="(value, idx) in allExamQuestionList">
                         <div v-if="value.questionType =='trueFalse'"><input class="mb-1" type="checkbox" :id="value.id" :value="value.id" v-model="itemData.data.questionIdList">
                           <label class="mb-1 form-check-label" :for="value.id">
                             <img class="question-image" src="../../public/assets/ic_set.png" /><span style="font-family:黑体">&ensp;{{value.title}}&ensp;</span>
                           </label>
                         </div>
                       </div>
                       <span class="examQuestionType">单选题</span>
                       <div v-for="(value, idx) in allExamQuestionList">
                         <div v-if="value.questionType =='radio'"><input class="mb-1" type="checkbox"  :id="value.id" :value="value.id" v-model="itemData.data.questionIdList">
                           <label class="mb-1 form-check-label" :for="value.id">
                             <img class="question-image" src="../../public/assets/ic_edit.png" /><span style="font-family:黑体">&ensp;{{value.title}}&ensp;</span>
                           </label>
                         </div>
                       </div>
                       <span class="examQuestionType">多选题</span>
                       <div v-for="(value, idx) in allExamQuestionList">
                         <div v-if="value.questionType =='checkbox'"><input class="mb-1" type="checkbox" :id="value.id" :value="value.id" v-model="itemData.data.questionIdList">
                           <label class="mb-1 form-check-label" :for="value.id">
                             <img class="question-image" src="../../public/assets/ic_time.png"/><span style="font-family:黑体">&ensp;{{value.title}}&ensp;</span>
                           </label>
                         </div>
                       </div>
                   </div>
                  </div>
                  <div class="col">
                     <span class="fs-5">已选择题目</span>
                     <div class="overflow-div">
                       <div v-for ="(value, idx) in itemData.data.examQuestionList">
                         <div v-if="value.questionType =='trueFalse'">
                           <img class="question-image" src="../../public/assets/ic_set.png" /><span class="selectbackcolor" style="font-family:黑体;">&ensp;{{value.title}}&ensp;</span>
                         </div>
                         <div v-if="value.questionType =='radio'">
                           <img class="question-image" src="../../public/assets/ic_edit.png" /><span class="selectbackcolor" style="font-family:黑体;">&ensp;{{value.title}}&ensp;</span>
                         </div>
                         <div v-if="value.questionType =='checkbox'">
                           <img class="question-image" src="../../public/assets/ic_time.png"/><span class="selectbackcolor" style="font-family:黑体;">&ensp;{{value.title}}&ensp;</span>
                         </div>
                       </div>
                     </div>
                 </div>
              </div>
             </div>
           </td>
         </tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>状态:</label></div></td>
					<td>
						<div class="mb-1 form-switch">
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="itemData.data.show"/>
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="flex-row-center modal-footer2">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, ref, onMounted, watch } from "vue";
import RichEditor from '@/components/RichEditor.vue'
import ImageUploader from '@/components/ImageUploader.vue'
import FileUploader from '@/components/FileUploader.vue'
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import request from "@/global";


export default defineComponent({
  components: { RichEditor, ImageUploader, FileUploader },
  emits: ["confirm", 'cancel'],
  props: {
    show: {
        type: Boolean,
        default: false,
    },
  },

  setup(props: any, ctx: any) {
	  const refData = reactive({
      trueFalseQuestion:[],
      radioQuestion:[],
      checkboxQuestion:[]
  });


	const allExamQuestionList = ref<any[]>([]);
	const itemData = reactive({data: {id:null,
      examType:"",
      title: "",
      questionIdList: [],
      examQuestionList:[],
      excellentAccuracy:80,
      passAccuracy:0,
      show:true,
      trueFalseQuantity:0,
      radioQuantity:0,
      checkboxQuantity:0}});

	onMounted(async () => {
     //await loadAllExamQuestion();
	});

  watch(() => itemData.data.questionIdList, (newName, oldName) => {
    initExamQuestionList();
    itemData.data.trueFalseQuantity = getQuestionTypeQuantity("trueFalse")
    itemData.data.radioQuantity = getQuestionTypeQuantity("radio")
    itemData.data.checkboxQuantity = getQuestionTypeQuantity("checkbox")
  });

    watch(() => itemData.data.examType, (newName, oldName) => {
      loadAllExamQuestion();
    });

  const getQuestionTypeQuantity = (questionType:string) =>{
    let i = 0;
    for(let j = 0; j < itemData.data.examQuestionList.length; j++){
      if(itemData.data.examQuestionList[j].questionType === questionType){
        i++;
      }
    }

    return i;
  }

  const setItemData = (value: any) => {
    itemData.data = value;
    loadAllExamQuestion();
  }

  const loadAllExamQuestion = async () =>{
    const postObj = {"page" : 1, "size": 20000, "examType": itemData.data.examType};
    const data = (await request({url: `examinationQuestionController/list`, data: postObj})).data
    if (checkResponseDataAndAlert(data) && data.data) {
      allExamQuestionList.value = data.data;
      refData.trueFalseQuestion.length = 0;
      refData.radioQuestion.length = 0;
      refData.checkboxQuestion.length = 0;

      for(let i = 0; i < allExamQuestionList.value.length; i++){
        if(allExamQuestionList.value[i].questionType == 'trueFalse'){
          refData.trueFalseQuestion.push(allExamQuestionList.value[i])
        }else if(allExamQuestionList.value[i].questionType == 'radio'){
          refData.radioQuestion.push(allExamQuestionList.value[i])
        }else if(allExamQuestionList.value[i].questionType == 'checkbox'){
          refData.checkboxQuestion.push(allExamQuestionList.value[i])
        }
      }
    }
  }

 const cancel = () => {
    ctx.emit('cancel');
  }

	const confirm = () => {
    console.log(itemData.data);
		ctx.emit('confirm', toPostData(itemData.data, []));
	}

  const randSort = (arr:any) => {
    console.log(arr.length)
    for (let i = 0, len = arr.length; i < len; i++) {
      let rand = Math.random() * (len - 1);
      rand = parseInt(rand.toString(), 10)
      console.log( rand);
      let temp = arr[rand];
      arr[rand] = arr[i];
      arr[i] = temp;
    }
    return arr;
  }

  const autoSelect = () =>{
    itemData.data.questionIdList.length = 0;
    randSort(refData.trueFalseQuestion)
    for(let i = 0; i< refData.trueFalseQuestion.length && i <= itemData.data.trueFalseQuantity - 1; i++){
      itemData.data.questionIdList.push(refData.trueFalseQuestion[i].id)
      console.log(itemData.data.questionIdList)
    }

    randSort(refData.radioQuestion)
    for(let i = 0; i< refData.radioQuestion.length && i < itemData.data.radioQuantity; i++){
      itemData.data.questionIdList.push(refData.radioQuestion[i].id)
    }

    randSort(refData.checkboxQuestion)
    for(let i = 0; i< refData.checkboxQuestion.length && i < itemData.data.checkboxQuantity; i++){
      itemData.data.questionIdList.push(refData.checkboxQuestion[i].id)
    }

    initExamQuestionList();
  }

    const initExamQuestionList = () =>{
      itemData.data.examQuestionList.length = 0;
      for(let i = 0; i < itemData.data.questionIdList.length; i++){
        for(let j = 0; j < allExamQuestionList.value.length; j++){
          if(allExamQuestionList.value[j].id === itemData.data.questionIdList[i]){
            itemData.data.examQuestionList.push(allExamQuestionList.value[j]);
            break;
          }
        }
      }
    }

	const dataRef = toRefs(refData);
    return {
    cancel,
	  confirm,
    setItemData,
    autoSelect,
    loadAllExamQuestion,
      initExamQuestionList,
	  ...dataRef,
	  itemData,
    allExamQuestionList,
    }
  },
});
</script>

<style scoped>
.form-check-input {
	margin-left: 0;
}
.lesson-image {
	width: 100px;
	height: 100px;
	cursor: pointer;
	background-color: #00193A;
}
.question-image {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.overflow-div {
  height: 200px; /* 设置你想要的高度 */
  overflow-y: auto; /* 当内容超出区域高度时，显示垂直滚动条 */
}

.selectbackcolor {
  color: #020202;
  background-color: rgba(116, 175, 116, 0.58);
}

.examQuestionType {
  color: #0d6efd;
  background-color: rgba(154, 213, 154, 0.58);
}
</style>
