<template>
	<div class="modal-backdrop">
	     <div class="modal2">
	        <div class="modal-header flex-row flex-align-center">
	          <div class="text-center flex-1 modal-header-title">详情</div>
	          <button @click="cancel" class="modal-header-close">
				<svg width="16" height="16">
				  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
				</svg>
	          </button>
	        </div>
	        <div class="modal-body flex-row flex-wrap">
				<textarea rows="2" class="form-control" style="height: 400px;" v-model="json"></textarea>
	        </div>
	    </div>
	</div>
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, ref, onMounted, watch } from "vue";

export default defineComponent({
  components: { },
  props: {
	json: {
		type: String,
		default: "",
	}
  },

  setup(props: any, ctx: any)
  {
	
	onMounted(async () => {
	});
	
    const cancel = () => {
      ctx.emit('cancel');
    }

    return {
      cancel
    }
  },
});
</script>

<style>
</style>
