import Vue from 'vue'
import axios from 'axios';
import { buildHttpHeader, buildPlainTextWithAuthHeader, buildPlainTextHeader } from '@/common/util';

export const DEFAULTS = {
  URL: process.env.VUE_APP_API_URL,
  HOST: process.env.VUE_APP_API_HOST
};

function request(config: any) { 
  const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
	headers: config.plainText ? (config.guest ? buildPlainTextHeader() : buildPlainTextWithAuthHeader()) : (config.guest ? {} : buildHttpHeader()),
	method: config.get ? "GET" : 'POST',
    timeout: 30000,
	responseType: 'json',
  });

  return service(config)
}

export default request;