<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{title}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr id="app-other-rule-tr">
					<td>
						<div class="mb-1 td-label-right">
							<label for="app-other-rule">门店介绍:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<textarea rows="6" class="form-control" v-model="item.desc"></textarea>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="app-other-image">仪器证书:</label>
						</div>
					</td>
					<td>
						<div class="flex-row mb-1">
							<img v-for="(value, index) in item.images" :src='value' :key='index' class="image" />
							<ImageUploader @upload='imageUpload' />
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import ImageUploader from '@/components/ImageUploader.vue'
import { DEFAULTS } from "@/global";
import axios from 'axios';
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
	components: { ImageUploader,},
	props: {  
		show: {
			type: Boolean,
			default: false, 
		},
		title: {
			type: String,
			default: {},
		},
		item: {
			type: Object,
			default: {},
		},
	},

  setup(props: any, ctx: any)
  {
	onMounted(async () => {
	});
 
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		ctx.emit('confirm', toPostData(props.item, []));
	}
	
	const imageUpload = (url: string) => {
		if (!props.item.images) props.item.images = [];
		props.item.images.push(url)
	}

    return {
      cancel,
	  confirm,
	  imageUpload,
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
	.image {
		width: 150px;
		height: 150px;
		margin: 0 5px 5px 5px ;
		background-color: #00193A;
	}
</style>