<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{label.id ? '编辑' : '添加'}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="class-title">标题:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="label.title">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="class-order">排序:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<input type="number" class="form-control" v-model="label.order">
						</div>
					</td>
				</tr>
				<tr v-if="hasRecommend">
					<td>
						<div class="mb-1 td-label-right">
							<label>推荐:</label>
						</div>
					</td>
					<td>
						<div class="mb-1 form-switch">
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="label.recommend"/>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="class-show">状态:</label>
						</div>
					</td>
					<td>
						<div class="mb-1 form-switch">
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="label.show"/>
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent } from "vue";
import { checkNumber, toPostData } from '@/common/util';

export default defineComponent({
  props: {  
    show: {
        type: Boolean,
        default: false, 
    },
	hasRecommend: {
	    type: Boolean,
	    default: true, 
	},
	label: {
	    type: Object,
	    default: { recommend: false, show: true },
	},
  },

  setup(props: any, ctx: any)
  {
	onMounted(async () => {
		// $("#editArticleClassModalLabel")[0].innerText = '添加';
				
		// cleanFormInput("class-form");
		// $("#class-show").attr('checked', true);
	});
 
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		let intKeys = ['show', 'recommend'];
		ctx.emit('confirm', toPostData(props.label, intKeys));
	}

    return {
      cancel,
	  confirm,
	  checkNumber
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
</style>