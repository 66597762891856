<template>
	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
			<div class="align-self-center mb-3" style="height: 30px;">
				<button type="button" class="btn btn-primary" @click="openAddArticleDialog">添加</button>
			</div>
 			<div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
				<input type="text" id="article-keywords" class="form-control" v-model="keywords">
				<button class="btn btn-outline-secondary" type="button" @click="searchArticle">搜索</button>
			</div>
		</div>
 		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th>序号</th><th>标题</th><th>类型</th><th>状态</th><th>排序</th><th>添加时间</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.title}}</td>
						<td>{{value.type}}</td>
						<td>
							<div class="mb-1 form-check form-switch" style="padding-top: 5px;">
								<input class="form-check-input" :checked="value.show === 1" @change="updateArticle(value, 'show')" type="checkbox" role="switch"/>
							</div>
						</td>
						<td>{{value.order}}</td>
						<td>{{dateFtt('yyyy-MM-dd hh:mm', new Date(value.operateTime))}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditArticleDialog(value.id)" />
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteArticle(value.id, value.title)" />
								<!-- <img class="button-icon img-margin" src="/assets/ic_comment.png" @click="openPage('comment/${value.id}')" /> -->
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadArticleList" />
		</div>
	</div>
	
	<AddArticleDialog ref="addDialogRef" :show='showAddDialog' @confirm='editArticle' @cancel='showAddDialog=false'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddArticleDialog from '@/modals/AddArticleDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt } from '@/common/util';

export default({
	components: { Pagination, AddArticleDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			keywords: "搜索",
		});
		
		const addDialogRef = ref<any>(null) // 通过 ref 绑定子组件
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadArticleList(1);
		});
		
		// 添加文章
		const openAddArticleDialog = () => {
			addDialogRef.value!.setItemData({'type': 'video', 'show': true})
		    refData.showAddDialog = true;
		}
		
		// 跳转编辑页
		const openEditArticleDialog = async (id: string) => {
			const errMsg = "查询信息失败";
			try {
				const data = (await request({url: `article/${id}`, get: true, guest: true})).data;
			    if (checkResponseDataAndAlert(data, errMsg) && data.data) {
					data.data.show = (data.data.show === 1)
					addDialogRef.value!.setItemData(data.data)
					refData.showAddDialog = true;
			    }
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		const updateArticle  = async (item: any, key: string) => {
			refData.showLoadingDialog = true;
			try {
				const postObj: any = {};
				postObj[`${key}`] = item[`${key}`] === 1 ? 0 : 1;
				const data = (await request({url: `article/update/${item.id}`, data: postObj})).data;
				refData.showLoadingDialog = false;
			    if (checkResponseDataAndAlert(data, "操作失败")) {
					item[`${key}`] = postObj[`${key}`];
			    } else {
					loadArticleList(nowPage.value);
				}
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, "操作失败");
			}
		}
		
		// 编辑
		const  editArticle = async (postObj: any) => {	
		    let gid = postObj.id;
		    const errMsg = `${(gid && gid.length > 0) ? '编辑' : '添加'}`;
		
		    let isVideo = postObj.type === 'video' || postObj.type === 'yoga';
		    if (!postObj.title || postObj.title.length < 1
		        || !postObj.desc || postObj.desc.length < 1
		        || (isVideo && (!postObj.video || postObj.video.length < 1))
		        || (!isVideo && (!postObj.content || postObj.content.length < 1))) {
		        alert("请完善带*的必填项");
		        return;
		    }

			refData.showLoadingDialog = true;
			try {
				const data = (await request({url: `article/save`, data: postObj})).data;
				refData.showLoadingDialog = false;
			    if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
			        loadArticleList(nowPage.value);
			    }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg + "文章失败");
			}
		}
		
		// 删除
		const deleteArticle = async (card_no: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除文章失败";
				try {
					const data = (await request({url: `article/delete/${card_no}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除文章成功")
						loadArticleList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		}
		
		// 搜索
		const searchArticleInner = async () => {
			if (refData.keywords.length === 0) {
			    loadArticleList(1);
			    return;
			}
					
			nowPage.value = 1;
			try {
				const postObj = {"page" : 1, "size": 50, "content": refData.keywords, timestamp: Date.now() };
				const data = (await request({url: `article/search`, data: postObj})).data
			    if (checkResponseDataAndAlert(data) && data.data) {
					dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}
		
		// 加载列表
		const  loadArticleList = async (page: number) => {
		    if (page < 1) return;
		
			dataList.value = [];
		    nowPage.value = page;
			try {
				const data = (await request({url: `article/list/${page}/${50}?label=admin`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}
		
		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
			
			dateFtt,
			editArticle,
			loadArticleList,
			openAddArticleDialog,
			openEditArticleDialog,
			deleteArticle,
			addDialogRef,
			updateArticle,
			
			...dataRef
		};
	},
});
</script>

<style>
</style>
