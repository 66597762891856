<template>
 	<div class="flex-colum h-100">
		<div class="flex-row bd-highlight mb-3">
		    <div class="align-self-center" style="height: 30px; margin-bottom: 5px;">
		        <button type="button" class="btn btn-primary" @click="openAddEmployeeDialog">添加</button>
		    </div>
			<div class="input-group align-self-center ms-auto bd-highlight mr-5" style="width: 15%;">
				<select class="form-select" v-model="shop">
					<option v-for="(value, idx) in shops" :key='idx' :value="value.id">{{value.name}}</option>
				</select>
			</div>
			<div class="input-group align-self-center bd-highlight" style="width: 25%;">
			    <input type="text" id="employee-keywords" class="form-control" placeholder="搜索" v-model="keywords">
			    <button class="btn btn-outline-secondary" type="button" @click="loadEmployeeList(1)">搜索</button>
			</div>
		</div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th>序号</th><th>姓名</th><th>ID</th><th>手机号</th><th>职位</th><th>门店</th><th>状态</th><th>身份证</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.name}}</td>
						<td>{{value.id}}</td>
						<td>{{value.mobile}}</td>
						<td>{{value.jobTitle || "-"}}</td>
						<td>{{getShopName(value.shop)}}</td>
						<td class="flex-row">
							{{value.disable ? "已离职" : "在职"}}
							<div style="text-decoration: underline; color: dodgerblue; cursor: pointer;" @click="enableEmployee(value.id, value.name)" v-if="value.disable">(复职)</div>
						</td>
						<td>{{value.idcard || "-"}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditEmployeeDialog(value.id)"/>
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteEmployee(value.id, value.name)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadEmployeeList" />
		</div>
	</div>
	
	<AddEmployeeDialog :show='showAddDialog' :item='editing' @cancel='showAddDialog = false' @confirm='editEmployee'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddEmployeeDialog from '@/modals/AddEmployeeDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import { DEFAULTS } from "@/global";
import { checkResponseDataAndAlert, dateFtt, buildHttpHeader } from '@/common/util';
import axios from 'axios';
import request from "@/global";

export default({
	components: { Pagination, AddEmployeeDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			keywords: "",
			shop: "all",
			shops: [{id: "all", name: "全部"}] as any[],
			editing: {}
		});
		
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
		
		watch(()=>refData.shop, ()=>{
			loadEmployeeList(1);
		})
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadShops();
		  await loadEmployeeList(1);
		});
		
		// 加载列表
		const loadShops = async () => {
			try {
				const data = (await request({url: `shop/list/1/100`, get: true})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
					refData.shops.push(...data.data);
					refData.shop = refData.shops[0].id;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};
		
		// ========================文章标签========================
		// 添加标签
		const openAddEmployeeDialog = () => {
			refData.editing = {};
			refData.showAddDialog = true;
		};
		
		// 编辑信息
		const openEditEmployeeDialog = async (id: string) => {
		    const errMsg = "查询信息失败";
			try {
				const data = (await axios.get(`${DEFAULTS.URL}employee/${id}`, {headers: buildHttpHeader()})).data;
				console.log(id, data.data)
		        if (checkResponseDataAndAlert(data, errMsg) && data.data) {
					refData.editing = data.data;
					refData.showAddDialog = true;
		        }
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 编辑
		const editEmployee = async (postObj: any) =>  {
			console.log(postObj)
		    const errMsg = `操作失败`;		
			if (!postObj.name || postObj.name.length < 2
				&& !postObj.mobile || postObj.mobile.length !== 11) {
				alert("请完善带*的必填项");
				return;
			}
		
		    refData.showLoadingDialog = true;
			try {
				const action = (postObj.id && postObj.id.length > 0) ? `update/${postObj.id}` : "save"
				const data = (await axios.post(`${DEFAULTS.URL}employee/${action}`, postObj, {headers: buildHttpHeader()})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadEmployeeList(nowPage.value);
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 删除
		const deleteEmployee = async (card_no: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await axios.get(`${DEFAULTS.URL}employee/delete/${card_no}`, {headers: buildHttpHeader()})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功");
						loadEmployeeList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};

		// 复职
		const enableEmployee = async (card_no: string, name: string) => {
			if (confirm(`确认启用 ${name} 吗？`)) {
				const errMsg = "操作失败";
				try {
					const data = (await axios.get(`${DEFAULTS.URL}employee/enable/${card_no}`, {headers: buildHttpHeader()})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("操作成功");
						loadEmployeeList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};
			
		// 加载列表
		const loadEmployeeList = async (page: number) => {
		    if (page < 1) return;
		
		    nowPage.value = page;
			try {
				const data = (await axios.get(`${DEFAULTS.URL}employee/list/${page}/${50}?shop=${refData.shop}&search=${refData.keywords}`, {headers: buildHttpHeader()})).data;
				if (checkResponseDataAndAlert(data)) {
				    dataList.value = data.data || [];
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};
		
		const getShopName = (shop: string) => {
			const res = refData.shops.filter((t:any)=>{return t.id === shop})
			return res.length > 0 ? res[0].name : "未知"
		};
		
		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
			
			getShopName,
			dateFtt,
			editEmployee,
			loadEmployeeList,
			openAddEmployeeDialog,
			openEditEmployeeDialog,
			deleteEmployee,
			enableEmployee,
			
			...dataRef
		};
	},
});
</script>

<style>
</style>