<template>
 	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
		    <div class="align-self-center mb-3" style="height: 30px;">
		        <button type="button" class="btn btn-primary" @click="openAddFoodDialog">添加</button>
		    </div>
		</div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th>序号</th><th>入住天数</th><th>BMI</th><th>门店</th><th>菜品</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.days}}</td>
						<td>{{value.bmiFrom}}-{{ value.bmiTo }}</td>
						<td>{{(value.shopInfo) ? value.shopInfo.name : "未知"}}</td>
						<td>{{value.zaocan.content}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditFoodDialog(value.id)"/>
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteFood(value.id, value.id)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadFoodList" />
		</div>
	</div>
	
	<AddFoodDialog :show='showAddDialog' :item='editing' @cancel='showAddDialog = false' @confirm='editFood'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddFoodDialog from '@/modals/AddFoodDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import { DEFAULTS } from "@/global";
import { checkResponseDataAndAlert, dateFtt, buildHttpHeader } from '@/common/util';
import axios from 'axios';

export default({
	components: { Pagination, AddFoodDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			editing: {}
		});
		
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
		const isBlue = ref<boolean>(process.env.VUE_APP_ENV === "blue")
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadFoodList(1);
		});
		
		// ========================文章标签========================
		// 添加标签
		const openAddFoodDialog = () => {
			const choice = [{title: "蔬菜选择"}, {title: "肉蛋豆制品选择"}, {title: "水果选择"}, {title: "烹调调料"}]
			refData.editing = {zaocan: {}, wucan:{}, wujiancan:{}, jiancan:{}, wancan:{}, xiaoye:{}, choice: isBlue.value ? choice: null};
			refData.showAddDialog = true;
		};
		
		// 编辑信息
		const openEditFoodDialog = async (id: string) => {
		    const errMsg = "查询信息失败";
			try {
				const data = (await axios.get(`${DEFAULTS.URL}food/get/${id}`, {headers: buildHttpHeader()})).data;
		        if (checkResponseDataAndAlert(data, errMsg) && data.data) {
					data.data.show = (data.data.show === 1)
					refData.editing = data.data;
					refData.showAddDialog = true;
		        }
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 编辑
		const editFood = async (postObj: any) =>  {
			console.log(postObj)
		    const errMsg = `操作失败`;		
			if (!postObj.shop || postObj.shop.length < 2
				|| !postObj.zaocan.content || postObj.zaocan.content.length < 2
				|| !postObj.wujiancan.content || postObj.wujiancan.content.length < 2
				|| !postObj.wucan.content || postObj.wucan.content.length < 2
				|| !postObj.jiancan.content || postObj.jiancan.content.length < 2
				|| !postObj.wancan.content || postObj.wancan.content.length < 2
				|| !postObj.xiaoye.content || postObj.xiaoye.content.length < 2) {
				alert("请完善带*的必填项");
				return;
			}
		
		    refData.showLoadingDialog = true;
			try {
				const data = (await axios.post(`${DEFAULTS.URL}food/save`, postObj, {headers: buildHttpHeader()})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadFoodList(nowPage.value);
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 删除
		const deleteFood = async (card_no: string, name: string) => {
			if (confirm(`确认删除 ${name} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await axios.get(`${DEFAULTS.URL}food/delete/${card_no}`, {headers: buildHttpHeader()})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除成功");
						loadFoodList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};
			
		// 加载列表
		const  loadFoodList = async (page: number) => {
		    if (page < 1) return;
		
		    nowPage.value = page;
			try {
				const data = (await axios.get(`${DEFAULTS.URL}food/list/${page}/${50}`, {headers: buildHttpHeader()})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		};
		
		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
			
			dateFtt,
			editFood,
			loadFoodList,
			openAddFoodDialog,
			openEditFoodDialog,
			deleteFood,
			
			...dataRef
		};
	},
});
</script>

<style>
</style>