<template>
	<div class="flex-row h-full" v-if="isLogin" style="overflow: hidden; min-width: 850px;">
		<NavBar />
	
		<div class="main-content flex-1 pl-5 pr-5" style="overflow-y: scroll;">
			<div class="mt-3 mb-3">
				<router-view />
			</div>
		</div>
	</div>
	<div class="flex-row-center w-100" style="height: 90vh;" v-else>
		<div class="card" style="width: 320px;">
			<div class="card-header">登录管理后台</div>
			<div class="card-body">
				<div class="mb-3">
					<label for="login-name" class="col-form-label">&nbsp;用户名</label>
					<input type="text" class="form-control" v-model="postData.name" >
				</div>
		
				<div class="mb-3">
					<label for="login-pwd" class="col-form-label">&nbsp;密码</label>
					<input type="password" class="form-control" v-model="postData.password">
				</div>
				<a @click="login" class="btn btn-primary" style="width: 100%;">登录</a>
			</div>
		</div>
	</div>
	
	<LoadingDialog :show='showLoadingDialog' />
	
</template>

<script lang="ts">
import { reactive, toRefs, onMounted, ref } from 'vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import NavBar from '@/components/NavBar.vue';
import request from "@/global";
import { checkResponseDataAndAlert } from '@/common/util';
import { sha512 } from "js-sha512";

export default({
	components: { NavBar, LoadingDialog },
	setup() {
		const refData = reactive({
			showLoadingDialog: false,
			postData: { name: '', password: ''}
		});
		
		const isLogin = ref<boolean>(false);
		
		//needed in case we switch in from another window
		onMounted(async () => {
			const token = sessionStorage.getItem("token");
			if (token) {
				const data = JSON.parse(token);
				if (data.accessToken && data.expireOn > (new Date().getTime() / 1000 + 600)) {
					isLogin.value = true;
					return;
				} else if (data.refreshToken) {
					refreshToken(data.refreshToken)
					return;
				}
			}
			sessionStorage.removeItem("token");
			isLogin.value = false;
		});
		
		const login = async () => {
			if (refData.postData.name.length > 0 && refData.postData.password.length > 0) {
				refData.showLoadingDialog = true;
			    let errMsg = "登录失败，请重试";
				
				try {
					const postObj = {name: refData.postData.name, password: sha512(refData.postData.password) }
					console.log(postObj);
					const data = (await request({url: `employee/login`, data: postObj, guest: true})).data;
				    if (checkResponseDataAndAlert(data, errMsg) && data.data) {
						sessionStorage.setItem("token", JSON.stringify(data.data));
						isLogin.value = true;
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg)
				}
				refData.showLoadingDialog = false;
			}
		};
		
		const refreshToken = async (token: string) => {
			if (token.length > 0) {
				refData.showLoadingDialog = true;
			    let errMsg = "登录失败，请重试";
				
				try {
					const data = (await request({url: `token/refresh`, data: token, plainText: true, guest: true})).data;
				    if (checkResponseDataAndAlert(data, errMsg) && data.data) {
						sessionStorage.setItem("token", JSON.stringify(data.data));
						isLogin.value = true;
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg)
				}
				refData.showLoadingDialog = false;
			}
		};
		
		const dataRef = toRefs(refData);
		return {
			login,
			isLogin,
			...dataRef
		};
	},
});
</script>

<style>

.sidebar-heading {
	margin-top: 15px;
	padding: 0 15px;
	width: 100%;
	height: 30px;
	line-height: 30px;
	font-size: 12px;
	color: rgba(255,255,255,.5)!important;
}

.main-content {
	height:100vh;
}

</style>
