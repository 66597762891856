<template>
	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
			<div class="align-self-center mb-3" style="height: 30px;">
				<button type="button" class="btn btn-primary" @click="openAddQuestionDialog">添加</button>
			</div>
 			<div class="input-group align-self-center ms-auto p-2 bd-highlight" style="width: 25%;">
				<input type="text" id="article-keywords" class="form-control" v-model="keywords">
				<button class="btn btn-outline-secondary" type="button" @click="searchQuestionInner">搜索</button>
			</div>
		</div>
 		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th>序号</th><th style="width: 300px;">问题</th><th style="width: 250px;">回答</th><th>状态</th><th>排序</th><th>添加时间</th><th>操作</th>
					</tr>
				</thead>
				<tbody id="member-list" >
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.title}}</td>
            <td><div v-html="value.content"></div></td>
            <!--<td>value.content}}</td>-->
						<td>
							<div class="mb-1 form-check form-switch" style="padding-top:5px;">
								<input class="form-check-input" :checked="value.show" @change="updateQuestion(value)" type="checkbox" role="switch"/>
							</div>
						</td>
						<td>{{value.sort}}</td>
						<td>{{value.createTime}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditQuestionDialog(value.id)" />
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteQuestion(value.id, value.title)" />
								<!-- <img class="button-icon img-margin" src="/assets/ic_comment.png" @click="openPage('comment/${value.id}')" /> -->
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination :page='nowPage' :max='totalPage/50' @loadPage="loadQuestionList" />
		</div>
	</div>

	<AddQuestionDialog ref="addDialogRef" :show='showAddDialog' @confirm='editQuestion' @cancel='showAddDialog=false'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddQuestionDialog from '@/modals/AddQuestionDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt } from '@/common/util';

export default({
	components: { Pagination, AddQuestionDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			keywords: "搜索",
		});

		const addDialogRef = ref<any>(null) // 通过 ref 绑定子组件
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);

		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadQuestionList(1);
		});

		// 添加文章
		const openAddQuestionDialog = () => {
			addDialogRef.value!.setItemData({'show': true,"content":""})
		    refData.showAddDialog = true;
		}


		// 跳转编辑页
		const openEditQuestionDialog = async (id: string) => {

			const errMsg = "查询信息失败";
			try {
				const data = (await request({url: `askedQuestion/get/${id}`, get: true})).data;
			    if (checkResponseDataAndAlert(data, errMsg) && data.data) {
					addDialogRef.value!.setItemData(data.data)
					refData.showAddDialog = true;
			    }
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({}, errMsg);
			}
		}

		const updateQuestion  = async (item:any) => {
			refData.showLoadingDialog = true;
			try {
				const postObj = item;
        postObj.show = !postObj.show;
        console.log(postObj)
				const data = (await request({url: `askedQuestion/update`, data: postObj})).data;
				refData.showLoadingDialog = false;
			    if (checkResponseDataAndAlert(data, "操作失败")) {
			    } else {
            loadQuestionList(nowPage.value);
				}
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, "操作失败");
			}
		}

		// 编辑
		const  editQuestion = async (postObj: any) => {
		    let gid = postObj.id;
		    const errMsg = `${(gid && gid.length > 0) ? '编辑' : '添加'}`;

		    if (!postObj.title || postObj.content < 1) {
		        alert("请完善带*的必填项");
		        return;
		    }

			refData.showLoadingDialog = true;
			try {
        let data={};
        if(gid){
				 data = (await request({url: `askedQuestion/update`, data: postObj})).data;
        }else{
          data = (await request({url: `askedQuestion/add`, data: postObj})).data;
        }
				refData.showLoadingDialog = false;
			    if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
            loadQuestionList(nowPage.value);
			    }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg + "添加问答失败");
			}
		}

		// 删除
		const deleteQuestion = async (id: string, title:String) => {
			if (confirm(`确认删除 ${title} 吗？`)) {
				const errMsg = "删除问题失败";
				try {
					const data = (await request({url: `askedQuestion/del/${id}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						alert("删除问答成功")
            loadQuestionList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		}

		// 搜索
		const searchQuestionInner = async () => {
      console.log(refData.keywords);
			if (refData.keywords.length === 0) {
        loadQuestionList(1);
			    return;
			}

			nowPage.value = 1;
			try {
				const postObj = {"page" : 1, "size": 50, "title": refData.keywords};
				const data = (await request({url: `askedQuestion/list`, data: postObj})).data
			    if (checkResponseDataAndAlert(data) && data.data) {
					dataList.value = data.data;
					totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}

		// 加载列表
		const  loadQuestionList = async (page: number) => {
		    if (page < 1) return;

			dataList.value = [];
		    nowPage.value = page;
			try {
        //let postObj = new Object();
        let postObj = {
          page:page,
          size:50
        };
				const data = (await request({url: `askedQuestion/list`, data: postObj})).data;
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					  totalPage.value = data.total;
				}
			} catch (e) {
				checkResponseDataAndAlert({});
			}
		}

		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,

			dateFtt,
      editQuestion,
      loadQuestionList,
      openAddQuestionDialog,
      openEditQuestionDialog,
      deleteQuestion,
			addDialogRef,
      updateQuestion,
      searchQuestionInner,
			...dataRef
		};
	},
});
</script>

<style>
</style>
