import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row file-upload" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingDialog = _resolveComponent("LoadingDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.src || '/assets/ic_upload.png'
      }, null, 8, _hoisted_2),
      _createElementVNode("input", {
        type: "file",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadFilePicked && _ctx.uploadFilePicked(...args)))
      }, null, 32)
    ]),
    _createVNode(_component_LoadingDialog, { show: _ctx.showLoadingDialog }, null, 8, ["show"])
  ], 64))
}