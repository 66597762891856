<template>
 	<div class="flex-colum h-100">
		<div class="d-flex justify-content-between bd-highlight mb-3">
		    <div class="align-self-center mb-3" style="height: 30px;">
		        <button type="button" class="btn btn-primary" @click="openEditTagDialog()">添加</button>
		    </div>
		</div>
		<div>
			<table class="table table-bordered table-striped table-sm member-list">
				<thead>
					<tr>
						<th scope="col" width="80px">序号</th><th scope="col">标题</th><th scope="col">子类型</th><th scope="col" width="120px">操作</th>
					</tr>
				</thead>
				<tbody id="member-list">
					<tr v-for="(value, idx) in dataList" :key='idx'>
						<td>{{50 * (nowPage - 1) + idx + 1}}</td>
						<td>{{value.title}}</td>
						<td>{{value.subtype}}</td>
						<td>
							<div class="flex-row flex-align-center">
								<img class="button-icon" src="/assets/ic_edit.png" @click="openEditTagDialog(value)"/>
								<img class="button-icon img-margin" src="/assets/ic_trash.png" @click="deleteTag(value)"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	
	<AddTagDialog :show='showAddDialog' :tag='editing' @cancel='showAddDialog = false' :hasImage="true" @confirm='editTag'/>
	<LoadingDialog :show='showLoadingDialog' />
</template>

<script lang="ts">
import { reactive, toRefs, defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue';
import Pagination from '@/components/Pagination.vue';
import AddTagDialog from '@/modals/AddTagDialog.vue';
import LoadingDialog from '@/modals/LoadingDialog.vue';
import request from "@/global";
import { checkResponseDataAndAlert, dateFtt } from '@/common/util';

export default({
	components: { Pagination, AddTagDialog, LoadingDialog },
	setup() {
		const refData = reactive({
			showAddDialog: false,
			showLoadingDialog: false,
			editing: {}
		});
		
		const type = 'greeting';
		const totalPage = ref<number>(0);
		const nowPage = ref<number>(1);
		const dataList = ref<any[]>([]);
		
		//needed in case we switch in from another window
		onMounted(async () => {
		  await loadTagList(1);
		});

		// 编辑
		const openEditTagDialog = async (tag: any) => {
			refData.editing = tag ? tag : { "type": type };
			refData.showAddDialog = true;
		}
		
		// 编辑
		const editTag = async (tag: any) =>  {
		    const errMsg = `操作失败`;		
			if (!tag.title || tag.title.length < 1) {
				alert("请填写标题");
				return;
			}
		
		    refData.showLoadingDialog = true;
			try {
				tag.type = type;
				const data = (await request({url:`preset/save`, data: tag})).data;
				refData.showLoadingDialog = false;
		        if (checkResponseDataAndAlert(data, errMsg)) {
					refData.showAddDialog = false;
		            loadTagList(nowPage.value);
		        }
			} catch (e) {
				console.log(e);
				refData.showLoadingDialog = false;
				checkResponseDataAndAlert({}, errMsg);
			}
		}
		
		// 删除
		const deleteTag = async (tag: any) => {
			if (confirm(`确认删除 ${tag.title} 吗？`)) {
				const errMsg = "删除失败";
				try {
					const data = (await request({url:`preset/delete/${tag.id}`, get: true})).data;
					if (checkResponseDataAndAlert(data, errMsg)) {
						loadTagList(nowPage.value);
					}
				} catch (e) {
					checkResponseDataAndAlert({}, errMsg);
				}
			}
		};
			
		// 加载列表
		const  loadTagList = async (page: number) => {
		    if (page < 1) return;
		
		    nowPage.value = page;
			try {
				const data = (await request({url:`preset/list/1/200?type=${type}`, get: true})).data;
				console.log(data);
				if (checkResponseDataAndAlert(data) && data.data) {
				    dataList.value = data.data;
					totalPage.value = data.data.length;
				}
			} catch (e) {
				console.log(e);
				checkResponseDataAndAlert({});
			}
		};
		
		const dataRef = toRefs(refData);
		return {
			dataList,
			nowPage,
			totalPage,
			
			editTag,
			loadTagList,
			openEditTagDialog,
			deleteTag,
			
			...dataRef
		};
	},
});
</script>

<style>
</style>