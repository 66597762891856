<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{customer.id ? '编辑' : '添加'}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td class="td-avg6"></td><td class="td-avg6"></td><td class="td-avg6"></td><td class="td-avg6"></td><td class="td-avg6"></td><td class="td-avg6"></td></tr>
				<tr>
					<td colspan="2">
						<div class="mb-1">
							<label for="customer-name">姓名:</label>
							<input type="text" class="form-control" v-model="customer.name" :disabled="true">
						</div>
					</td>
 					<td colspan="2">
						<div class="mb-1">
							<label for="customer-mobile">手机:</label>
							<input type="tel" class="form-control" v-model="customer.mobile" :disabled="true">
						</div>
					</td>
					<td colspan="2">
						<div class="mb-1">
							<label for="customer-idcard">身份证:</label>
							<input type="text" class="form-control" v-model="customer.idcard" :disabled="true">
						</div>
					</td>
				</tr>
<!--				<tr>
					<td  colspan="2">
						<div class="mb-1">
							<label for="customer-nation">民族:</label><br>
							<select class="form-select" aria-label="Default select example" v-model="customer.nation">
								<option value="汉族" selected>汉族</option><option value="壮族">壮族</option><option value="回族">回族</option><option value="满族">满族</option><option value="维吾尔族">维吾尔族</option>
								<option value="苗族">苗族</option><option value="彝族">彝族</option><option value="土家族">土家族</option><option value="藏族">藏族</option><option value="蒙古族">蒙古族</option>
								<option value="侗族">侗族</option><option value="布依族">布依族</option><option value="瑶族">瑶族</option><option value="白族">白族</option><option value="朝鲜族">朝鲜族</option>
								<option value="哈尼族">哈尼族</option><option value="黎族">黎族</option><option value="哈萨克族">哈萨克族</option><option value="傣族">傣族</option><option value="畲族">畲族</option>
								<option value="傈僳族">傈僳族</option><option value="东乡族">东乡族</option><option value="仡佬族">仡佬族</option><option value="拉祜族">拉祜族</option><option value="佤族">佤族</option>
								<option value="水族">水族</option><option value="纳西族">纳西族</option><option value="羌族">羌族</option><option value="土族">土族</option><option value="仫佬族">仫佬族</option>
								<option value="锡伯族">锡伯族</option><option value="柯尔克孜族">柯尔克孜族</option><option value="景颇族">景颇族</option><option value="达斡尔族">达斡尔族</option><option value="撒拉族">撒拉族</option>
								<option value="布朗族">布朗族</option><option value="毛南族">毛南族</option><option value="塔吉克族">塔吉克族</option><option value="普米族">普米族</option><option value="阿昌族">阿昌族</option>
								<option value="怒族">怒族</option><option value="鄂温克族">鄂温克族</option><option value="京族">京族</option><option value="基诺族">基诺族</option><option value="德昂族">德昂族</option>
								<option value="保安族">保安族</option><option value="俄罗斯族">俄罗斯族</option><option value="裕固族">裕固族</option><option value="乌孜别克族">乌孜别克族</option><option value="门巴族">门巴族</option>
								<option value="鄂伦春族">鄂伦春族</option><option value="独龙族">独龙族</option><option value="赫哲族">赫哲族</option><option value="高山族">高山族</option><option value="珞巴族">珞巴族</option><option value="塔塔尔族">塔塔尔族</option>
							</select>
						</div>
					</td>
					<td  colspan="2">
						<div class="mb-1">
							<label for="customer-birthPlace">籍贯:</label>
							<input type="text" class="form-control" v-model="customer.birthPlace">
						</div>
					</td>
					<td colspan="2">
						<div class="mb-1">
							<label for="customer-education">学历:</label>
							<select class="form-select" v-model="customer.education">
								<option value="博士">博士</option>
								<option value="硕士">硕士</option>
								<option value="大学本科" selected>大学本科</option>
								<option value="高中">高中</option>
								<option value="初中">初中</option>
								<option value="小学">小学</option>
							</select>
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="6">
						<div class="mb-1">
							<label for="customer-job">职业:</label>
							<input type="text" class="form-control" v-model="customer.job">
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<div class="mb-1">
							<label for="customer-mealType">套餐类型:</label>
							<input type="number" oninput="checkNumber(this, 0, 100)" v-model="customer.mealType" class="form-control">
						</div>
					</td>
					<td colspan="2">
						<div class="mb-1">
							<label for="customer-liveCount">入住次数:</label>
							<input type="number" oninput="checkNumber(this, 0, 100)" class="form-control" v-model="customer.liveCount">
						</div>
					</td>
					<td colspan="2">
						<div class="mb-1">
							<label for="customer-liveDays">入住天数:</label>
							<input type="number" oninput="checkNumber(this, 0, 100000)" class="form-control" v-model="customer.liveDays">
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<div class="mb-1">
							<label for="customer-babyName">宝宝姓名:</label>
							<input type="text" class="form-control" v-model="customer.babyName">
						</div>
					</td>
					<td colspan="2">
						<div class="mb-1">
							<label for="customer-babySex">宝宝性别:</label>
							<select class="form-select" v-model="customer.babySex">
								<option value="男" selected>男</option>
								<option value="女">女</option>
							</select>
						</div>
					</td>
					<td colspan="2">
						<div class="mb-1">
							<label for="customer-babyWeight">出生体重:(kg)</label>
							<input type="number" class="form-control" v-model="customer.babyWeight">
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<div class="mb-1">
							<label for="customer-babyJaundice">黄疸值:</label>
							<input type="number" class="form-control" v-model="customer.babyJaundice">
						</div>
					</td>
					<td colspan="2">
						<div class="mb-1">
							<label for="customer-bearCount">生育次数:</label>
							<select class="form-select" v-model="customer.bearCount">
								<option value="博士">博士</option>
								<option value="硕士">硕士</option>
								<option value="大学本科" selected>大学本科</option>
								<option value="高中">高中</option>
								<option value="初中">初中</option>
								<option value="小学">小学</option>
							</select>
						</div>
					</td>
					<td colspan="2">
						<div class="mb-1">
							<datepicker date-format="yyyy-MM-dd" selector="form-control">
								<label for="customer-pregnantDays">孕周期:</label>
								<input ng-model="date3" type="text" class="form-control" v-model="customer.pregnantDays"/>
							</datepicker>
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="6">
						<div class="mb-1">
							<label for="customer-babyNote">宝宝特殊事宜:</label>
							<textarea rows="2" class="form-control" v-model="customer.babyNote"></textarea>
						</div>
					</td>
				</tr> -->
				<tr>
					<td  colspan="2">
						<div class="mb-1" selector="angular-datepicker-input">
							<label for="customer-createdTime">入住日期:</label>
							<input type="text" class="form-control" v-model="customer.createdTime"/>
						</div>
					</td>
					<td  colspan="2">
						<div class="mb-1">
							<label for="customer-leaveTime">离店日期:</label>
							<input type="text" class="form-control" v-model="customer.leaveTime"/>
						</div>
					</td>
					<td  colspan="2">
						<div class="mb-1">
							<label for="customer-roomNo">入住房号:</label>
							<input type="text" class="form-control" v-model="customer.roomNo"/>
						</div>
					</td>
				</tr>
<!-- 				<tr>
					<td colspan="2">
						<div class="mb-1 form-check form-switch" style="padding-top: 10px;">
							<label for="customer-qianyue" class="ml-1">是否签约</label>
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="customer.qianyue">
						</div>
					</td>
					<td colspan="2">
						<div class="mb-1 form-check form-switch" style="padding-top: 10px;">
							<label for="customer-waipaipeihu" class="ml-1">是否外派陪护</label>
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="customer.waipaipeihu">
						</div>
					</td>
					<td colspan="2">
						<div class="mb-1 form-check form-switch" style="padding-top: 10px;">
							<label for="customer-waipaitongru" class="ml-1">是否外派通乳</label>
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="customer.waipaitongru">
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="6">
						<div class="mb-1">
							<label for="customer-rufangqingkuang">乳房情况:</label>
							<textarea rows="2" class="form-control" v-model="customer.rufangqingkuang"></textarea>
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="6">
						<div class="mb-1">
							<label for="customer-ruzhuxuqiu">入住前需求:</label>
							<textarea rows="2" class="form-control" v-model="customer.ruzhuxuqiu"></textarea>
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="6">
						<div class="mb-1">
							<label for="customer-yudingxuqiu">预订单需求:</label>
							<textarea rows="2" class="form-control" v-model="customer.yudingxuqiu"></textarea>
						</div>
					</td>
				</tr> -->
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>
</template>
 
<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent } from "vue";
import { checkNumber, toPostData, isTextValid } from '@/common/util';

export default defineComponent({
  props: {  
    show: {
        type: Boolean,
        default: false, 
    },
	customer: {
	    type: Object,
	    default: {},
	},
  },

  setup(props: any, ctx: any)
  {
	const intKeys = ['qianyue'];
	onMounted(async () => {
	});
 
    const cancel = () => {
      ctx.emit('cancel');
    }
	
	const confirm = () => {
		// console.log(JSON.stringify(toPostData(props.customer, intKeys)))
		const postData = {} as any;
		if (isTextValid(props.customer.createdTime)) postData['createdTime'] = props.customer.createdTime;
		if (isTextValid(props.customer.leaveTime)) postData['leaveTime'] = props.customer.leaveTime;
		if (isTextValid(props.customer.roomNo)) postData['roomNo'] = props.customer.roomNo;
		ctx.emit('confirm', props.customer.id, postData);
	}

    return {
      cancel,
	  confirm,
	  checkNumber
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
</style>